import React from "react";

const Modal = ({ modalactive, title, description, setModalHide }) => {
  return (
    <div className="modal" id="myModal" style={{ display: `${modalactive}` }}>
      <div className="modal-dialog">
        <div className="modal-content rounded">
          <div className="modal-header border border-primary">
            <h4 className="modal-title">{title}</h4>
          </div>

          <div className="modal-body ">{description}</div>

          <div className="modal-footer">
            {" "}
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={setModalHide}
            >
              {" "}
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
