import React from "react";
import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

// import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";

// import WhatsappSend from "../../utils/WhatsappSend";
// import dateFormat from "dateformat";
// import { format } from "date-fns";

const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const ViewSingleUser = () => {
  const [values, setValues] = useState([]);
  // const [Users, setUsers] = useState();

  // const navigate = useNavigate();

  const dateString = values.birthdate;

  const dateObject = new Date(dateString);
  const formattedDate = dateObject.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  //*********************** Category End ******************************************

  const [showloader, setShowLoader] = useState("none");
  // const [Tranjection, setTranjection] = useState([]);

  const { id, userType } = useParams();

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************

  const getSingleData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/users/getSingleUser/${id}`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getData;
    setValues(Data1?.data?.data);

    setShowLoader("none");
  };

  useEffect(() => {
    getSingleData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link to={`/users/${userType}`} className="btn btn-primary mb-2">
        {" "}
        Back{" "}
      </Link>
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">User Details </h4>
              <p className="card-description"></p>
              <form className="forms-sample">
                {/*  row start */}
                <div class="row">
                  <div class="col-md-12 ">
                    <div className="d-flex justify-content-center align-items-center ">
                      <div className="text-center  " style={{ width: "150px" }}>
                        {values?.presignedUrl1 ? (
                          <img
                            src={values.presignedUrl1}
                            alt="img"
                            className="w-100 "
                            style={{ height: "250px" }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Name </label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        id="exampleInputUsername1"
                        placeholder={values?.name}
                        value={values?.name}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Country </label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        id="exampleInputUsername1"
                        placeholder={values?.countryid?.name}
                        name="name"
                        value={values?.countryid?.name}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">State </label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        id="exampleInputUsername1"
                        placeholder={values?.stateid?.name}
                        value={values?.stateid?.name}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Email Id </label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        id="exampleInputUsername1"
                        placeholder={values?.email}
                        value={values?.email}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Phone </label>
                      <input
                        type="number"
                        className="form-control"
                        readOnly
                        id="exampleInputUsername1"
                        placeholder={values?.mobile}
                        value={values?.mobile}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">DOB </label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        id="exampleInputUsername1"
                        // placeholder={values?.birthdate}
                        value={formattedDate ? formattedDate : null}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">
                        Subscription Name{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        id="exampleInputUsername1"
                        value={
                          values?.planid?.title ? values?.planid?.title : "Free"
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* row end */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSingleUser;
