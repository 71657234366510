import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
// import { toast } from "react-toastify";
import Logo from "../../assets/image/logo.png";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import DefaultImg from "../../assets/image/defaultimages/default.png";
// const WEBSITE = process.env.REACT_APP_SESSION_WEBSITE_NAME;
import React, { useContext } from "react";
import { AuthContext } from "../../AuthContext";

const SharedLayout = () => {
  const [isActive, setActive] = useState("inactive");
  const { isAuthenticated, logout } = useContext(AuthContext);
  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const [number, setnumber] = useState(0);
  const toggleClass = (e) => {
    // const val = e;
    setnumber(e);
    setActive(isActive === "inactive" ? "active" : "inactive");
  };

  const logout2 = (e) => {
    e.preventDefault();
    logout();
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  // check is already token available or not
  const navigate = useNavigate();
  // useEffect(() => {
  //   const adminlocaltoken = localStorage.getItem("token");

  //   if (!adminlocaltoken) {
  //     navigate("/login");
  //     toast.info("Acess Denied...");
  //   }
  // }, []);

  // check is already token available or not

  return (
    <>
      {isAuthenticated ? (
        <div className="container-scroller">
          <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
              <Link className="navbar-brand brand-logo" to="/dashboard">
                <img src={Logo} className="" alt="logo" />
              </Link>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
              <ul className="navbar-nav mr-lg-2 search-col">
                <li>
                  {/* <form action="">
          <button type="submit"><i class="fa fa-search"></i></button>
          <input type="text" placeholder="Search.." name="search" />
        </form> */}
                </li>
              </ul>
              <ul className="navbar-nav navbar-nav-right">
                <li>
                  <i className="fa-regular "></i>
                </li>
                <li>
                  <h5 className="m-none text-primary text-right">
                    Hello Admin
                  </h5>
                </li>

                <li className="nav-item nav-profile dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    href="#"
                    data-toggle="dropdown"
                    id="profileDropdown"
                  >
                    <img src={DefaultImg} alt="profile" />
                  </Link>
                  <div
                    className="dropdown-menu dropdown-menu-right navbar-dropdown"
                    aria-labelledby="profileDropdown"
                  >
                    {/* <Link to='/changepassword' class="dropdown-item" >
                  <i class="ti-key text-primary"></i>
                  Change Password
                </Link> */}
                    <Link className="dropdown-item" onClick={logout2}>
                      <i className="ti-power-off text-primary"></i>
                      Logout
                    </Link>
                  </div>
                </li>
                {/* <li class="nav-item nav-settings d-none d-lg-flex">
              <Link class="nav-link">
                <i class="icon-ellipsis"></i>
              </Link>
            </li> */}
              </ul>
              {/* <button onClick={toggleClass}>toggle</button> */}
              <button
                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                type="button"
                data-toggle="offcanvas"
                onClick={toggleClass}
              >
                <span className="icon-menu" onClick={toggleClass}></span>
              </button>
            </div>
          </nav>

          <div className="container-fluid page-body-wrapper">
            <Sidebar
              isActive={isActive}
              number={number}
              onClick={toggleClass}
            />
            <div className="main-panel">
              <Outlet />
              <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                  {/* <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021.  Premium <a href="https://www.bootstrapdash.com/" target="_blank">Bootstrap admin template</a> from BootstrapDash. All rights reserved.</span>
            <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="ti-heart text-danger ml-1"></i></span> */}
                </div>
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                  {/* <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Distributed by <a href="https://www.themewagon.com/" target="_blank">Themewagon</a></span>  */}
                </div>
              </footer>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center text-center error-page bg-primary">
              <div className="row flex-grow">
                <div className="col-lg-7 mx-auto text-white">
                  <div className="row align-items-center d-flex flex-row">
                    <div className="col-lg-6 text-lg-right pr-lg-4">
                      {/* <h1 className="display-1 mb-0">Logout</h1> */}
                    </div>
                    <div className="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                      <h3 className="font-weight-light"></h3>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-12 text-center mt-xl-2">
                      <Link
                        to="/login"
                        className="text-white font-weight-medium"
                      >
                        <h2> Sorry You are Logout.</h2>
                      </Link>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-12 text-center mt-xl-2">
                      <Link
                        to="/login"
                        className="text-white font-weight-medium"
                      >
                        Back to home
                      </Link>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-12 mt-xl-2">
                      <p className="text-white font-weight-medium text-center">
                        All rights reserved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SharedLayout;
