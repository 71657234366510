import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Register, Login, Error } from "./pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminSharedLayout from "./pages/admin/SharedLayout";
import Dashboard from "./pages/admin/Dashboard";

import ViewCategory from "./pages/admin/ViewCategory";
import AddCategory from "./pages/admin/AddCategory";
import ViewSingleCategory from "./pages/admin/ViewSingleCategory";
import EditCategory from "./pages/admin/EditCategory";

import ViewSubCategory from "./pages/admin/ViewSubCategory";
import AddSubCategory from "./pages/admin/AddSubCategory";
import ViewSingleSubCategory from "./pages/admin/viewSingleSubCategory";
import EditSubCategory from "./pages/admin/EditSubCategory";

import ViewPledge from "./pages/admin/ViewAllPledge";
import AddPledge from "./pages/admin/AddPledge";
import ViewSinglePledge from "./pages/admin/ViewSinglePledge";
import EditPledge from "./pages/admin/EditSinglePledge";
import ViewAllPledgeUser from "./pages/admin/ViewAllPledgeUser";

import ViewEvent from "./pages/admin/ViewAllEvent";
import AddEvent from "./pages/admin/AddEvent";
import ViewSingleEvent from "./pages/admin/ViewSingleEvent";
import EditEvent from "./pages/admin/EditSingleEvent";

import ViewAllTemplate from "./pages/admin/ViewAllTemplate";
import ViewSingleTemplate from "./pages/admin/ViewSingleTemplate";
import ViewSingleTemplateFromCategory from "./pages/admin/ViewSingleTemplateFromCategory";


import User from "./pages/admin/Users";
import SingleUser from "./pages/admin/ViewSingleUser";
import EditSingleUser from "./pages/admin/EditSIngleUser";

import ViewNotification from "./pages/admin/ViewNotification";
import ViewSingleNotification from "./pages/admin/viewSingleNotification";
import EditNotification from "./pages/admin/EditNotification";
import AddNotification from "./pages/admin/Addnotification";
import ProtectedRoute from "./components/ProtectedRoute";
import MultiPages from "./pages/admin/ViewMultipages";
import ViewStaff from "./pages/admin/ViewSubStaff";
import AddStaff from "./pages/admin/AddStaff";
import EditStaff from "./pages/admin/EditSubStaff";
import ForgetPassword from "./pages/Forgetpassword";
import Feedback from "./pages/admin/Feedback";
import ChangePassword from "./pages/admin/ChangePassword";

import TranjectionDetail from "./pages/admin/TranjectionDetail";
import SubscriptionPlan from "./pages/admin/SubscriptionPlan";
import AddSubscriptionplan from "./pages/admin/AddSubscriptionPlan";
import EditSubscriptionplan from "./pages/admin/EditSubscriptionPlan";
import ViewSingleSubscriptionplan from "./pages/admin/ViewSingleSubscriptionPlan";

import SubscriptionSetting from "./pages/admin/Setting_Subscription";

import ViewAllDownload from "./pages/admin/ViewDownload";
import ViewDownloadLink from "./pages/admin/ViewDownloadLink";

import Test from "./pages/admin/Test";
import { AuthProvider } from './AuthContext';  // Import the AuthProvider
function App() {
  return (
    <AuthProvider>
    <BrowserRouter basename="/">
      <ToastContainer rtl/>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="*" element={<Error />} />

        {/* private start */}
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<AdminSharedLayout />}>
            {/* Admin file create here start */}
            <Route index path="dashboard" element={<Dashboard />} />
            <Route path="changepassword" element={<ChangePassword />} />
            <Route path="SubscriptionSetting" element={<SubscriptionSetting />} />
            
            <Route path="viewcategory" element={<ViewCategory />} />
            <Route path="addcategory" element={<AddCategory />} />
            <Route path="viewsinglecategory/:id" element={<ViewSingleCategory />} />
            <Route path="editcategory/:id" element={<EditCategory />} />
           
            <Route path="viewSubCategory/:categoryid" element={<ViewSubCategory />} />
            <Route path="addSubCategory/:categoryid" element={<AddSubCategory />} />
            <Route path="viewSingleSubcategory/:categoryid/:subcategoryid" element={<ViewSingleSubCategory />} />
            <Route path="editSingleSubcategory/:categoryid/:subcategoryid" element={<EditSubCategory/>} />


            <Route path="viewpledge" element={<ViewPledge />} />
            <Route path="addpledge" element={<AddPledge />} />
            <Route path="viewsinglepledge/:id" element={<ViewSinglePledge />} />
            <Route path="editpledge/:id" element={<EditPledge />} />
            <Route path="ViewAllPledgeUser/:id" element={<ViewAllPledgeUser />} />

            <Route path="viewevent" element={<ViewEvent />} />
            <Route path="addevent" element={<AddEvent />} />
            <Route path="viewsingleevent/:id" element={<ViewSingleEvent />} />
            <Route path="editevent/:id" element={<EditEvent />} />
           
            <Route path="viewalltemplate/:pageno" element={<ViewAllTemplate />} />
            {/* <Route path="viewsingletemplate/:id" element={<ViewSingleTemplate />} /> */}
            <Route path="viewsingletemplate/:id/:pageno" element={<ViewSingleTemplate />} />
            <Route path="ViewSingleTemplateFromCategory/:id/:backcategoryid" element={<ViewSingleTemplateFromCategory />} />
           
           
            <Route path="viewnotification" element={<ViewNotification />} />
            <Route path="editnotification/:id" element={<EditNotification />} />
            <Route path="viewsinglenotification/:id" element={<ViewSingleNotification />} />
            <Route path="addnotification" element={<AddNotification />} />
            <Route path="viewpages" element={<MultiPages />} />
           
            <Route path="viewstaff" element={<ViewStaff />} />

            <Route path="addstaff" element={<AddStaff />} />
            <Route path="editstaff/:id" element={<EditStaff />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="users/:userType" element={<User />} />
            <Route path="singleusers/:id/:userType" element={<SingleUser />} />
            <Route path="editsingleusers/:id/:userType" element={<EditSingleUser />} />
           
            <Route path="test" element={<Test />} />
            <Route path="tranjectionDetail" element={<TranjectionDetail />} />
          
            <Route path="subscriptionplan" element={<SubscriptionPlan />} />
            <Route path="addsubscriptionplan" element={<AddSubscriptionplan />} />
            <Route path="editsubscriptionplan/:id" element={<EditSubscriptionplan />} />

            <Route path="viewsinglesubscriptionplan/:id" element={<ViewSingleSubscriptionplan />} />
            

            <Route path="viewalldownload" element={<ViewAllDownload />} />
            <Route path="viewadownloadLink/:id" element={<ViewDownloadLink />} />

            {/* Admin file create here End */}
          </Route>
        </Route>
        {/* private end */}
      </Routes>
    </BrowserRouter> </AuthProvider>
  );
}

export default App;
