import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import

import "react-confirm-alert/src/react-confirm-alert.css";
import dateFormat from "dateformat";
import Pagination from "../../components/Pagination";
import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const ViewNotification = () => {
  const [notification, setnotification] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [showloader, setShowLoader] = useState("none");
  // Function to filter customers based on input field value ==========================================
  const itemsPerPage = 10; // Set the number of items per page

  const [searchfilterValue, setSearchFilterValue] = useState("");
  const [filtertype, setFilterType] = useState("DEFAULT");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [initialFetchDone, setInitialFetchDone] = useState(false); // Track initial fetch

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleInputChangeSearch = (e) => {
    setSearchFilterValue(e.target.value);
  };

  const SubmitSearch = async (e) => {
    e.preventDefault();
    if (!searchfilterValue) {
      toast.error("Please Enter Search Data");
      return;
    }
    setFilterType("SEARCH");
    const getdata = await axios.get(
      `${URL}/admin/MultiplefilterNotification?FILTERTYPE=${filtertype}&SEARCH=${searchfilterValue}&page=${currentPage}&limit=${itemsPerPage}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Singledata = await getdata;
    const Data1 = await Singledata;
    setnotification(Data1?.data?.data);
    setTotalPages(Data1?.data.totalPages);
  };

  const SubmitSearch2_by_button = async (e) => {
    setShowLoader("block");
    e.preventDefault();

    if (!searchfilterValue) {
      setShowLoader("none");
      toast.error("Please Enter Search Data");
      return;
    }
    setCurrentPage(1);
    setFilterType("SEARCH");
    const getdata = await axios.get(
      `${URL}/admin/MultiplefilterNotification?FILTERTYPE=SEARCH&SEARCH=${searchfilterValue}&page=${1}&limit=${itemsPerPage}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Singledata = await getdata;
    const Data1 = await Singledata;
    setnotification(Data1?.data?.data);
    setTotalPages(Data1?.data.totalPages);
    setShowLoader("none");
  };

  // ==========================================================
  //==================== Submit Search End ======================================
  // ==========================================================

  //======================= Date filter start================================================
  // ==========================================================
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "startDate") {
      if (new Date(value) > new Date(endDate)) {
        toast.error("Start date cannot be later than end date.");
      } else {
        setstartDate(value);
      }
    }

    if (name === "endDate") {
      if (!startDate) {
        toast.error("Please Select a Start Date First");
        return;
      }
      if (new Date(value) < new Date(startDate)) {
        toast.error("End date cannot be earlier than start date.");
      } else {
        setendDate(value);
      }
    }
  };

  const onSubmitBothDate = async (e) => {
    // e.preventDefault();
    if (!startDate || !endDate) {
      toast.error("Please fill Start Date and End Date");
      return;
    }
    setFilterType("DATE");
    const getdata = await axios.get(
      `${URL}/admin/MultiplefilterNotification?FILTERTYPE=DATE&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${itemsPerPage}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Singledata = await getdata;
    const Data1 = await Singledata;
    setnotification(Data1?.data?.data);
    setTotalPages(Data1?.data.totalPages);
    setShowLoader("none");
  };

  const onSubmitByButton = async (e) => {
    setShowLoader("block");
    e.preventDefault();
    setCurrentPage(1);
    if (!startDate || !endDate) {
      toast.error("Please fill Start Date and End Date");
      return;
    }
    setFilterType("DATE");
    const getdata = await axios.get(
      `${URL}/admin/MultiplefilterNotification?FILTERTYPE=DATE&startDate=${startDate}&endDate=${endDate}&page=${1}&limit=${itemsPerPage}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Singledata = await getdata;
    const Data1 = await Singledata;
    setnotification(Data1?.data?.data);
    setTotalPages(Data1?.data.totalPages);
    setShowLoader("none");
  };

  // ======================================================================
  //=============================== By Date Function ========================
  // ======================================================================

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  // const getAllData = async () => {
  //   setShowLoader("block");
  //   const getData = await axios.get(`${URL}/admin/getAllNotification`);
  //   const Data1 = await getData;
  //   setnotification(Data1.data.data);
  //   setShowLoader("none");
  //   console.log(Data1.data.data);
  // };

  // useEffect(() => {
  //   getAllData();
  // }, []);
  const getAllData = async () => {
    setShowLoader("block");
    setFilterType("DEFAULT");
    const getData = await axios.get(
      `${URL}/admin/MultiplefilterNotification?FILTERTYPE=${filtertype}&page=${currentPage}&limit=${itemsPerPage}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Data1 = await getData;
    setnotification(Data1?.data?.data);
    setTotalPages(Data1?.data.totalPages);
    setShowLoader("none");
  };

  useEffect(() => {
    // getAllData(); // eslint-disable-next-line
    // setInitialFetchDone(true);
  }, []);
  useEffect(() => {
    if (!initialFetchDone) {
      getAllData(); // eslint-disable-next-line
      setInitialFetchDone(true);
    } else {
      if (filtertype === "DEFAULT") {
        // eslint-disable-next-line
        getAllData(); // eslint-disable-next-line
      }
      if (filtertype === "SEARCH") {
        // eslint-disable-next-line
        SubmitSearch(); // eslint-disable-next-line
      }
      if (filtertype === "DATE") {
        // eslint-disable-next-line
        onSubmitBothDate(); // eslint-disable-next-line
      }
    }
  }, [currentPage]);
  const ClearData = () => {
    getAllData();
    setSearchFilterValue("");
    setstartDate("");
    setendDate("");
    setCurrentPage(1);
  };
  const confirmDelete = (id) => {
    confirmAlert({
      message: "Are you sure you want to delete this Data.",
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteData(id),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const DeleteData = async (Linkid) => {
    await axios
      .patch(`${URL}/admin/updateDeleteStatusNotification/${Linkid}`, {
        headers: { "X-API-Key": APP_X_API_KEY },
      })
      .then((result) => {
        toast.success("Notification Delete Successfully");
        getAllData();
      })
      .catch((error) => {
        alert("service error");
        // console.log(error);
      });
  };
  // ====================================================
  const startIndex = (currentPage - 1) * itemsPerPage;
  // ====================================================
  return (
    <div className="content-wrapper user-page form-bar">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      {/* Filter start */}
      <div className="row">
        <div className="col-md-12">
          <div class="form-inline">
            <form
              onSubmit={SubmitSearch2_by_button}
              class="input-group mb-3 mr-sm-5"
            >
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search"
                maxlength="50"
                value={searchfilterValue}
                onChange={handleInputChangeSearch}
              />
              <div class="input-group-append">
                <button class="btn btn-sm btn-primary" type="submit">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </form>

            <form onSubmit={onSubmitByButton} class="form-inline">
              <div class="input-group mb-3 mr-sm-3">
                <label
                  for="startDate"
                  className="mr-3 d-flex align-items-center"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-sm mr-sm-2"
                  id="startDate"
                  placeholder="Enter StartDate"
                  name="startDate"
                  onKeyDown={(e) => e.preventDefault()}
                  onClick={(e) => e.target.showPicker()}
                  value={startDate}
                  onChange={handleChange}
                />
              </div>
              <div class="input-group mb-3 mr-sm-3">
                <label for="endDate" className="mr-3 d-flex align-items-center">
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-sm mr-sm-2"
                  id="endDate"
                  placeholder="Enter EndDate"
                  name="endDate"
                  onKeyDown={(e) => e.preventDefault()}
                  onClick={(e) => e.target.showPicker()}
                  value={endDate}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex">
                <button type="submit" class="btn btn-primary mb-3 mr-3">
                  Submit
                </button>
                <span className="btn btn-primary mb-3" onClick={ClearData}>
                  Clear
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* FIlter end */}
      <div className="row">
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="card-title">View Notifications</h4>
                <Link
                  to="/addnotification"
                  className="btn btn-primary link_button"
                >
                  Add Notification
                </Link>
              </div>
              {/* <p className="card-description">Add className</p> */}
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      <th>Sr.no</th>
                      <th>Image</th>
                      <th>Title</th>

                      {/* <th>DESCRIPTION</th> */}
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notification?.map((val, index) => {
                      return (
                        <>
                          {/* {val?.notificationforSingleuser === "false" ? ( */}
                          <tr key={index}>
                            <td className="py-1">{startIndex + index + 1}</td>
                            <td class="py-1">
                              {val?.presignedUrl1 ? (
                                <img src={val?.presignedUrl1} alt="img" />
                              ) : (
                                "No Image"
                              )}
                            </td>
                            <td>{val?.title}</td>

                            {/* <td>
                        {val?.description} 
                        
                        </td> */}
                            <td>
                              {dateFormat(`${val?.createdAt}`, "mmmm dS, yyyy")}
                            </td>
                            <td>
                              <Link
                                className="btn btn-primary"
                                to={`/viewsinglenotification/${val._id}`}
                              >
                                <i class="fa-solid fa-eye menu-icon"></i>
                              </Link>
                              <Link className="btn btn-primary ml-2">
                                <i
                                  className="fa-solid fa-trash menu-icon "
                                  onClick={() => confirmDelete(val?._id)}
                                ></i>
                              </Link>
                            </td>
                          </tr>
                          {/* ): null } */}
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {Pagination.length === 0 ? (
                  <p className="text-center mt-2 bold ">No Data Found</p>
                ) : null}
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};

export default ViewNotification;
