import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
// import WhatsappSend from "../../utils/WhatsappSend";

const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const EditSubCategory = () => {
  const { categoryid, subcategoryid } = useParams();

  const navigate = useNavigate();
  const backcategoryid = categoryid;
  const [values, setValues] = useState({
    title: "",
    description: "",
    templateid1: "",
    categoryid: categoryid,
    presignedUrl1: "",
    imageurl:
      "https://res.cloudinary.com/demo/image/upload/d_avatar.png/non_existing_id.png",
  });
  const [template, settemplate] = useState([]);
  const [showloader, setShowLoader] = useState("none");

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const getSingleData = async () => {
    setShowLoader("block");
    const getData = await axios.get(
      `${URL}/admin/getSingleSubCategoryById/${subcategoryid}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Data1 = await getData;
    setValues(Data1?.data?.data);
    setShowLoader("none");
  };

  useEffect(() => {
    getSingleData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getAllTemplate`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getData;
    settemplate(Data1?.data?.data);
    setShowLoader("none");
  };

  useEffect(() => {
    getAllData();
  }, []);

  const UpdateData = async () => {
    const {
      title,
      description,
      imageurl,
      templateid1,
      categoryid,
      // templateid1,
      // templateid2,
      // templateid3,
      // templateid4,
    } = values;

    setShowLoader("block");
    const url = `${URL}/admin/updateSubCategoryById/${subcategoryid}`;
    // const config = {
    //   headers: { "content-type": "multipart/form-data" },
    // };
    // const formData = new FormData();
    // if(image){
    //     formData.append("file", image);
    //   }
    // formData.append("title", values.title);
    // formData.append("description", values.description);
    //const dt1={title:values.title,description:values.description}
    axios
      .patch(
        url,
        {
          title,
          description,
          imageurl,
          templateid1,
          categoryid,
          // templateid2,
          // templateid3,
          // templateid4,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Sub Category Update Successfully");
        navigate(`/viewSubCategory/${backcategoryid}`);
        // getAllData();
        setShowLoader("none");
      })
      .catch((error) => {
        toast.error("Failed to update.");
        // alert("service error");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      title,
      imageurl,
      description,
      templateid1,
      // templateid1,
      // templateid2,
      // templateid3,
      // templateid4,
    } = values;

    if (!title) {
      toast.error("Please fill Title");
      return;
    }
    if (!description) {
      toast.error("Please fill Description");
      return;
    }
    if (!imageurl) {
      toast.error("Please add Image");
      return;
    }
    if (!templateid1) {
      toast.error("Please Select Template");
      return;
    }

    // if (!templateid1) {
    //   toast.error("Please fill out templateid1");
    //   return;
    // }
    // if (!templateid2) {
    //   toast.error("Please fill out templateid2");
    //   return;
    // }
    // if (!templateid3) {
    //   toast.error("Please fill out  templateid3");
    //   return;
    // }
    // if (!templateid4) {
    //   toast.error("Please fill out  templateid4");
    //   return;
    // }

    UpdateData();
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };
  // Image Upload 0
  const handleImageChange = async (e) => {
    setShowLoader("block");
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    var requestOptions = {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-API-Key": APP_X_API_KEY,
      },
    };
    try {
      const fetchdata = axios.post(
        `${URL}/admin/imageUpload_Use/imageUpload`,
        formData,
        requestOptions
      );
      const response = await fetchdata;
      if (response.status === 200) {
        //toast.success("Data Uploaded Successfully");
        setShowLoader("none");
        //seturl(response?.data?.url);
        setValues({
          ...values,
          imageurl: response?.data?.url,
          presignedUrl1: response?.data?.presignedUrl,
        });
      } else {
        setShowLoader("none");
        toast.error("Fail To Load");
      }
    } catch (error) {
      setShowLoader("none");
      toast.error(
        "An error occurred while uploading the image. Please try again."
      );
    }
  };

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>

      <Link
        to={`/viewSubCategory/${categoryid}`}
        className="btn btn-primary mb-2"
      >
        {" "}
        Back{" "}
      </Link>

      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Edit Sub Category</h4>
              {values?.presignedUrl1 ? (
                <img
                  src={values?.presignedUrl1}
                  alt={values?.presignedUrl1}
                  className="mt-4"
                ></img>
              ) : null}

              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="Enter Name"
                        name="title"
                        maxlength="60"
                        value={values?.title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">
                        Small Description
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="Enter Description"
                        name="description"
                        maxlength="70"
                        value={values?.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1"> Image</label>
                      <div
                        className="loader-container "
                        style={{ display: showloader }}
                      >
                        <img src={loaderimg} alt="" className="loaderImage" />
                      </div>
                      <input
                        type="file"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="Image Upload"
                        name="file"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">
                        Assign template 1
                      </label>
                      <div
                        className="loader-container "
                        style={{ display: showloader }}
                      >
                        <img src={loaderimg} alt="" className="loaderImage" />
                      </div>
                      <select
                        className="form-control"
                        name="templateid1"
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {template?.map((tmp, index) => {
                          return (
                            <option
                              value={tmp?._id}
                              selected={tmp?._id === values?.templateid1?._id}
                            >
                              {tmp?.number}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {/* 
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">
                        Assign template 2
                      </label>
                      <div
                        className="loader-container "
                        style={{ display: showloader }}
                      >
                        <img src={loaderimg} alt="" className="loaderImage" />
                      </div>
                      <select
                        className="form-control"
                        name="templateid2"
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {template?.map((tmp, index) => {
                          return (
                            <option
                              value={tmp?._id}
                              selected={tmp?._id === values?.templateid2?._id}
                            >
                              {tmp?.number}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">
                        Assign template 3
                      </label>
                      <div
                        className="loader-container "
                        style={{ display: showloader }}
                      >
                        <img src={loaderimg} alt="" className="loaderImage" />
                      </div>
                      <select
                        className="form-control"
                        name="templateid3"
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {template?.map((tmp, index) => {
                          return (
                            <option
                              value={tmp?._id}
                              selected={tmp?._id === values?.templateid3?._id}
                            >
                              {tmp?.number}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">
                        Assign template 4
                      </label>
                      <div
                        className="loader-container "
                        style={{ display: showloader }}
                      >
                        <img src={loaderimg} alt="" className="loaderImage" />
                      </div>
                      <select
                        className="form-control"
                        name="templateid4"
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {template?.map((tmp, index) => {
                          return (
                            <option
                              value={tmp?._id}
                              selected={tmp?._id === values?.templateid4?._id}
                            >
                              {tmp?.number}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>*/}
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default EditSubCategory;
