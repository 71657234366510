import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link ,useParams} from "react-router-dom";

import "react-confirm-alert/src/react-confirm-alert.css";
// import dateFormat from "dateformat";
import Pagination from "../../components/Pagination";

import loaderimg from "../../assets/image/loader.gif";
import temp1 from "../../assets/image/templates-img/temp-1.png";
import temp2 from "../../assets/image/templates-img/temp-2.png";
import temp3 from "../../assets/image/templates-img/temp-3.png";
import temp4 from "../../assets/image/templates-img/temp-4.png";
import temp4_1 from "../../assets/image/templates-img/temp-4.1.png";
import temp5 from "../../assets/image/templates-img/temp-5.png";
import temp6 from "../../assets/image/templates-img/temp-6.png";
import temp7 from "../../assets/image/templates-img/temp-7.png";
import temp10 from "../../assets/image/templates-img/temp-10.png";
import temp11 from "../../assets/image/templates-img/temp-11.png";
import temp13 from "../../assets/image/templates-img/temp-13.png";
import temp15 from "../../assets/image/templates-img/temp-15.png";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const ViewAllTemplate = () => {
  const { pageno } = useParams();
  const [template, settemplate] = useState([]);

  const [showloader, setShowLoader] = useState("none");
  // Function to filter customers based on input field value ==========================================
  const itemsPerPage = 16; // Set the number of items per page

  const [searchfilterValue, setSearchFilterValue] = useState("");
  const [filtertype, setFilterType] = useState("DEFAULT");
  const [currentPage, setCurrentPage] = useState(pageno?pageno:1);
  const [totalPages, setTotalPages] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const getAllData = async () => {
    setShowLoader("block");
    setFilterType("DEFAULT");
    const getData = await axios.get(
      `${URL}/admin/MultiplefilterTemplate?FILTERTYPE=${filtertype}&page=${currentPage}&limit=${itemsPerPage}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Data1 = await getData;
    settemplate(Data1?.data?.data);
    setTotalPages(Data1?.data.totalPages);
    setShowLoader("none");
  };

  useEffect(() => {
    
    getAllData(); // eslint-disable-next-line
   
  }, []);
  useEffect(() => {
    if (filtertype === "DEFAULT") {
      // eslint-disable-next-line
      getAllData(); // eslint-disable-next-line
    }
  }, [currentPage]);
  // ====================================================
  const startIndex = (currentPage - 1) * itemsPerPage;
  // ====================================================
  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div
        className="row all-temp-page"
        style={{
          maxHeight: "1800px", // Set maximum height for the scrollable div
          overflowY: "auto", // Enable vertical scrolling
          // border: "1px solid #ccc",  Optional: Add border for visual clarity
          padding: "10px", // Optional: Add padding inside the div
        }}
      >
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">View All Template</h4>
              {/* <Link to="/addevent" className="btn btn-primary">
                Add Event
              </Link> */}
              {/* <p className="card-description">Add className</p> */}

              <div className="card-scrol">
                <div className="row">
                  {template?.map((val, index) => {
                    return (
                      <>
                        <div className="col-md-3 col-lg-2 mx-auto mb-4 px-3 alltemp-col">
                          <Link
                            style={{ width: "100%" }}
                            to={`/viewsingletemplate/${val?._id}/${currentPage}`}
                          >
                            <div className="p-0 position-relative" >
                              <p className="d-flex justify-content-center align-items-center card_serial_number">
                                {val?.number}
                              </p>
                              <i className="fa fa-pencil card-pencil all_temp_card_pencil align-item-end">
                                {" "}
                              </i>

                              {" "}
                              <img src={val.presignedUrl2} className="w-100" style={{ maxHeight: "300px"}} />
                            </div>
                          </Link>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};

export default ViewAllTemplate;
