import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
// import dateFormat from "dateformat";

import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const SubscriptionPlan = () => {
  const [subscription, setsubscription] = useState([]);

  const [showloader, setShowLoader] = useState("none");
  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getAllSubscriptionPlan`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getData;
    setsubscription(Data1?.data?.data);
    setShowLoader("none");
  };

  useEffect(() => {
    getAllData();
  }, []);
  const confirmDelete = (LinkId) => {
    confirmAlert({
      message: "Are you sure you want to delete this Data.",
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteData(LinkId),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const DeleteData = async (Linkid) => {
    await axios
      .patch(`${URL}/admin/deleteSubscriptionPlanById/${Linkid}`, {
        headers: { "X-API-Key": APP_X_API_KEY },
      })
      .then((result) => {
        toast.success("Subscription Plan Delete Successfully");
        getAllData();
      })
      .catch((error) => {
        alert("service error");
        // console.log(error);
      });
  };

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-end">
          <Link
            to="/SubscriptionSetting"
            className="btn btn-primary link_button mb-3"
          >
            Setting
          </Link>
        </div>
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="card-title">View subscription</h4>
                <Link
                  to="/addsubscriptionplan"
                  className="btn btn-primary link_button"
                >
                  Add subscription
                </Link>
              </div>
              {/* <p className="card-description">Add className</p> */}
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      <th>Sr.no</th>
                      <th>Subscription name</th>
                      <th>Android price </th>
                      <th>iOS price</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscription?.map((val, index) => {
                      return (
                        <>
                          {/* {val?.notificationforSingleuser === "false" ? ( */}
                          <tr key={index}>
                            <td className="py-1"> {index + 1}</td>

                            <td>{val?.title}</td>

                            <td>{val?.android_price}</td>
                            <td>{val?.ios_price}</td>

                            {val?.freeplan === 1 ? (
                              <td>7 Days Free Plan</td>
                            ) : val?.socialfreeplan === 1 ? (
                              <td>Social Worker Plan</td>
                            ) : (
                              <td>
                                <Link
                                  className="btn btn-primary"
                                  to={`/viewsinglesubscriptionplan/${val?._id}`}
                                >
                                  <i class="fa-solid fa-eye menu-icon"></i>
                                </Link>
                                <Link
                                  className="btn btn-primary ml-2"
                                  to={`/editsubscriptionplan/${val?._id}`}
                                >
                                  <i class="fa-solid fa-edit menu-icon"></i>
                                </Link>

                                <Link className="btn btn-primary ml-2">
                                  <i
                                    class="fa-solid fa-trash menu-icon "
                                    onClick={() => confirmDelete(val?._id)}
                                  ></i>
                                </Link>
                              </td>
                            )}
                          </tr>
                          {/* ): null } */}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};

export default SubscriptionPlan;
