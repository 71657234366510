import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const EditSubStaff = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    loginType: "",
    permission: [],
  });
  const [permission, setPermission] = useState([]);
  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const { id } = useParams();

  const UpdateData = async () => {
    const url = `${URL}/admin/updateSubStaff/${id}`;
    const { name, email, phone, loginType } = values;
    axios
      .patch(
        url,
        {
          name: name,
          email: email,
          phone: phone,
          loginType: loginType,
          permission: permission,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Data Update Successfully");
        navigate("/viewstaff");
      })
      .catch((error) => {
        //alert("Data Already Exist (EMAIL / USERNAME)");
        // console.log(error);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log(values);
    const { name, email, phone, loginType } = values;

    if (!name || !email || !phone || !loginType) {
      toast.error("Please fill out all fields");
      return;
    }
    UpdateData();
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };
  const handleCheckboxChange = (e) => {
    let selectedCheckboxes = [...permission];
    const isChecked = selectedCheckboxes.includes(e.target.value);
    if (!isChecked) {
      selectedCheckboxes.push(e.target.value);
    } else {
      selectedCheckboxes.splice(selectedCheckboxes.indexOf(e.target.value), 1);
    }
    setPermission(selectedCheckboxes);
  };

  useEffect(() => {
    const getData = async () => {
      const getdata = await axios.get(`${URL}/admin/getstaffinfo/${id}`, {
        headers: { "X-API-Key": APP_X_API_KEY },
      });
      const Singledata = await getdata;
      setValues(Singledata?.data?.data);
      setPermission(Singledata?.data?.data?.permission);
      // console.log(Singledata.data.data);
    };

    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="content-wrapper">
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">EDIT STAFF</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label for="exampleInputUsername1">NAME</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Enter NAME"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label for="exampleInputEmail1">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label for="exampleInputEmail1">PHONE</label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">
                    Login Type{values.loginType}
                  </label>

                  <select
                    name="loginType"
                    value={values.loginType}
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option
                      value="SuperAdmin"
                      // selected={"SuperAdmin" === values.loginType}
                    >
                      SuperAdmin
                    </option>
                    <option
                      value="SubAdmin"
                      // selected={"SubAdmin" === values.loginType}
                    >
                      SubAdmin
                    </option>
                    <option
                      value="Accountant"
                      // selected={"Accountant" === values.loginType}
                    >
                      Accountant
                    </option>
                    <option
                      value="Delivery"
                      // selected={"Delivery" === values.loginType}
                    >
                      Delivery
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label>PERMISSIONS : -</label>
                  <div className="ml-5">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="1"
                        name="1"
                        checked={permission.includes("1") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      USER
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="2"
                        name="2"
                        checked={permission.includes("2") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      CATEGORIES
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="3"
                        name="3"
                        checked={permission.includes("3") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      VIDEOS
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="4"
                        name="4"
                        checked={permission.includes("4") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      SUBSTAFF
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="5"
                        name="5"
                        checked={permission.includes("5") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      PERSON TYPE
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="6"
                        name="6"
                        checked={permission.includes("6") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      NOTIFICATION
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="7"
                        name="7"
                        checked={permission.includes("7") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      PAGES
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="8"
                        name="8"
                        checked={permission.includes("8") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      SKILL LEVEL
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="9"
                        name="9"
                        checked={permission.includes("9") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      WORK CYCLE
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="10"
                        name="10"
                        checked={permission.includes("10") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      BANNER/VIDEO WEEK
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="11"
                        name="11"
                        checked={permission.includes("11") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      Change User Skill
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="13"
                        name="13"
                        checked={permission.includes("13") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      Analytics
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="14"
                        name="14"
                        checked={permission.includes("14") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      Tranjection Detail
                    </label>
                    <br />
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value="15"
                        name="15"
                        checked={permission.includes("15") ? "checked" : null}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      Subscription Plan
                    </label>
                    <br />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default EditSubStaff;
