import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
// import WhatsappSend from "../../utils/WhatsappSend";
import html2canvas from "html2canvas";
import htmlToImage from "html-to-image";
import { Canvg } from "canvg";
import domtoimage from "dom-to-image";

import temp1 from '../../assets/image/templates-img/temp-1.png';
import temp2 from "../../assets/image/templates-img/temp-2.png";
import temp3 from "../../assets/image/templates-img/temp-3.png";
import temp4 from "../../assets/image/templates-img/temp-4.png";
import temp4_1 from "../../assets/image/templates-img/temp-4.1.png";
import temp5 from "../../assets/image/templates-img/temp-5.png";
import temp6 from "../../assets/image/templates-img/temp-6.png";
import temp7 from "../../assets/image/templates-img/temp-7.png";
import temp10 from "../../assets/image/templates-img/temp-10.png";
import temp11 from "../../assets/image/templates-img/temp-11.png";
import temp12 from "../../assets/image/templates-img/temp-12.png";
import temp13 from "../../assets/image/templates-img/temp-13.png";
import temp15 from "../../assets/image/templates-img/temp-15.png";
import temp17 from "../../assets/image/templates-img/temp-17.png";
import temp18 from "../../assets/image/templates-img/temp-18.png";
import temp19 from "../../assets/image/templates-img/temp-19.png";
import temp20 from "../../assets/image/templates-img/temp-20.png";
import temp21 from "../../assets/image/templates-img/temp-21.png";
import temp22 from "../../assets/image/templates-img/temp-22.png";
import temp23 from "../../assets/image/templates-img/temp-23.png";
import temp24 from "../../assets/image/templates-img/temp-24.png";
import temp25 from "../../assets/image/templates-img/temp-25.png";
import temp26 from "../../assets/image/templates-img/temp-26.png";
import temp27 from "../../assets/image/templates-img/temp-27.png";
import temp28 from "../../assets/image/templates-img/temp-28.png";
import temp29 from "../../assets/image/templates-img/temp-29.png";
import temp30 from "../../assets/image/templates-img/temp-30.png";
import temp31 from "../../assets/image/templates-img/temp-31.png";
import temp37 from "../../assets/image/templates-img/temp-37.png";
import temp46 from "../../assets/image/templates-img/temp-46.png";
import temp49 from "../../assets/image/templates-img/temp-49.png";


const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const initialstate = {
  imageurl: "",
  presignedUrl1: "",
};

const ViewSingleTemplate = () => {
  const { id, pageno } = useParams();
  const divRef = useRef(null);

  const navigate = useNavigate();
  const [values, setValues] = useState(initialstate);
  const [thumbnailimage, setThumbnailimage] = useState("");
  const [preview, setPreview] = useState(null);
  const [dummy1, setDummy1] = useState('');
  const [dummy2, setDummy2] = useState('Your Name');
  const [dummy3, setDummy3] = useState('');
  const [isNewImageUpload, seIsNewImageUpload] = useState("NO");

  const [showloader, setShowLoader] = useState("none");
  const wordLimit = 10;

  // ======================Dummy data================================
  const onHandleChangeDummy1 = (e) => {
    const value = e.target.value;

  }
  const onHandleChangeDummy2 = (e) => {
    const value = e.target.value;
    setDummy2(value);
  }
  const onHandleChangeDummy3 = (e) => {
    const value = e.target.value;
    setDummy3(value);
  }
  // Capture image ===================================================
  // const [dynamicImageUrl, setDynamicImageUrl] = useState(imageUrl);

  // Define the background image style with a dynamic URL
  const filter = (node) => {
    // Only process images with presigned URLs
    return node.tagName !== 'IMG' || node.crossOrigin === 'anonymous';
  };
  const handleCapture = async () => {
    // alert("Check 1");
    if (divRef.current) {
      // Ensure image is fully loaded
      const img = new Image();
      // img.crossOrigin = '*';

      img.src = values?.presignedUrl1;
      img.onload = () => {
        domtoimage.toPng(divRef.current, { filter }).then((dataUrl) => {
          console.log('dataUrl:', dataUrl);
          uploadImage(dataUrl);
        });
      };
    }
  };
  // Function to convert a base64 string to a Blob object
  function base64ToBlob(base64, contentType) {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }
  const uploadImage = async (file) => {
    // alert(" Check 2");
    const blob = base64ToBlob(file, "image/png");

    const formData = new FormData();
    formData.append("file", blob, "screenshot.png");
    try {
      const response1 = await axios.post(
        `${URL}/admin/imageUpload_Use/imageUpload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-API-Key": APP_X_API_KEY,
          }, mode: 'cors',     // CORS mode
          cache: 'no-cache' // Disable caching
        }
      );

      const response = await response1;
      if (response.status === 200) {
        setThumbnailimage(
          // response?.data?.url
          response?.data?.presignedUrl
        );
        // alert("test stopped her");
        UploadthumbnailData(response?.data?.url);
      } else {
      }
    } catch (error) { }
  };

  const UploadthumbnailData = async (URL_THUMB) => {
    setShowLoader("block");
    // alert("Check 3");
    // alert(URL_THUMB);
    const url = `${URL}/admin/updateTemplateSnapshotimage/${id}`;

    axios
      .patch(
        url,
        {
          imageurl2: URL_THUMB,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Template Update Successfully");
        // navigate("/viewalltemplate");
        setShowLoader("none");
      })
      .catch((error) => {
        setShowLoader("none");
        alert("service error");
      });
  };

  // Capture image end================================================

  useEffect(() => {
    const getData = async () => {
      const getdata = await axios.get(`${URL}/admin/getSingleTemplate/${id}`, {
        headers: { "X-API-Key": APP_X_API_KEY },
      });
      const Singledata = await getdata;
      setValues(Singledata?.data?.data);
    };

    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (e) => {
    e.preventDefault();
    const { imageurl } = values;

    if (!imageurl) {
      toast.error("Please Select Image");
      return;
    }
    UpdateData(values);
  };
  const UpdateData = async () => {
    setShowLoader("block");
    const url = `${URL}/admin/updateTemplate/${id}`;

    axios
      .patch(
        url,
        {
          imageurl: values?.imageurl,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        // handleImageChangethumbnailimage();
        handleCapture();
        seIsNewImageUpload("NO");
        // toast.success("Data Update Successfully");
        // navigate("/viewalltemplate");
        //setShowLoader("none");
      })
      .catch((error) => {
        setShowLoader("none");
        alert("service error");
      });
  };

  // const handleChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setValues({ ...values, [name]: value });
  // };
  const handleImageChange = async (e) => {
    setShowLoader("block");
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    var requestOptions = {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-API-Key": APP_X_API_KEY,
      },
    };
    try {
      const fetchdata = axios.post(
        `${URL}/admin/imageUpload_Use/imageUpload`,
        formData,
        requestOptions
      );
      const response = await fetchdata;
      if (response.status === 200) {
        //toast.success("Data Uploaded Successfully");
        setShowLoader("none");
        seIsNewImageUpload("YES");
        //seturl(response?.data?.url);
        setValues({
          ...values,
          imageurl: response?.data?.url,
          presignedUrl1: response?.data?.presignedUrl,
        });
      } else {
        setShowLoader("none");
        toast.error("Fail To Load");
      }
    } catch (error) {
      setShowLoader("none");
      console.error("Error uploading image:", error);
      toast.error(
        "An error occurred while uploading the image. Please try again."
      );
    }
  };

  // Snapshot image====================================

  // const backgroundImageStyle = {
  //   backgroundImage:
  //     'url("https://images.pexels.com/photos/1363157/pexels-photo-1363157.jpeg?auto=compress&cs=tinysrgb&w=600")',
  //     backgroundSize: "cover",
  //     backgroundPosition: "center",
  //     width: "400px",
  //     height: "500px",
  // };

  // const backgroundImageStyle = {
  //   backgroundImage: `url("${values?.presignedUrl1}")`,
  //   backgroundSize: "cover",
  //   backgroundPosition: "center", width: "400px",
  //   height: "500px",
  // };
  // const backgroundImageStyle = {
  //   backgroundImage: `url("${dynamicImageUrl}")`,
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  // };

  const dynamicImage = { backgroundImage: `url("${values?.presignedUrl1}")`, height: "150px", width: "100%", backgroundPosition: "center", backgroundSize: "cover" }

  // ===================================================================================
  // const isoDate = new Date(value?.date);
  //   const formattedDate = isoDate.toISOString().split('T')[0];
  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link to={`/viewalltemplate/${pageno}`} className="btn btn-primary mb-2">
        {" "}
        Back{" "}
      </Link>

      <div className="row single-temp-page">
        {/* <div ref={divRef} style={{ width: '300px', height: '300px', border: '1px solid #000' }}>
       
        <p>25222Content to be captured</p>
        <img src="https://t4.ftcdn.net/jpg/06/96/23/37/240_F_696233797_fk5Vfi39xnqaRQyrunqL7vkWR6YscS5Q.jpg" alt="Background"  />
      
      </div> */}
        {/* {/ Testing /}
        {/ SECOND FORM /} */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              {/* {/  /} */}
              <h4 className="card-title">Edit Template No {values.number}</h4>
              {/* <h4 className="card-title" onClick={handleCapture}>
                Only Snap Shot Click Here
              </h4> */}
              <p className="card-description"></p>
              <form className="forms-sample m-0" onSubmit={onSubmit}>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">TEMPLATE</label>

                      <input
                        type="file"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="Image Upload"
                        name="file"
                        onChange={handleImageChange}
                      />
                      {/* {/ <img src={values.imageurl} alt="img" /> /} */}
                    </div>
                {isNewImageUpload === "YES"? <button type="submit" className="btn btn-primary mr-2">
                      Update Template
                    </button>:null}    
                  </div>
                </div>
                {/* <img src={thumbnailimage} alt="temp img" style={{ width: '50%' }} /> */}

                <div ref={divRef} style={{ width: "365px" }} className="d-flex justify-content-center ">

                  {/*===========================start Template number 1 =================== */}
                  {values.number === 1 ? (
                    <div className="row ">
                      <div className="col-md-3 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative">
                            {" "}
                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp1})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                              {/* <img src={temp1} alt="" /> */}
                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "fit-content" }}>
                              <div className="" style={{ backgroundImage: `url("${values?.presignedUrl1}")`, height: "150px", width: "100%", backgroundPosition: "center", backgroundSize: "cover" }}></div>
                              <div
                                className="d-flex mr-auto mb-1 ml-2 mt-4 rounded-pill bg-light"
                                style={{ width: "80px", height: "80px", position: "absolute", top: "22px", left: "10px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 h-75 all_temp_input mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  // readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                  style={{ borderColor: "transparent !important" }}
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 1 =================== */}

                  {/*===========================start Template number 2 =================== */}
                  {values.number === 2 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative">
                            {" "}
                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp2})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                              {/* <img src={temp1} alt="" /> */}
                            </div>
                            <div className="col-12 mx-auto p-0">
                              <div className="" style={{ backgroundImage: `url("${values?.presignedUrl1}")`, height: "150px", width: "100%", backgroundPosition: "center", backgroundSize: "cover" }}></div>
                              <div
                                className="d-flex mr-auto mb-1 ml-2 mt-4 rounded-pill bg-light"
                                style={{ width: "80px", height: "80px", position: "absolute", top: "22px", right: "25px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-10 mx-auto p-0 mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                  style={{ borderColor: "transparent !important" }}
                                />
                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 2 =================== */}

                  {/*===========================start Template number 3 =================== */}
                  {values.number === 3 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative">
                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp3})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div>
                            <div className="col-12 mx-auto p-0">
                              <div className="" style={{ backgroundImage: `url("${values?.presignedUrl1}")`, height: "150px", width: "100%", backgroundPosition: "center", backgroundSize: "cover" }}></div>
                              <div
                                className="d-flex mr-auto mb-1 ml-2 mt-4 rounded-pill bg-light"
                                style={{ width: "80px", height: "80px", position: "absolute", top: "0px", right: "25px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-8 mx-auto p-0 mb-4">
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "130px" }}
                              >
                                <input
                                type="text"
                                name="text"
                                readOnly
                                className="w-100 h-100 all_temp_input-dark mb-3"
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 3 =================== */}

                  {/*===========================start Template number 4 =================== */}
                  {values.number === 4 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative bg-white"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >
                            <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div>
                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp4})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp4_1})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto" style={{ height: "100px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "55px", height: "55px", top: "-15px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "70%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                readOnly
                                className="w-100 h-100 all_temp_input-dark mb-3"
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 4 =================== */}

                  {/*===========================start Template number 5 =================== */}
                  {values.number === 5 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                          // style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >
                            <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div>
                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp5})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>
                            </div>
                            <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div>
                            <div className="col-12 mx-auto">
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "55px", height: "55px", top: "-15px", zIndex: "11", left: "42%" }}
                              ></div>
                            </div>
                            <div className="col-12 mx-auto px-5  mb-4 ">
                              <div
                                className="w-100 rounded mb-5 position-relative rounded-circle"
                                style={{ height: "330px", backgroundImage: `url(${values?.presignedUrl1})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
                              >
                              </div>
                              {/* <input
                                type="text"
                                name="text"
                                readOnly
                                className="w-100 h-100 all_temp_input mb-3 rounded-circle"

                              />
                              <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "20%", marginLeft: "-47px" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                {dummy1}
                              </textarea> */}
                            </div>

                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 5 =================== */}

                  {/*===========================start Template number 6 =================== */}
                  {values.number === 6 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative">
                            {" "}
                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp6})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                              {/* <img src={temp1} alt="" /> */}
                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "260px" }}>
                              <div className="" style={{ backgroundImage: `url("${values?.presignedUrl1}")`, height: "215px", width: "100%", backgroundPosition: "center", backgroundSize: "cover" }}></div>
                              <div
                                className="d-flex mr-auto mb-1 ml-2 mt-4 rounded-pill bg-light"
                                style={{ width: "100px", height: "100px", position: "absolute", top: "135px", left: "34%", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "55%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                  style={{ borderColor: "transparent !important" }}
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 6 =================== */}

                  {/*===========================start Template number 7 =================== */}
                  {values.number === 7 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp7})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "40px", left: "40px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "40px", left: "127px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "40px", right: "40px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 7 =================== */}

                  {/*===========================start Template number 8 =================== */}
                  {values.number === 8 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative bg-white"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp7})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "40px", left: "40px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "40px", left: "127px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "40px", right: "40px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                    type="text"
                                    name="text"
                                    readOnly
                                    className="w-100 h-100 all_temp_input mb-3 mt-4"
                                  />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 8 =================== */}

                  {/*===========================start Template number 9 =================== */}
                  {values.number === 9 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp12})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto">
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "55px", height: "55px", top: "-15px", zIndex: "11", left: "42%" }}
                              ></div>
                            </div>
                            {/* <div className="col-8 mx-auto p-0 mb-4 ">
                              <div
                                className="w-100 rounded mb-5 position-relative"
                                style={{ height: "330px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"

                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col " >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 9 =================== */}

                  {/*===========================start Template number 10 =================== */}
                  {values.number === 10 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative">
                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp3})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div>
                            <div className="col-12 mx-auto p-0">
                              <div className="" style={{ backgroundImage: `url("${values?.presignedUrl1}")`, height: "150px", width: "100%", backgroundPosition: "center", backgroundSize: "cover" }}></div>
                              <div
                                className="d-flex mr-auto mb-1 ml-2 mt-4 rounded-pill bg-light"
                                style={{ width: "80px", height: "80px", position: "absolute", top: "0px", left: "15px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-8 mx-auto p-0 mb-4">
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "130px" }}
                              >
                                <input
                                type="text"
                                name="text"
                                readOnly
                                className="w-100 h-100 all_temp_input-dark mb-3"
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 10 =================== */}

                  {/*===========================start Template number 11 =================== */}
                  {values.number === 11 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative bg-white"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >
                            {/* <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div> */}
                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp11})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>
                            </div>
                            <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div>
                            <div className="col-12 mx-auto">
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "-10px", zIndex: "11", right: "35px" }}
                              ></div>
                            </div>
                            {/* <div className="col-8 mx-auto p-0 mb-4 ">
                              <div
                                className="w-100 rounded mb-5 position-relative"
                                style={{ height: "330px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"

                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col " >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 11 =================== */}

                  {/*===========================start Template number 12 =================== */}
                  {values.number === 12 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp12})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto">
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "70px", height: "70px", top: "-12px", zIndex: "1", left: "40%" }}
                              ></div>
                            </div>
                            {/* <div className="col-8 mx-auto p-0 mb-4 ">
                              <div
                                className="w-100 rounded mb-5 position-relative"
                                style={{ height: "330px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"

                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col " >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 12 =================== */}

                  {/*===========================start Template number 13 =================== */}
                  {values.number === 13 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp13})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>
                            <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-0 rounded-pill bg-light position-absolute"
                                style={{ width: "65px", height: "65px", top: "0px", zIndex: "11", left: "40%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  // readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                  style={{ borderColor: "transparent !important" }}
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 13 =================== */}

                  {/*===========================start Template number 14 =================== */}
                  {values.number === 14 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp13})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>
                            <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-0 rounded-pill bg-light position-absolute"
                                style={{ width: "65px", height: "65px", top: "0px", zIndex: "11", left: "40%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 14 =================== */}

                  {/* ========================== Template No 15 Start  ==========================*/}
                  {values.number === 15 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative" >
                            <div className="bg-temp-design"
                              style={{ backgroundImage: `url(${temp15})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "fit-content" }}>
                              <div className=""
                                style={{ backgroundImage: `url("${values?.presignedUrl1}")`, height: "165px", width: "100%", backgroundPosition: "center", backgroundSize: "cover", position: "absolute", top: "158px" }}>
                              </div>
                              <div
                                className="d-flex mr-auto mb-1 mt-4 rounded-pill bg-light"
                                style={{ width: "80px", height: "80px", position: "absolute", top: "-15px", left: "39%", zIndex: "111" }}
                              ></div>
                            </div>
                            <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div>
                            <div className="bg-temp-design" >
                              <img src={temp15} alt="" />
                            </div>
                            {/* <div className="col-8 mx-auto mt-auto p-0 mb-4">
                              <div
                                className="w-100 rounded mb-3 mt-3 position-relative"
                                style={{ height: "80px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* ========================== Template No 15 End  ==========================*/}

                  {/* ========================== Template No 16 Start  ==========================*/}
                  {values.number === 16 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative" >
                            <div className="bg-temp-design"
                              style={{ backgroundImage: `url(${temp15})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "fit-content" }}>
                              <div className=""
                                style={{ backgroundImage: `url("${values?.presignedUrl1}")`, height: "165px", width: "100%", backgroundPosition: "center", backgroundSize: "cover", position: "absolute", top: "158px" }}>
                              </div>
                              <div
                                className="d-flex mr-auto mb-1 mt-4 rounded-pill bg-light"
                                style={{ width: "80px", height: "80px", position: "absolute", top: "-15px", left: "39%", zIndex: "111" }}
                              ></div>
                            </div>
                            <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div>
                            <div className="bg-temp-design" >
                              <img src={temp15} alt="" />
                            </div>
                            {/* <div className="col-8 mx-auto mt-auto p-0 mb-4">
                              <div
                                className="w-100 rounded mb-3 mt-3 position-relative"
                                style={{ height: "80px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* ========================== Template No 16 End  ==========================*/}

                  {/*=========================== start Template number 17 =================== */}
                  {values.number === 17 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp17})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "128px", height: "128px", top: "78px", left: "48px", zIndex: "1" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "128px", height: "128px", top: "78px", right: "48px", zIndex: "1" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*=========================== End Template number 17 =================== */}

                  {/*=========================== start Template number 18 =================== */}
                  {values.number === 18 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp18})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*=========================== End Template number 18 =================== */}

                  {/*=========================== start Template number 19 =================== */}
                  {values.number === 19 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp19})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*=========================== End Template number 19 =================== */}

                  {/*=========================== start Template number 20 =================== */}
                  {values.number === 20 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp20})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*=========================== End Template number 20 =================== */}

                  {/*=========================== start Template number 21 =================== */}
                  {values.number === 21 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp21})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*=========================== End Template number 21 =================== */}

                  {/*=========================== start Template number 22 =================== */}
                  {values.number === 22 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp22})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 22 =================== */}

                  {/*===========================start Template number 23 =================== */}
                  {values.number === 23 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp23})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 23 =================== */}

                  {/*===========================start Template number 24 =================== */}
                  {values.number === 24 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp24})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 24 =================== */}

                  {/*===========================start Template number 25 =================== */}
                  {values.number === 25 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp25})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 25 =================== */}

                  {/*===========================start Template number 26 =================== */}
                  {values.number === 26 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp26})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 26 =================== */}

                  {/*===========================start Template number 27 =================== */}
                  {values.number === 27 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp27})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 27 =================== */}

                  {/*===========================start Template number 28 =================== */}
                  {values.number === 28 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp28})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 28 =================== */}

                  {/*===========================start Template number 29 =================== */}
                  {values.number === 29 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp29})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 29 =================== */}

                  {/*===========================start Template number 30 =================== */}
                  {values.number === 30 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp30})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 30 =================== */}

                  {/*===========================start Template number 31 =================== */}
                  {values.number === 31 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp31})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", }}>

                            </div>

                            <div className="col-12 mx-auto p-0" style={{ height: "120px" }}>
                              <div
                                className="d-flex ml-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "118px", height: "118px", top: "62px", zIndex: "1", left: "32%" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "80%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >
                                <textarea id="freeform" name="freeform" rows="4" cols="50" style={{ height: "70%" }} className="w-100 all_temp_input input-border-0 mb-3 " onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                type="text"
                                name="text"
                                // readOnly
                                className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                style={{ borderColor: "transparent !important" }}
                              />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 31 =================== */}

                  {/*===========================start Template number 32 =================== */}
                  {values.number === 32 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp17})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "128px", height: "128px", top: "78px", left: "48px", zIndex: "1" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "128px", height: "128px", top: "78px", right: "48px", zIndex: "1" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 32 =================== */}

                  {/* ========================== Template No 33 Start  ====================*/}
                  {values.number === 33 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}>
                            {/* <div className="bg-temp-design"
                              style={{ backgroundImage: `url(${temp333})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div> */}
                            <div className="col-12 mx-auto p-0" style={{ height: "fit-content" }}>
                              <div
                                className="d-flex mr-auto mb-1 mt-4 rounded-pill bg-light"
                                style={{ width: "80px", height: "80px", position: "absolute", top: "-15px", left: "39%", zIndex: "111" }}
                              ></div>
                            </div>
                            <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div>
                            {/* <div className="col-8 mx-auto mt-auto p-0 mb-4">
                              <div
                                className="w-100 rounded mb-3 mt-3 position-relative"
                                style={{ height: "80px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* ============================ Template No 33 End  ========================*/}

                  {/* ============================ Template No 34 Start  ========================*/}
                  {values.number === 34 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}>
                            {/* <div className="bg-temp-design"
                              style={{ backgroundImage: `url(${temp333})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div> */}
                            <div className="col-12 mx-auto p-0" style={{ height: "fit-content" }}>
                              <div
                                className="d-flex mr-auto mb-1 mt-4 rounded-pill bg-light"
                                style={{ width: "80px", height: "80px", position: "absolute", top: "-15px", left: "39%", zIndex: "111" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div> */}
                            {/* <div className="col-8 mx-auto mt-auto p-0 mb-4">
                              <div
                                className="w-100 rounded mb-3 mt-3 position-relative"
                                style={{ height: "80px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* ============================= Template No 34 End  ==========================*/}

                  {/* ============================= Template No 35 Start  ============================*/}
                  {values.number === 35 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}>
                            {/* <div className="bg-temp-design"
                              style={{ backgroundImage: `url(${temp333})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div> */}
                            <div className="col-12 mx-auto p-0" style={{ height: "fit-content" }}>
                              <div
                                className="d-flex mr-auto mb-1 mt-4 rounded-pill bg-light"
                                style={{ width: "100px", height: "100px", position: "absolute", top: "10px", left: "35%", zIndex: "111" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div> */}
                            {/* <div className="col-8 mx-auto mt-auto p-0 mb-4">
                              <div
                                className="w-100 rounded mb-3 mt-3 position-relative"
                                style={{ height: "80px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* =============================== Template No 35 End  ========================*/}

                  {/* ============================== Template No 36 Start  ===========================*/}
                  {values.number === 36 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}>
                            {/* <div className="bg-temp-design"
                              style={{ backgroundImage: `url(${temp333})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div> */}
                            <div className="col-12 mx-auto p-0" style={{ height: "fit-content" }}>
                              <div
                                className="d-flex mr-auto mb-1 mt-4 rounded-pill bg-light"
                                style={{ width: "100px", height: "100px", position: "absolute", top: "10px", left: "35%", zIndex: "111" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div> */}
                            {/* <div className="col-8 mx-auto mt-auto p-0 mb-4">
                              <div
                                className="w-100 rounded mb-3 mt-3 position-relative"
                                style={{ height: "80px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* ============================== Template No 36 End  ============================*/}

                  {/* ============================== Template No 37 Start ============================*/}
                  {values.number === 37 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}>
                            <div className="bg-temp-design"
                              style={{ backgroundImage: `url(${temp37})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "fit-content" }}>
                              <div
                                className="d-flex mr-auto mb-1 mt-4 rounded-pill bg-light"
                                style={{ width: "95px", height: "95px", position: "absolute", top: "65px", right: "56px", zIndex: "111" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div> */}
                            {/* <div className="col-8 mx-auto mt-auto p-0 mb-4">
                              <div
                                className="w-100 rounded mb-3 mt-3 position-relative"
                                style={{ height: "80px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* ============================ Template No 37 End  =======================*/}

                  {/*=========================== start Template number 38 =================== */}
                  {values.number === 38 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp7})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "75px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", right: "75px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 38 =================== */}

                  {/*===========================start Template number 39 =================== */}
                  {values.number === 39 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp7})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "75px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", right: "75px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 39 =================== */}

                  {/*===========================start Template number 40 =================== */}
                  {values.number === 40 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp7})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "75px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", right: "75px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 40 =================== */}

                  {/*===========================start Template number 41 =================== */}
                  {values.number === 41 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp7})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "30px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "127px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", right: "30px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 41 =================== */}

                  {/*===========================start Template number 42 =================== */}
                  {values.number === 42 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp7})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "30px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "127px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", right: "30px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 42 =================== */}

                  {/*===========================start Template number 43 =================== */}
                  {values.number === 43 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp7})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "30px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "127px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", right: "30px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 43 =================== */}

                  {/*===========================start Template number 44 =================== */}
                  {values.number === 44 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp7})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "30px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "127px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", right: "30px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 44 =================== */}

                  {/*===========================start Template number 45 =================== */}
                  {values.number === 45 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            {/* <div className="bg-temp-design" style={{ backgroundImage: `url(${temp7})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div> */}
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "135px", height: "135px", top: "10px", left: "99px", zIndex: "111" }}
                              ></div>
                            </div>
                            <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 45 =================== */}

                  {/*===========================start Template number 46 =================== */}
                  {values.number === 46 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp46})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "60px", height: "60px", top: "16", left: "136px", zIndex: "111" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "116px", height: "116px", top: "94px", left: "109px", zIndex: "111" }}
                              ></div>
                            </div>
                            <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 46 =================== */}

                  {/* ============================ Template No 47 Start  ========================*/}
                  {values.number === 47 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}>
                            {/* <div className="bg-temp-design"
                              style={{ backgroundImage: `url(${temp333})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div> */}
                            <div className="col-12 mx-auto p-0" style={{ height: "fit-content" }}>
                              <div
                                className="d-flex mr-auto mb-1 mt-4 rounded-pill bg-light"
                                style={{ width: "131px", height: "131px", position: "absolute", top: "52px", left: "31%", zIndex: "111" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div> */}
                            {/* <div className="col-8 mx-auto mt-auto p-0 mb-4">
                              <div
                                className="w-100 rounded mb-3 mt-3 position-relative"
                                style={{ height: "80px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* ============================= Template No 47 End  ==========================*/}

                  {/*===========================start Template number 48 =================== */}
                  {values.number === 48 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card" >
                          <div
                            className="row edit-temp-card position-relative"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            {/* <div className="bg-temp-design" style={{ backgroundImage: `url(${temp7})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div> */}
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "135px", height: "135px", top: "10px", left: "99px", zIndex: "111" }}
                              ></div>
                            </div>
                            <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 48 =================== */}

                  {/* ============================ Template No 49 Start  ========================*/}
                  {values.number === 49 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card p-0 rounded-0 shadow-none" >
                          <div className="row edit-temp-card position-relative rounded-0"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}>
                            <div className="bg-temp-design"
                              style={{ backgroundImage: `url(${temp49})`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "fit-content" }}>
                              <div
                                className="d-flex mr-auto mb-1 mt-4 rounded-pill bg-light"
                                style={{ width: "80px", height: "80px", position: "absolute", top: "10px", left: "39%", zIndex: "111" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div> */}
                            {/* <div className="col-8 mx-auto mt-auto p-0 mb-4">
                              <div
                                className="w-100 rounded mb-3 mt-3 position-relative"
                                style={{ height: "80px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* ============================= Template No 49 End  ==========================*/}

                  {/* ============================ Template No 50 Start  ========================*/}
                  {values.number === 50 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card p-0 rounded-0 shadow-none" >
                          <div className="row edit-temp-card position-relative rounded-0"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}>
                            <div className="bg-temp-design"
                              style={{ backgroundImage: `url(${temp49})`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "fit-content" }}>
                              <div
                                className="d-flex mr-auto mb-1 mt-4 rounded-pill bg-light"
                                style={{ width: "80px", height: "80px", position: "absolute", top: "10px", left: "39%", zIndex: "111" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 p-0 top-input-col" >
                              <div className="btm-input" ></div>
                            </div> */}
                            {/* <div className="col-8 mx-auto mt-auto p-0 mb-4">
                              <div
                                className="w-100 rounded mb-3 mt-3 position-relative"
                                style={{ height: "80px" }}
                              >
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 input-border-0"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* ============================= Template No 50 End  ==========================*/}

                  {/*=========================== start Template number 51 =================== */}
                  {values.number === 51 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card p-0 rounded-0 shadow-none" >
                          <div
                            className="row edit-temp-card position-relative rounded-0"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp49})`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "100px", height: "100px", top: "27px", left: "75px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "100px", height: "100px", top: "27px", right: "75px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 51 =================== */}

                  {/*=========================== start Template number 52 =================== */}
                  {values.number === 52 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card p-0 rounded-0 shadow-none" >
                          <div
                            className="row edit-temp-card position-relative rounded-0"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp49})`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "90px", height: "90px", top: "27px", left: "70px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "90px", height: "90px", top: "27px", right: "70px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 52 =================== */}

                  {/*=========================== start Template number 53 =================== */}
                  {values.number === 53 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card p-0 rounded-0 shadow-none" >
                          <div
                            className="row edit-temp-card position-relative rounded-0"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp49})`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "90px", height: "90px", top: "27px", left: "70px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "90px", height: "90px", top: "27px", right: "70px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 53 =================== */}

                  {/*=========================== start Template number 54 =================== */}
                  {values.number === 54 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card p-0 rounded-0 shadow-none" >
                          <div
                            className="row edit-temp-card position-relative rounded-0"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp49})`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "90px", height: "90px", top: "27px", left: "70px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "90px", height: "90px", top: "27px", right: "70px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 54 =================== */}

                  {/*=========================== start Template number 55 =================== */}
                  {values.number === 55 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card p-0 rounded-0 shadow-none" >
                          <div
                            className="row edit-temp-card position-relative rounded-0"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp49})`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "100px", height: "100px", top: "27px", left: "75px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "100px", height: "100px", top: "27px", right: "75px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 55 =================== */}

                  {/*=========================== start Template number 56 =================== */}
                  {values.number === 56 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card p-0 rounded-0 shadow-none" >
                          <div
                            className="row edit-temp-card position-relative rounded-0"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp49})`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "100px", height: "100px", top: "27px", left: "75px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "100px", height: "100px", top: "27px", right: "75px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 56 =================== */}

                  {/*===========================start Template number 57 =================== */}
                  {values.number === 57 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card p-0 rounded-0 shadow-none" >
                          <div
                            className="row edit-temp-card position-relative rounded-0"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp49})`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "30px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "144px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", right: "30px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 57 =================== */}

                  {/*===========================start Template number 58 =================== */}
                  {values.number === 58 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card p-0 rounded-0 shadow-none" >
                          <div
                            className="row edit-temp-card position-relative rounded-0"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp49})`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "30px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", left: "144px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "80px", height: "80px", top: "27px", right: "30px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 58 =================== */}

                  {/*=========================== start Template number 59 =================== */}
                  {values.number === 59 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card p-0 rounded-0 shadow-none" >
                          <div
                            className="row edit-temp-card position-relative rounded-0"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp49})`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "100px", height: "100px", top: "45px", left: "75px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "60px", height: "60px", top: "0px", left: "153px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "100px", height: "100px", top: "45px", right: "75px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 59 =================== */}

                  {/*=========================== start Template number 60 =================== */}
                  {values.number === 60 ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-2 col-12 mx-auto alltemp-col">
                        <div className="temp-card p-0 rounded-0 shadow-none" >
                          <div
                            className="row edit-temp-card position-relative rounded-0"
                            style={{ backgroundImage: `url(${values?.presignedUrl1})` }}
                          >

                            <div className="bg-temp-design" style={{ backgroundImage: `url(${temp49})`, backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                            </div>
                            <div className="col-12 mx-auto p-0" style={{ height: "160px" }}>

                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "60px", height: "60px", top: "90px", left: "80px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "100px", height: "100px", top: "0px", left: "135px", zIndex: "11" }}
                              ></div>
                              <div
                                className="d-flex mr-auto mb-3 mt-4 rounded-pill bg-light position-absolute"
                                style={{ width: "60px", height: "60px", top: "90px", right: "80px", zIndex: "11" }}
                              ></div>
                            </div>
                            {/* <div className="col-12 mx-auto mb-4" style={{ height: "68%" }}>
                              <div
                                className="w-100 rounded mb-3"
                                style={{ height: "100%", position: "relative" }}
                              >

                                <textarea id="freeform" name="freeform" rows="4" cols="50" className="w-100 all_temp_input mb-3 " style={{ height: "95%" }} onChange={onHandleChangeDummy1} placeholder="Enter text here...">
                                  {dummy1}
                                </textarea>
                                <input
                                  type="text"
                                  name="text"
                                  readOnly
                                  className="w-100 h-100 all_temp_input mb-3 mt-4"
                                />
                              </div>
                            </div> */}
                            {/* <div className="col-12 p-0 btm-input-col" >
                              <div className="btm-input  text-white text-center d-flex align-items-center justify-content-center " >
                                <p className="mb-0" style={{ fontSize: "18px" }}>{dummy2}</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*===========================End Template number 60 =================== */}

                </div>

              </form>
            </div>
          </div>
        </div>
        {/* {/ SECOND FORM /} */}
      </div>
    </div>
  );
};

export default ViewSingleTemplate;
