import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
// import WhatsappSend from "../../utils/WhatsappSend";

const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const initialstate = {
  title: "",
  pledge: "",
  noofpledge: "",
  image: "",
  presignedUrl1: "",
};

const ViewSinglePledge = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState(initialstate);
  const [showloader, setShowLoader] = useState("none");

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  useEffect(() => {
    const getData = async () => {
      const getdata = await axios.get(`${URL}/pledge/getSinglepledge/${id}`, {
        headers: { "X-API-Key": APP_X_API_KEY },
      });
      const Singledata = await getdata;
      setValues(Singledata?.data?.data);
    };

    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const UpdateData = async () => {
    setShowLoader("block");
    const url = `${URL}/pledge/updatepledge/${id}`;

    axios
      .put(
        url,
        {
          title: values?.title,
          pledge: values?.pledge,
          image: values?.image,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Data Add Successfully");
        navigate("/viewpledge");
        setShowLoader("none");
      })
      .catch((error) => {
        alert("service error");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { title, pledge, image } = values;

    if (!title || !pledge || !image) {
      toast.error("Please fill out all fields");
      return;
    }
    UpdateData(values);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };
  // const handleImageChange = async (e) => {
  //   setShowLoader("block");
  //   const formData = new FormData();
  //   formData.append("file", e.target.files[0]);
  //   var requestOptions = {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   };
  //   const fetchdata = axios.post(
  //     `${URL}/admin/imageUpload_Use/imageUpload`,
  //     formData,
  //     requestOptions
  //   );
  //   const response = await fetchdata;
  //   if (response.status === 200) {
  //     //toast.success("Data Uploaded Successfully...");
  //     setShowLoader("none");
  //     //seturl(response?.data?.url);
  //     setValues({ ...values, imageurl: response?.data?.url });
  //   } else {
  //     setShowLoader("none");
  //     toast.error("Fail To Load...");
  //   }
  //   setShowLoader("none");
  // };

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link to="/viewpledge" className="btn btn-primary mb-2">
        {" "}
        Back{" "}
      </Link>

      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">VIEW PLEDGE</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Pledge title </label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        id="exampleInputUsername1"
                        name="title"
                        value={values?.title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">
                        Number of Pledges{" "}
                      </label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        id="exampleInputUsername1"
                        name="noofpledge"
                        value={values?.noofpledge}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Pledge</label>
                      <textarea
                        type="text"
                        readOnly
                        className="form-control"
                        id="exampleInputEmail1"
                        rows="5"
                        name="pledge"
                        value={values?.pledge}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <img
                      src={values?.presignedUrl1}
                      alt={values?.presignedUrl1}
                    ></img>
                  </div>
                </div>

                {/* <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button> */}
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default ViewSinglePledge;
