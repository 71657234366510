import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
// import WhatsappSend from "../../utils/WhatsappSend";

const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const adminid = localStorage.getItem("adminid");
const initialstate = {
  title: "",
  address: "",
  date: "",
  from_time: "",
  to_time: "",
  dresscode: "",
  instruction: "",
  eventcreatedByAdmin: true,
  admin_id: adminid,
  user_id: "",
  rsvp: "",
};

const EditSingleEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState(initialstate);

  const [showloader, setShowLoader] = useState("none");

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  useEffect(() => {
    const getData = async () => {
      const getdata = await axios.get(`${URL}/event/getSingleEvent/${id}`, {
        headers: { "X-API-Key": APP_X_API_KEY },
      });
      const Singledata = await getdata;
      setValues(Singledata?.data?.data);
      // Extract YYYY-MM-DD
      // setValues({ date: formattedDate });
    };

    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const CreateData = async () => {
    setShowLoader("block");
    const url = `${URL}/event/updateEvent/${id}`;

    axios
      .put(
        url,
        {
          title: values?.title,
          address: values?.address,
          date: values?.date,
          from_time: values?.from_time,
          to_time: values?.to_time,
          dresscode: values?.dresscode,
          instruction: values?.instruction,
          eventcreatedByAdmin: values?.eventcreatedByAdmin,
          admin_id: values?.admin_id,
          user_id: values?.user_id,
          rsvp: values?.rsvp,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Event Update Successfully");
        navigate("/viewevent");
        setShowLoader("none");
      })
      .catch((error) => {
        setShowLoader("none");
        alert("service error");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      title,
      address,
      date,
      from_time,
      to_time,
      dresscode,
      instruction,
      rsvp,
    } = values;

    if (
      !title ||
      !address ||
      !date ||
      !from_time ||
      !to_time ||
      !dresscode ||
      !instruction ||
      !rsvp
    ) {
      toast.error("Please fill out all fields");
      return;
    }
    CreateData(values);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };
  // const handleImageChange = async (e) => {
  //   setShowLoader("block");
  //   const formData = new FormData();
  //   formData.append("file", e.target.files[0]);
  //   var requestOptions = {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   };
  //   const fetchdata = axios.post(
  //     `${URL}/admin/imageUpload_Use/imageUpload`,
  //     formData,
  //     requestOptions
  //   );
  //   const response = await fetchdata;
  //   if (response.status === 200) {
  //     //toast.success("Data Uploaded Successfully...");
  //     setShowLoader("none");
  //     //seturl(response?.data?.url);
  //     setValues({ ...values, imageurl: response?.data?.url });
  //   } else {
  //     setShowLoader("none");
  //     toast.error("Fail To Load...");
  //   }
  //   setShowLoader("none");
  // };
  // const isoDate = new Date(value?.date);
  //   const formattedDate = isoDate.toISOString().split('T')[0];
  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link to="/viewevent" className="btn btn-primary mb-2">
        {" "}
        Back{" "}
      </Link>
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">View Event</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="Enter Title"
                        name="title"
                        readOnly
                        value={values?.title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Address</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter address"
                        name="address"
                        value={values?.address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Date</label>
                      <input
                        type="Date"
                        readOnly
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter date"
                        name="date"
                        value={values?.date.split("T")[0]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">From Time</label>
                      <input
                        type="time"
                        readOnly
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter time"
                        name="from_time"
                        value={values?.from_time}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Time</label>
                      <input
                        type="time"
                        readOnly
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter To time"
                        name="to_time"
                        value={values?.to_time}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Dress Code</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter instruction"
                        name="instruction"
                        value={values?.dresscode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Instruction</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter instruction"
                        name="instruction"
                        value={values?.instruction}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Rsvp</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter rsvp"
                        name="rsvp"
                        value={values?.rsvp}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                {/* <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button> */}
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default EditSingleEvent;
