import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link ,useParams} from "react-router-dom";
import dateFormat from "dateformat";

import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Pagination from "../../components/Pagination";

import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const ViewSubCategory = () => {
  const {categoryid } =  useParams();
  const [subCategory, setSubCategorys] = useState([]);
  // const [startDate, setstartDate] = useState("");
  // const [endDate, setendDate] = useState("");
  const [showloader, setShowLoader] = useState("none");
    // Function to filter customers based on input field value ==========================================
    const itemsPerPage = 5; // Set the number of items per page
    // const [searchfilterValue, setSearchFilterValue] = useState("");
    // const [filtertype, setFilterType] = useState("DEFAULT");
    const [currentPage, setCurrentPage] = useState(1);
    // const [totalPages, setTotalPages] = useState(1);
    // const [initialFetchDone, setInitialFetchDone] = useState(false); // Track initial fetch

  //   const handlePageChange = (page) => {
  //     setCurrentPage(page);
  //   };
  //  // Function to handle input change
  //  const handleInputChangeSearch = (e) => {
  //   setSearchFilterValue(e.target.value);
  // };

  // const SubmitSearch = async (e) => {
  //   e.preventDefault();
  //   if (!searchfilterValue) {
  //     toast.error("Please Enter Search Data");
  //     return;
  //   }
  //   setFilterType("SEARCH");
  //   const getdata = await axios.get(
  //     `${URL}/admin/MultiplefilterCategory?FILTERTYPE=${filtertype}&SEARCH=${searchfilterValue}&page=${currentPage}&limit=${itemsPerPage}` ,{ headers: { 'X-API-Key': APP_X_API_KEY } }
  //   );
  //   const Singledata = await getdata;
  //   const Data1 = await Singledata;
  //   setSubCategorys(Data1?.data?.data);
  //   setTotalPages(Data1?.data.totalPages);
  // };

  // const SubmitSearch2_by_button = async (e) => {
  //   setShowLoader("block");
  //   e.preventDefault();

  //   if (!searchfilterValue) {
  //     setShowLoader("none");
  //     toast.error("Please Enter Search Data");
  //     return;
  //   }
  //   setCurrentPage(1);
  //   setFilterType("SEARCH");
  //   const getdata = await axios.get(
  //     `${URL}/admin/MultiplefilterCategory?FILTERTYPE=SEARCH&SEARCH=${searchfilterValue}&page=${1}&limit=${itemsPerPage}` ,{ headers: { 'X-API-Key': APP_X_API_KEY } }
  //   );
  //   const Singledata = await getdata;
  //   const Data1 = await Singledata;
  //   setCategorys(Data1?.data?.data);
  //   setTotalPages(Data1?.data.totalPages);
  //   setShowLoader("none");
  // };

  // ==========================================================
  //==================== Submit Search End ======================================
  // ==========================================================

  //======================= Date filter start================================================
  // ==========================================================
  // const handleChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;

  //   if (name === "startDate") {
  //     if (new Date(value) > new Date(endDate)) {
  //       toast.error("Start date cannot be later than end date.");
  //     } else {
  //       setstartDate(value);
  //     }
  //   }

  //   if (name === "endDate") {
  //     if (!startDate) {
  //       toast.error("Please Select a Start Date First");
  //       return;
  //     }
  //     if (new Date(value) < new Date(startDate)) {
  //       toast.error("End date cannot be earlier than start date.");
  //     } else {
  //       setendDate(value);
  //     }
  //   }
  // };

  // const onSubmitBothDate = async (e) => {
  //   // e.preventDefault();
  //   if (!startDate || !endDate) {
  //     toast.error("Please fill Start Date and End Date");
  //     return;
  //   }
  //   setFilterType("DATE");
  //   const getdata = await axios.get(
  //     `${URL}/admin/MultiplefilterCategory?FILTERTYPE=DATE&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${itemsPerPage}` ,{ headers: { 'X-API-Key': APP_X_API_KEY } }
  //   );
  //   const Singledata = await getdata;
  //   const Data1 = await Singledata;
  //   setCategorys(Data1?.data?.data);
  //   setTotalPages(Data1?.data.totalPages);
  //   setShowLoader("none");
  // };

  // const onSubmitByButton = async (e) => {
  //   setShowLoader("block");
  //   e.preventDefault();
  //   setCurrentPage(1);
  //   if (!startDate || !endDate) {
  //     toast.error("Please fill Start Date and End Date");
  //     return;
  //   }
  //   setFilterType("DATE");
  //   const getdata = await axios.get(
  //     `${URL}/admin/MultiplefilterCategory?FILTERTYPE=DATE&startDate=${startDate}&endDate=${endDate}&page=${1}&limit=${itemsPerPage}` ,{ headers: { 'X-API-Key': APP_X_API_KEY } }
  //   );
  //   const Singledata = await getdata;
  //   const Data1 = await Singledata;
  //   setCategorys(Data1?.data?.data);
  //   setTotalPages(Data1?.data.totalPages);
  //   setShowLoader("none");
  // };

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const getAllData = async () => {
  try {
    setShowLoader("block");
    // setFilterType("DEFAULT");
    // const getData = await axios.get(
    //   `${URL}/admin/MultiplefilterCategory?FILTERTYPE=${filtertype}&page=${currentPage}&limit=${itemsPerPage}` ,{ headers: { 'X-API-Key': APP_X_API_KEY } }
    // );
    const getData = await axios.get(
      `${URL}/admin/getAllSubCategoryByCategoryId/${categoryid}` ,{ headers: { 'X-API-Key': APP_X_API_KEY } }
    );
    const Data1 = await getData?.data?.data;
    setSubCategorys(Data1);
    // setTotalPages(Data1?.data.totalPages);
   setShowLoader("none");
  } catch (error) {
    // toast.error("Fail to Load Data");
  }
   
  };

 useEffect(() => {
  getAllData(); // eslint-disable-next-line
}, []);

//  useEffect(() => {
//   if (!initialFetchDone) {
//     getAllData(); // eslint-disable-next-line
//     setInitialFetchDone(true);
//   }else{
//   if (filtertype === "DEFAULT") {
//     // eslint-disable-next-line
//     getAllData(); // eslint-disable-next-line
//   }
//   if (filtertype === "SEARCH") {
//     // eslint-disable-next-line
//     SubmitSearch(); // eslint-disable-next-line
//   }
//   if (filtertype === "DATE") {
//     // eslint-disable-next-line
//     onSubmitBothDate(); // eslint-disable-next-line
//   }}
// }, [currentPage]);

  const confirmDelete = (id) => {
    confirmAlert({
      message: "Are you sure you want to delete this Data.",
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteData(id),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const DeleteData = async (Linkid) => {
    await axios
      .patch(`${URL}/admin/deleteCategoryById/${Linkid}` ,{ headers: { 'X-API-Key': APP_X_API_KEY } })
      .then((result) => {
        toast.success("Category Deleted sucessfully");
        getAllData();
      })
      .catch((error) => {
        alert("service error");
      });
  };
  const ClearData = () => {
    getAllData();
    // setSearchFilterValue("");
    // setstartDate("");
    // setendDate("");
    // setCurrentPage(1);
  };
  // Helper function to format time in AM/PM format
  const formatTime = (timeString) => {
    if (!timeString) {
      return ""; // Return empty string if timeString is undefined or null
    }

    // Parse the input time string in 24-hour format (e.g., "14:55")
    const [hours, minutes] = timeString.split(":");
    let formattedHours = parseInt(hours, 10);

    // Determine AM/PM and convert to 12-hour format
    const period = formattedHours >= 12 ? "PM" : "AM";
    formattedHours = formattedHours % 12 || 12; // Convert 0 to 12
    // Format the time as "hh:mm AM/PM"
    return `${formattedHours}:${minutes} ${period}`;
  };

  const renderContent = (text) => {
    const words = text.split(" ");

    if (words.length > 7) {
      // If text has more than 50 words, truncate it
      const truncatedText = words.slice(0, 7).join(" ") + "...";
      return truncatedText;
    }

    return text; // Return full text if within or equal to 50 words
  };
    // ====================================================
    const startIndex = (currentPage - 1) * itemsPerPage;
    // ====================================================
  
  return (
    <div className="content-wrapper user-page form-bar">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link to={"/viewcategory"} className="btn btn-primary mb-2"> Back </Link>
    
      <div className="row">
        {/* first */}

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              
              <h4 className="card-title">Sub Categories (4 Fixed Sub Categories)</h4>
              <Link to={`/addSubCategory/${categoryid}`} className="btn btn-primary link_button">
                Create Sub Categories
              </Link>
              </div>
              {/* <p className="card-description">
                Total Category : {Category?.length}
              </p> */}
              <div className="table-responsive" id="collapse1">
              {/* f-table */}
                <table className="table table-striped tableFixHead ">
                  <thead>
                    <tr>
                      <th>Sr.no</th>
                      <th>Created date</th>
                      <th>Sub Categories Name</th>
                      <th>Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subCategory?.map((Categorys, index) => {
                      return (
                        <tr key={index}>
                          <td> {startIndex + index + 1}</td>
                          <td>
                            {dateFormat(
                              `${Categorys?.createdAt}`,
                              "mmmm dS, yyyy"
                            )}
                          </td>

                          <td>{renderContent(Categorys?.title)}</td>
                          <td>
                            <img src={Categorys?.presignedUrl1} alt="img" />
                          </td>

                          <td>
                            {/* Categorys._id) */}
                            <Link to={`/viewSingleSubcategory/${categoryid}/${Categorys?._id}`}>
                              {" "}
                              <i class="fa-solid fa-eye menu-icon "></i>{" "}
                            </Link>
                           
                            <Link to={`/editSingleSubcategory/${categoryid}/${Categorys?._id}`}>
                              {" "}
                              <i class="fa-solid fa-edit menu-icon ml-2"></i>{" "}
                            </Link>{" "}
                            {/* <Link className="ml-2">
                              {" "}
                              <i
                                class="fa-solid fa-trash menu-icon "
                                onClick={() => confirmDelete(Categorys?._id)}
                              ></i>
                            </Link> */}
                          
                            {/* <p onClick={() => confirmDelete(Categorys._id)}>Delete</p> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {subCategory.length === 0 ? (
                  <p className="text-center mt-2 bold ">No Data Found</p>
                ) : null}
                {/* <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                /> */}
              </div>
            </div>
          </div>
        </div>

        {/* first */}
      </div>
    </div>
  );
};

export default ViewSubCategory;
