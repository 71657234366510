import React from "react";
import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
// import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
// import WhatsappSend from "../../utils/WhatsappSend";

const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const initialstate = {
  title: "",
  description: "",
  imageurl: "",
  presignedUrl1: "",
  templateid1: "",
  //   templateid2: "",
  //   templateid3: "",
  //   templateid4: "",
};

const ViewSingleSubCategory = () => {
  const { categoryid, subcategoryid } = useParams();
  // const navigate = useNavigate();
  const [values, setValues] = useState(initialstate);
  // const [template, settemplate] = useState([]);

  const [showloader, setShowLoader] = useState("none");

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const getSingleData = async () => {
    setShowLoader("block");
    const getData = await axios.get(
      `${URL}/admin/getSingleSubCategoryById/${subcategoryid}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Data1 = await getData;
    setValues(Data1?.data?.data);
    setShowLoader("none");
  };

  useEffect(() => {
    getSingleData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link
        to={`/viewSubCategory/${categoryid}`}
        className="btn btn-primary mb-2"
      >
        {" "}
        Back{" "}
      </Link>

      <div
        className="row"
        style={{
          maxHeight: "500px", // Set maximum height for the scrollable div
          overflowY: "auto", // Enable vertical scrolling

          padding: "10px", // Optional: Add padding inside the div
        }}
      >
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Sub Category</h4>
              {/* <Link to="/addevent" className="btn btn-primary">
                Add Event
              </Link> */}
              {/* <p className="card-description">Add className</p> */}
              <div>
                <div className="row " style={{ border: "3px solid black" }}>
                  <div className="col-md-3 mt-2 mb-2">
                    <img
                      src={values?.presignedUrl1}
                      alt=""
                      style={{ width: "200px", height: "200px" }}
                    />
                  </div>
                  <div className="col-md-9 title_colsinglecategory">
                    {" "}
                    <span className="titlesinglecategory">
                      {" "}
                      <b> Sub Category Title : -</b> {values?.title}
                    </span>
                    <br />
                    <span className="titlesinglecategory">
                      {" "}
                      <b>Sub Category Description : -</b> {values?.description}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="row ">
                  <div className="col-md-3 py-3 px-4">
                    {" "}
                    <Link
                      //  to={`/ViewSingleTemplateFromSubCategory/${values?.templateid1?._id}/${categoryid}/${subcategoryid}`}
                      style={{ width: "100%" }}
                    >
                      <div
                        class="card"
                        style={{
                          width: "100%",
                          height: "150px",
                          border: "2px solid black",
                        }}
                      >
                        <p className="d-flex justify-content-center align-items-center h-100">
                          {values?.templateid1?.number}
                        </p>
                      </div>
                    </Link>
                  </div>
                  {/* <div className="col-md-3 py-3 px-4">
                    {" "}
                    <Link to={`/ViewSingleTemplateFromCategory/${values?.templateid2?._id}/${id}`} style={{ width: "100%" }}>
                      <div
                        class="card"
                        style={{
                          width: "100%",
                          height: "150px",
                          border: "2px solid black",
                        }}
                      >
                        <p className="d-flex justify-content-center align-items-center h-100">
                          {values?.templateid2?.number}
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3 py-3 px-4">
                    {" "}
                    <Link to={`/ViewSingleTemplateFromCategory/${values?.templateid3?._id}/${id}`} style={{ width: "100%" }}>
                      <div
                        class="card"
                        style={{
                          width: "100%",
                          height: "150px",
                          border: "2px solid black",
                        }}
                      >
                        <p className="d-flex justify-content-center align-items-center h-100">
                          {values?.templateid3?.number}
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-3 py-3 px-4">
                    {" "}
                    <Link to={`/ViewSingleTemplateFromCategory/${values?.templateid4?._id}/${id}`} style={{ width: "100%" }}>
                      <div
                        class="card"
                        style={{
                          width: "100%",
                          height: "150px",
                          border: "2px solid black",
                        }}
                      >
                        <p className="d-flex justify-content-center align-items-center h-100">
                      {values?.templateid4?.number}
                        </p>
                      </div>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};

export default ViewSingleSubCategory;
