// src/components/Pagination.js
import React from 'react';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const getPagination = () => {
    if (totalPages <= 1) {
      return [1];
    }

    const delta = 2;
    const range = [];
    const rangeWithDots = [];
    let l;

    range.push(1);
    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i < totalPages && i > 1) {
        range.push(i);
      }
    }
    range.push(totalPages);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  return (
    <div className="pagination mt-3 justify-content-center">
      {currentPage > 1 && (
        <button className="btn btn-primary ml-1" onClick={() => onPageChange(currentPage - 1)}>
          Previous
        </button>
      )}
      {getPagination().map((page, index) =>
        page === '...' ? (
          <span key={index} className="btn btn-disabled ml-1">...</span>
        ) : (
          <button
            className={`btn ml-1 ${page === currentPage ? 'btn-secondary' : 'btn-primary'}`}
            key={index}
            onClick={() => onPageChange(page)}
            disabled={page === currentPage}
          >
            {page}
          </button>
        )
      )}
      {currentPage < totalPages && (
        <button className="btn btn-primary ml-1" onClick={() => onPageChange(currentPage + 1)}>
          Next
        </button>
      )}
    </div>
  );
};

export default Pagination;
