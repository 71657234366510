import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
// import WhatsappSend from "../../utils/WhatsappSend";

const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const initialstate = {
  title: "",
  description: "",
  presignedUrl1: "",
  imageurl: "Uploads/image/1716986424906-noimage.jpg",
};

const Addnotification = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialstate);
  // const [image, setImage] = useState("");
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [users, setUsers] = useState([]);
  const [singlecountry, setsingleCountry] = useState("");
  const [singlestate, setsingleState] = useState("");
  const [singleusers, setsingleUsers] = useState("");
  const [showloader, setShowLoader] = useState("none");
  const [notificationTo, setNotificationTo] = useState("ALL");

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  // const handleImageChange = (e) => {
  //   setImage(e.target.files[0]);
  // };
  const getAllCountry = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getAllCountry`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getData;
    setCountry(Data1.data.data);
    setShowLoader("none");
  };
  const getAllUser = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getAllUsers2`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getData;
    setUsers(Data1.data.data);
    setShowLoader("none");
  };

  const getAllState = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getAllState`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getData;
    setState(Data1.data.data);
    setShowLoader("none");
  };

  useEffect(() => {
    getAllCountry();
    getAllState();
    getAllUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const getAllPersontypeData = async () => {
  //   setShowLoader("block");
  //   const getData = await axios.get(`${URL}/admin/getAllPersonType`);
  //   const Data1 = await getData;
  //   setPersontypes(Data1.data.data);
  //   setShowLoader("none");
  // };
  // const getAllSkilllevelData = async () => {
  //   setShowLoader("block");
  //   const getData = await axios.get(`${URL}/admin/getAllSkillLevel`);
  //   const Data1 = await getData;
  //   setskilllevels(Data1.data.data);
  //   setShowLoader("none");
  // };
  // const getAllFranchiseUser = async () => {
  //   setShowLoader("block");
  //   const formdta = {
  //     stresslevel: "",
  //     persontype: "",
  //     skilllevel: "",
  //     workcycle: "",
  //   };
  //   const getData = await axios.post(
  //     `${URL}/admin/filterUsersByTagsCondition`,
  //     formdta
  //   );
  //   // const getData = await axios.get(`${URL}/admin/getAllUsers`);
  //   const Data1 = await getData;
  //   setfranchise(Data1.data.data);
  //   setShowLoader("none");
  //   // console.log(Data1.data.data);
  // };

  // const getAllData = async () => {
  //   const SingleData = await axios.get(
  //     `${URL}/admin/getSingleNotification/${id}`
  //   );
  //   const SingleData1 = await SingleData;
  //   setValues(SingleData1.data.data);
  //   console.log(SingleData1.data);
  // };

  const CreateData = async () => {
    setShowLoader("block");
    const url = `${URL}/admin/createnotification`;
    // const config = {
    //   headers: { "content-type": "multipart/form-data" },
    // };
    // const formData = new FormData();
    // if(image){
    //     formData.append("file", image);
    //   }
    // formData.append("title", values.title);
    // formData.append("description", values.description);
    //const dt1={title:values.title,description:values.description}

    axios
      .post(
        url,
        {
          title: values.title,
          description: values.description,
          imageurl: values.imageurl,
          countryid: singlecountry ? singlecountry : null,
          stateid: singlestate ? singlestate : null,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        // console.log(result.data);
        toast.success("Notification Add Successfully");
        navigate("/viewnotification");
        // getAllData();
        setShowLoader("none");
      })
      .catch((error) => {
        alert("service error");
        // console.log(error);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { title, description } = values;
    // if (notificationTo === "ALL") {
    //   if (image === "") {
    //     toast.error("Please fill out all fields");
    //     return;
    //   }
    // }
    // if (image === "") {
    //       toast.error("Please fill out all fields");
    //       return;
    //     }
    if (!title || !description) {
      toast.error("Please fill out all fields");
      return;
    }
    if (notificationTo === "COUNTRYWISE") {
      if (!singlecountry) {
        toast.error("Please Select Country");
        return;
      }
    }
    // if (notificationTo === '') {
    //   toast.error("Please Select notification sent To dropdown");
    //   return;
    // }
    if (notificationTo === "STATEWISE") {
      if (!singlestate) {
        toast.error("Please Select State");
        return;
      }
    }
    if (notificationTo === "SINGLEUSER") {
      if (!singleusers) {
        toast.error("Please Select User");
        return;
      }
    }
    if (notificationTo === "SINGLEUSER") {
      // alert('working on single section')
      sendto_single_franchise(values);
    }
    //send();
    else if (notificationTo === "ALL" || "COUNTRYWISE" || "STATEWISE") {
      CreateData(values);
      // alert('all line');
    }

    // notificationTo === "ALL"
    //   ? CreateData(values)
    //   : sendto_single_franchise(values);
  };

  const sendto_single_franchise = (values) => {
    setShowLoader("block");
    const url = `${URL}/admin/createnotification_Single`;
    // const config = {
    //   headers: { "content-type": "multipart/form-data", 'X-API-Key': APP_X_API_KEY },
    // };

    axios
      .post(
        url,
        {
          title: values.title,
          description: values.description,
          userid: singleusers,
          imageurl: values.imageurl,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        // console.log(result.data);
        toast.success("Notification Add Successfully");
        navigate("/viewnotification");
        // getAllData();
        setShowLoader("none");
      })
      .catch((error) => {
        alert("service error");
        setShowLoader("none");
        // console.log(error);
      });

    // const phone = notificationTo;
    // const msg2 = `Dear Customer ,${values.title} , ${values.description} `;
    // WhatsappSend(phone, msg2);
    // toast.success("Message Sent Successful 2...");
    setValues({ title: "", description: "" });
    setNotificationTo("ALL");
  };
  const handleNotificatSendTo = async (e) => {
    const value = e.target.value;
    // COUNTRYWISE ,STATEWISE ,SINGLEUSER
    setNotificationTo(value);
    if (value === "ALL") {
      setsingleCountry("");
      setsingleState("");
      setsingleUsers("");
    }
    if (value === "COUNTRYWISE") {
      // setsingleCountry('');
      setsingleState("");
      setsingleUsers("");
    }
    if (value === "STATEWISE") {
      setsingleCountry("");
      // setsingleState('');
      setsingleUsers("");
    }
    if (value === "SINGLEUSER") {
      setsingleCountry("");
      setsingleState("");
      // setsingleUsers('');
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleImageChange = async (e) => {
    setShowLoader("block");
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    var requestOptions = {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-API-Key": APP_X_API_KEY,
      },
    };
    try {
      const fetchdata = axios.post(
        `${URL}/admin/imageUpload_Use/imageUpload`,
        formData,
        requestOptions
      );
      const response = await fetchdata;
      if (response.status === 200) {
        //toast.success("Data Uploaded Successfully");
        setShowLoader("none");
        //seturl(response?.data?.url);
        setValues({
          ...values,
          imageurl: response?.data?.url,
          presignedUrl1: response?.data?.presignedUrl,
        });
      } else {
        setShowLoader("none");
        toast.error("Fail To Load");
      }
    } catch (error) {
      setShowLoader("none");
      console.error("Error uploading image:", error);
      toast.error(
        "An error occurred while uploading the image. Please try again."
      );
    }
  };
  // const HandleFilter = async (e) => {
  //   const value = e.target.value;
  //   const name = e.target.name;

  //   if (value == null) {
  //     setShowLoader("none");
  //     return;
  //   }

  //   if (name === "stresslevel") {
  //     const formdta1 = {
  //       stresslevel: value,
  //       persontype: "",
  //       skilllevel: "",
  //       workcycle: "",
  //     };
  //     setShowLoader("block");
  //     const getData = await axios.post(
  //       `${URL}/admin/filterUsersByTagsCondition`,
  //       formdta1
  //     );
  //     // const getData = await axios.post(
  //     //   `${URL}/admin/getAllFilteredUsers`,
  //     //   formdta
  //     // );
  //     const Data1 = await getData;
  //     setfranchise(Data1.data.data);
  //     // console.log(Data1.data.data);
  //     setShowLoader("none");
  //   }
  //   if (name === "persontype") {
  //     const formdta2 = {
  //       stresslevel: "",
  //       persontype: value,
  //       skilllevel: "",
  //       workcycle: "",
  //     };
  //     setShowLoader("block");
  //     const getData = await axios.post(
  //       `${URL}/admin/filterUsersByTagsCondition`,
  //       {
  //         params: formdta2,
  //       }
  //     );
  //     const Data1 = await getData;
  //     setfranchise(Data1.data.data);
  //     // console.log(Data1.data.data);
  //     setShowLoader("none");
  //   }
  //   if (name === "skilllevel") {
  //     const formdta3 = {
  //       skilllevel: value,
  //       stresslevel: "",
  //       persontype: "",
  //       workcycle: "",
  //     };
  //     setShowLoader("block");
  //     const getData = await axios.post(
  //       `${URL}/admin/filterUsersByTagsCondition`,
  //       formdta3
  //     );
  //     const Data1 = await getData;
  //     setfranchise(Data1.data.data);
  //     // console.log(Data1.data.data);
  //     setShowLoader("none");
  //   }
  //   if (name === "workcycle") {
  //     const formdta4 = {
  //       workcycle: value,
  //       stresslevel: "",
  //       persontype: "",
  //       skilllevel: "",
  //     };

  //     setShowLoader("block");
  //     const getData = await axios.post(
  //       `${URL}/admin/filterUsersByTagsCondition`,
  //       formdta4
  //     );
  //     const Data1 = await getData;
  //     setfranchise(Data1.data.data);
  //     // console.log(Data1.data.data);
  //     setShowLoader("none");
  //   }
  // };
  // const [selectedStress, setSelectedStress] = useState('');
  // const [selectedSkillLevel, setSelectedSkillLevel] = useState('');
  // const [selectedWorkCycle, setSelectedWorkCycle] = useState('');

  // const Clear = async (e) => {
  //   getAllFranchiseUser();
  //   document.getElementById('f1').value ='';
  //   // setSelectedStress('');
  //   // setSelectedSkillLevel('');
  //   // setSelectedWorkCycle('');
  //   // document.getElementsByName('workcycle').value =null;
  //   // document.getElementsByName('persontype').value =null;
  //   // document.getElementsByName('skilllevel').value = null;

  // }
  const handleDropdownDetail = (event) => {
    if (notificationTo === "COUNTRYWISE") {
      setsingleCountry(event.target.value);
      setsingleState("");
      setsingleUsers("");
    }
    if (notificationTo === "STATEWISE") {
      setsingleState(event.target.value);
      setsingleUsers("");
      setsingleCountry("");
    }
    if (notificationTo === "SINGLEUSER") {
      setsingleUsers(event.target.value);
      setsingleCountry("");
      setsingleState("");
    }
  };
  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Notification</h4>
              {values?.presignedUrl1 ? (
                <img
                  src={values?.presignedUrl1}
                  alt={values?.presignedUrl1}
                  className="mt-4"
                ></img>
              ) : null}
              {values?.presignedUrl2 ? (
                <img
                  src={values?.presignedUrl2}
                  alt={values?.presignedUrl2}
                  className="mt-4 ml-3"
                ></img>
              ) : null}

              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="Enter Title"
                        name="title"
                        maxlength="50"
                        value={values.title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Photo -</label>
                      <div
                        className="loader-container "
                        style={{ display: showloader }}
                      >
                        <img src={loaderimg} alt="" className="loaderImage" />
                      </div>
                      <input
                        type="file"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="Image Upload"
                        name="file"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Send To</label>
                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="notificationsnedto"
                        onChange={handleNotificatSendTo}
                      >
                        <option>Select</option>
                        <option value="ALL">All Users</option>
                        <option value="COUNTRYWISE">Country wise</option>
                        <option value="STATEWISE">State wise </option>
                        <option value="SINGLEUSER">Specific user</option>
                        {/* {franchise.map((franchises, index) => {
                          return (
                            <option value={franchises?._id}>
                              {franchises.name}
                            </option>
                          );
                        })}
                        ; */}
                      </select>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    style={{
                      display:
                        notificationTo === "COUNTRYWISE" ? "block" : "none",
                    }}
                  >
                    <div className="form-group">
                      <label for="exampleInputEmail1">Country</label>
                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="countryid"
                        onChange={handleDropdownDetail}
                      >
                        <option>Select</option>
                        {country.map((countrys, index) => {
                          return (
                            <option value={countrys?._id}>
                              {countrys.name}
                            </option>
                          );
                        })}
                        ;
                      </select>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    style={{
                      display:
                        notificationTo === "STATEWISE" ? "block" : "none",
                    }}
                  >
                    <div className="form-group">
                      <label for="exampleInputEmail1">State</label>
                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="statesid"
                        onChange={handleDropdownDetail}
                      >
                        <option>Select</option>
                        {state.map((states, index) => {
                          return (
                            <option value={states?._id}>{states.name}</option>
                          );
                        })}
                        ;
                      </select>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    style={{
                      display:
                        notificationTo === "SINGLEUSER" ? "block" : "none",
                    }}
                  >
                    <div className="form-group">
                      <label for="exampleInputEmail1">User</label>
                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="userid"
                        onChange={handleDropdownDetail}
                      >
                        <option>Select</option>
                        {users.map((userss, index) => {
                          return (
                            <option value={userss?._id}>{userss.name}</option>
                          );
                        })}
                        ;
                      </select>
                    </div>
                  </div>
                </div>

                {/* {notificationTo === "ALL" ? ( */}
                {/* <div className="form-group">
                  <label for="exampleInputEmail1">Image</label>

                  <input
                    type="file"
                    className="form-control"
                    id="exampleInputUsername1"
                    onChange={handleImageChange}
                    name="file"
                  />
                </div> */}
                {/* ) : null} */}

                <button
                  type="submit"
                  className="btn btn-primary mr-2"
                  style={{ display: showloader === "block" ? "none" : "block" }}
                >
                  Submit
                </button>
                <button
                  className="btn btn-primary mr-2"
                  style={{ display: showloader }}
                >
                  Please wait ...
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default Addnotification;
