import React, { useContext } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import { useNavigate, Link } from "react-router-dom";
import Logo from "../assets/image/logo.png";
import { AuthContext } from "../AuthContext";
import loaderimg from "../assets/image/loader.gif";
// import leftimg from "../assets/image/login-left-img.png";
const URL = process.env.REACT_APP_URL;
const WEBSITE = process.env.REACT_APP_SESSION_WEBSITE_NAME;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

//const URL = "http://localhost:5000/api/v1";
const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const {  login1, } = useContext(AuthContext);
  // const { isAuthenticated, login1, logout } = useContext(AuthContext);
  const [values, setValues] = useState(initialState);
  const [values2, setValues2] = useState({
    newpassword: "",
    reenternewpassword: "",
  });
  const [otpnumber, setOtpnumber] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });
  // const [two, setTwo] = useState('');
  // const [three, setThree] = useState('');
  // const [four, setFour] = useState('');

  const [adminid, setadminid] = useState("");
  // const [otp, setotp] = useState("");
  const [show, setShow] = useState("none");
  const [currentActive, setCurrentActive] = useState("Login");

  const [localStorageValue, SetlocalStorageValue] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [showloader, setShowLoader] = useState("none");

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const togglePasswordVisibility3 = () => {
    setShowPassword3(!showPassword3);
  };

  const ChangePage_1 = (e) => {
    setCurrentActive("Login");
  };
  const ChangePage_2 = (e) => {
    setCurrentActive("Forget_Password");
  };
  const ChangePage_3 = (e) => {
    setCurrentActive("Verify_Otp");
  };
  const ChangePage_4 = (e) => {
    setCurrentActive("Update_Password");
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };
  const handleChangeOtp = (e) => {
    const { name, value } = e.target;

    // Ensure the input value is a single digit number
    if (/^\d*$/.test(value) && value.length <= 1) {
      setOtpnumber({
        ...otpnumber,
        [name]: value,
      });

      // Automatically move focus to the next input field
      if (value && e.target.nextElementSibling) {
        e.target.nextElementSibling.focus();
      }
    }
  };
  const handleChange2 = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues2({ ...values2, [name]: value });
  };

  const onSubmit2 = (e) => {
    e.preventDefault();

    const { reenternewpassword, newpassword } = values2;
    // console.log("newpassword:", newpassword);
    // console.log("reenternewpassword:", reenternewpassword);

    if (!newpassword || !reenternewpassword) {
      toast.error("All Fields Required");
      return;
    }
    if (newpassword == reenternewpassword) {
      AddNewPassword();
    } else {
      toast.error("Password Not Match");
      return;
    }
  };
  const AddNewPassword = async () => {
    try {
      setShowLoader("block");
      const add = await axios.post(
        `${URL}/admin/updatepassword_adminfrontend`,
        { id: adminid, newpassword: values2.newpassword },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      );
      if (add.status === 200) {
        setShowLoader("none");
        navigate("/login");
        setCurrentActive("Login");
        toast.success("Password Update Successfully");
      }
    } catch (error) {
      setShowLoader("none");
      toast.error("Details Not Match");
    }
  };

  const AddData = async (userData) => {
    try {
      setShowLoader("block");
      const add = await axios.post(`${URL}/admin/login`, userData, {
        headers: { "X-API-Key": APP_X_API_KEY },
      });
      if (add.status === 200) {
        setShowLoader("none");
        login1();
        const okcheck = await add.data;
        // console.log(okcheck);
        localStorage.setItem("token", okcheck.token);
        localStorage.setItem("admin", okcheck.token);
        localStorage.setItem("permission", okcheck.admindata.permission);
        localStorage.setItem("adminid", okcheck.admindata._id);
        localStorage.setItem("loginType", okcheck.admindata.loginType);
        localStorage.setItem("WEBSITE", WEBSITE);
        // sessionStorage.setItem("token", okcheck.token);
        // sessionStorage.setItem("admin", okcheck.token);
        // sessionStorage.setItem('token', JSON.stringify(data.token));
        SetlocalStorageValue(localStorage.getItem("sessiondata"));
        navigate("/dashboard");
        toast.success("Login Successfully");
      }
    } catch (error) {
      setShowLoader("none");
      if (error.response.status === 403) {
        toast.error("Account Blocked");
      } else {
        toast.error("Details Not Match");
      }

      //  toast.error(error);
    }
  };
  const regenerateOtp = async () => {
    try {
      const add = await axios.post(
        `${URL}/admin/resendotp`,
        { adminid },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      );
      if (add.status === 200) {
        toast.success("OTP Regenerate Successfully");
      }
    } catch (error) {
      // toast.error("Details Not Match...");
    }
  };
  const onSubmit_LoginForm = (e) => {
    e.preventDefault();

    const { email, password } = values;
    if (currentActive === "Login") {
      if (!email || !password) {
        toast.error("All Fields Required");
        return;
      }
      AddData(values);
    }
  };
  const onSubmit_Forgetpassword = (e) => {
    e.preventDefault();

    const { email } = values;
    if (currentActive === "Forget_Password") {
      if (!email) {
        toast.error("Email Fields Required");
        return;
      }
      ForgetData_Email_send(values);
    }
  };

  const ForgetData_Email_send = async (userData) => {
    try {
      setShowLoader("block");
      const add = await axios.post(`${URL}/admin/forgetpassword`, userData, {
        headers: { "X-API-Key": APP_X_API_KEY },
      });
      if (add.status === 200) {
        setShowLoader("none");
        const data = await add?.data?.data;
        const adminid = data?.adminid;
        // const otp = data?.otp;
        setadminid(adminid);
        // setotp(otp);
        // setValues2({ id: adminid });
        setShow("block");
        toast.success("Please check OTP Detail");
        setCurrentActive("Verify_Otp");
      }
    } catch (error) {
      setShowLoader("none");
      toast.error("Details Not Match...");
    }
  };

  const onSubmit_Verify_otp = async (e) => {
    e.preventDefault();

    try {
      const otp1 = `${otpnumber.one}${otpnumber.two}${otpnumber.three}${otpnumber.four}`;

      const userData5 = {
        adminid: adminid,
        otp: otp1,
      };
      setShowLoader("block");
      const add = await axios.post(`${URL}/admin/verifyotp`, userData5, {
        headers: { "X-API-Key": APP_X_API_KEY },
      });
      if (add.status === 200) {
        setShow("block");
        setShowLoader("none");
        toast.success("OTP Verified Successfully");
        setCurrentActive("Update_Password");
      }
    } catch (error) {
      setShowLoader("none");
      toast.error("OTP Not Match...");
    }
  };

  return (
    <div>
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="container-scroller login-bg">
        <div className="container-fluid login-page page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-6 m-0 p-0">
                <p style={styles.centered}> WELCOME TO HEART2HEART ADMIN </p>
                {/* <img className="left-img" src={leftimg} alt="loading......" /> */}
              </div>
              <div className="col-lg-6 m-0 p-0">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo mr-0 pt-1 text-center">
                    <img src={Logo} alt="logo" style={{ width: "20%" }} />
                  </div>
                  {localStorageValue ? "" : ""}
                  <h4 className="head-txt mb-2 mt-2">
                    {currentActive === "Login" ? "LOGIN" : null}
                    {currentActive === "Forget_Password"
                      ? "Entered your registered Email ID to recover the password"
                      : null}
                    {currentActive === "Verify_Otp" ? "VERIFY OTP" : null}
                    {currentActive === "Update_Password"
                      ? "UPDATE PASSWORD"
                      : null}
                  </h4>
                  {/* <h6 className="font-weight-light">Sign in to continue.</h6> */}
                  {/* Login start */}
                  {/* ============================================ start Form One Login Form Only  ======================================= */}

                  <div
                    style={{
                      display: `${
                        currentActive === "Login" ? "block" : "none"
                      }`,
                    }}
                  >
                    <form className="pt-3" onSubmit={onSubmit_LoginForm}>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-feild form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Enter Email Id"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <span className="position-relative">
                          <input
                            type={showPassword3 ? "text" : "password"}
                            className="form-feild form-control form-control-lg"
                            id="exampleInputPassword2"
                            placeholder="Password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                          />
                          <i
                            className={`fa-solid ${
                              showPassword3 ? "fa-eye-slash" : "fa-eye"
                            } menu-icon input-eyeicon`}
                            onClick={togglePasswordVisibility3}
                          ></i>
                        </span>
                      </div>

                      <div className="my-2 mt-3 d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <label className="form-check-label text-muted">
                            {/* <input
                              type="checkbox"
                              className="checke-box form-check-input"
                            />
                            Remember me */}
                          </label>
                        </div>
                        <Link className="auth-link text-black">
                          <Link className="text-primary" onClick={ChangePage_2}>
                            Forgot Password?
                          </Link>
                        </Link>
                      </div>

                      <div className="mt-5">
                        <button
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          type="submit"
                        >
                          Log in
                        </button>
                      </div>

                      {/* <div className="text-center mt-4 font-weight-light">
                      Forget Password ?{" "}
                      <Link to='/forgetpassword' className="text-primary">Click Here</Link>
                    </div> */}
                    </form>
                  </div>
                  {/* ============================================ End Form One Login Form Only  ======================================= */}
                  {/* ============================================ Start Forget Password Email Input Form Only  ======================================= */}

                  {/* Forget start */}
                  <div
                    style={{
                      display: `${
                        currentActive === "Forget_Password" ? "block" : "none"
                      }`,
                    }}
                  >
                    <form className="pt-3" onSubmit={onSubmit_Forgetpassword}>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-feild form-control form-control-lg"
                          id="exampleInputEmail3"
                          placeholder="Enter Email Id"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="my-2 mt-3 d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <label className="form-check-label text-muted">
                            {/* <input
                              type="checkbox"
                              className="checke-box form-check-input"
                            /> */}
                          </label>
                        </div>
                        <Link className="auth-link text-black">
                          <Link className="text-primary" onClick={ChangePage_1}>
                            Login Here
                          </Link>
                        </Link>
                      </div>

                      <div className="mt-5">
                        <button
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          type="submit"
                        >
                          Send OTP
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* ============================================ End Forget Password Email Input Form Only  ======================================= */}

                  {/* ============================================ Start Verify Otp Input Form Only  ======================================= */}
                  <div
                    style={{
                      display: `${
                        currentActive === "Verify_Otp" ? "block" : "none"
                      }`,
                    }}
                  >
                    <form className="pt-3" onSubmit={onSubmit_Verify_otp}>
                      <div className="form-group">
                        {/* otp */}
                        <div className="otp-input-field">
                          <input
                            type="number"
                            name="one"
                            maxLength={1}
                            value={otpnumber.one}
                            onChange={handleChangeOtp}
                          />
                          <input
                            type="number"
                            name="two"
                            maxLength={1}
                            value={otpnumber.two}
                            onChange={handleChangeOtp}
                          />
                          <input
                            type="number"
                            name="three"
                            maxLength={1}
                            value={otpnumber.three}
                            onChange={handleChangeOtp}
                          />
                          <input
                            type="number"
                            name="four"
                            maxLength={1}
                            value={otpnumber.four}
                            onChange={handleChangeOtp}
                          />
                        </div>

                        {/* <input
                          type="email"
                          className="form-feild form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Enter Email Id"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        /> */}
                      </div>

                      <div className="my-2 mt-3 d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          {/* <label className="form-check-label text-muted">
                            <input
                              type="checkbox"
                              className="checke-box form-check-input"
                            />
                          </label> */}
                          <Link className="auth-link text-black">
                            Didn't received verification OTP?{" "}
                            <Link
                              className="text-primary"
                              onClick={regenerateOtp}
                            >
                              Resend again
                            </Link>
                          </Link>
                        </div>
                        <Link className="auth-link text-black">
                          <Link className="text-primary" onClick={ChangePage_1}>
                            Login Here
                          </Link>
                        </Link>
                      </div>

                      <div className="mt-5">
                        <button
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          type="submit"
                        >
                          Verify OTP
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* ============================================ End Verify Otp Input Form Only  ======================================= */}

                  {/* ============================================ Start Update  Password Input Form Only  ======================================= */}
                  <div
                    style={{
                      display: `${
                        currentActive === "Update_Password" ? "block" : "none"
                      }`,
                    }}
                  >
                    <form className="pt-3" onSubmit={onSubmit2}>
                      <div className="form-group">
                        <span className="position-relative">
                          <input
                            type={showPassword1 ? "text" : "password"}
                            className="form-control form-control-lg"
                            id="exampleInputPassword4"
                            placeholder="New Password"
                            name="newpassword"
                            value={values2.newpassword}
                            onChange={handleChange2}
                          />

                          <i
                            className={`fa-solid ${
                              showPassword1 ? "fa-eye-slash" : "fa-eye"
                            } menu-icon input-eyeicon`}
                            onClick={togglePasswordVisibility1}
                          ></i>
                        </span>
                      </div>
                      <div className="form-group">
                        <span className="position-relative">
                          <input
                            type={showPassword2 ? "text" : "password"}
                            className="form-control form-control-lg"
                            id="exampleInputPassword5"
                            placeholder="Re-Enter Password"
                            name="reenternewpassword"
                            value={values2.reenternewpassword}
                            onChange={handleChange2}
                          />
                          <i
                            className={`fa-solid ${
                              showPassword2 ? "fa-eye-slash" : "fa-eye"
                            } menu-icon input-eyeicon`}
                            onClick={togglePasswordVisibility2}
                          ></i>
                        </span>
                      </div>
                      <div className="my-2 mt-3 d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <label className="form-check-label text-muted">
                            {/* <input
                              type="checkbox"
                              className="checke-box form-check-input"
                            /> */}
                          </label>
                        </div>
                        <Link className="auth-link text-black">
                          <Link className="text-primary" onClick={ChangePage_1}>
                            Login Here
                          </Link>
                        </Link>
                      </div>

                      <div className="mt-5">
                        <button
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          type="submit"
                        >
                          Update Password
                        </button>
                      </div>

                      {/* <div className="text-center mt-4 font-weight-light">
                      Forgot Password ?{" "}
                      <Link to='/forgetpassword' className="text-primary">Click Here</Link>
                    </div> */}
                    </form>
                  </div>
                  {/* ============================================ End Update  Password Input Form Only  ======================================= */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const styles = {
  centered: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    color: "#333",
    fontWeight: 800,
    fontSize: "28px",
    lineHeight: "normal",
  },
};
export default Login;
