import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const initialstate = {
  name: "",
  username: "",
  email: "",
  password: "",
  phone: "",
  loginType: "",
  permission: "",
};

const AddStaff = () => {
  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const navigate = useNavigate();
  const [values, setValues] = useState(initialstate);
  const [permission, setPermission] = useState([]);

  const CreateData = async () => {
    const url = `${URL}/admin/register`;
    const { name, username, email, phone, loginType, password } = values;
    const str = username;
    const usernm = str.replace(/\s+/g, "").toLowerCase();

    axios
      .post(
        url,
        {
          name: name,
          username: usernm,
          email: email,
          phone: phone,
          password: password,
          loginType: loginType,
          permission: permission,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Data Add Successfully");
        navigate("/viewstaff");
      })
      .catch((error) => {
        //alert("Data Already Exist (EMAIL / USERNAME)");
        // console.log(error);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log(values);
    const { name, username, email, phone, loginType, password } = values;

    if (!name || !username || !email || !phone || !loginType || !password) {
      toast.error("Please fill out all fields");
      return;
    }
    CreateData();
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };
  const handleCheckboxChange = (e) => {
    let data = permission;
    const value = e.target.value;
    const checkdata = data.includes(value);
    // console.log(checkdata);
    if (checkdata) {
      data.pop(value);
      setPermission(data);

      //  console.log("true");
    } else {
      data.push(value);
      setPermission(data);

      //  console.log("false");
    }
    // console.log(permission);
  };
  // Multi Checkbox
  //   const getPjl = (e) => {
  //     let data = pjl
  //     data.push(e.target.value)
  //     setPjl(data)
  //   }
  return (
    <div className="content-wrapper">
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">ADD STAFF</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label for="exampleInputUsername1">NAME</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Enter NAME"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">USER NAME</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter User Name"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label for="exampleInputEmail1">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">PASSWORD</label>
                  <input
                    type="password"
                    placeholder=" Password"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">PHONE</label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputEmail1"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">Login Type</label>

                  <select
                    name="loginType"
                    value={values.loginType}
                    onChange={handleChange}
                    class="form-control"
                  >
                    <option value="">Select</option>
                    <option value="SuperAdmin">SuperAdmin</option>
                    <option value="SubAdmin">SubAdmin</option>
                  </select>
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">PERMISSIONS : -</label>
                  <div className="ml-5">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        value="1"
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      USER
                    </label>
                    <br />
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        value="2"
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      CATEGORIES
                    </label>
                    <br />
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        value="3"
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      VIDEOS
                    </label>
                    <br />
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        value="4"
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      SUBSTAFF
                    </label>
                    <br />
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        value="5"
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      PERSON TYPE
                    </label>
                    <br />
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        value="6"
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      NOTIFICATION
                    </label>
                    <br />
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        value="7"
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      PAGES
                    </label>
                    <br />
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        value="8"
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      SKILL LEVEL
                    </label>{" "}
                    <br />
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        value="9"
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      WORK CYCLE
                    </label>{" "}
                    <br />
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        value="10"
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                      BANNER/VIDEO WEEK
                    </label>{" "}
                    <br />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default AddStaff;
