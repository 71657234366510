import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
// import { Link } from "react-router-dom";

import "react-confirm-alert/src/react-confirm-alert.css";
import dateFormat from "dateformat";

import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const TranjectionDetail = () => {
  const [Tranjection, setTranjection] = useState([]);

  const [showloader, setShowLoader] = useState("none");
  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getAllTranjectionDetail`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getData;
    setTranjection(Data1.data.data);
    setShowLoader("none");
    // console.log(Data1.data.data);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">View Transactions</h4>

              {/* <p className="card-description">Add className</p> */}
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>User Name</th>
                      <th>Plan Detail</th>
                      <th>Amount</th>
                      <th>Transactions Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Tranjection?.map((val, index) => {
                      return (
                        <>
                          {/* {val?.TranjectionforSingleuser === "false" ? ( */}
                          <tr key={index}>
                            <td className="py-1"># {index + 1}</td>
                            {/* <td class="py-1">
                          <img src={val?.imageurl} alt="img" />
                        </td> */}
                            <td>{val?.userid?.name}</td>

                            <td>{val?.planid?.title} ...</td>
                            <td>{val?.amount} ...</td>
                            <td>
                              {val?.tranjectionStatus === true
                                ? "Success"
                                : "Fail"}{" "}
                            </td>
                            <td>
                              {dateFormat(`${val?.createdAt}`, "mmmm dS, yyyy")}
                            </td>
                          </tr>
                          {/* ): null } */}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};

export default TranjectionDetail;
