import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";

const ProtectedRoute = () => {
  const WEBSITE2 = process.env.REACT_APP_SESSION_WEBSITE_NAME;

  const adminlocaltoken = localStorage.getItem("token");
  const WEBSITE = localStorage.getItem("WEBSITE");
  // const { isAuthenticated } = useContext(AuthContext);

  // if (!adminlocaltoken) {
  //   navigate("/login");
  //   toast.info("Acess Denied...");
  // }
  //  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
  let auth = adminlocaltoken ? { token: true } : { token: false };
  return auth.token && WEBSITE === WEBSITE2  ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
