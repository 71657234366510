import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import loaderimg from "../../assets/image/loader.gif";
// import WhatsappSend from "../../utils/WhatsappSend";
// import dateFormat from "dateformat";
// import { format } from "date-fns";

const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const EditSingleUser = () => {
  const initialValue = {
    name: "",
    email: "",
    countryid: "",
    stateid: "",
    birthdate: "",
    image: "",
    mobile: "",
    fulltimesocialworker: false,
    presignedUrl1: "",
  };

  const [values, setValues] = useState(initialValue);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);

  const navigate = useNavigate();

  //*********************** Category End ******************************************

  const [showloader, setShowLoader] = useState("none");

  const { id, userType } = useParams();

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const getCountry = async () => {
    const getCountryData = await axios.get(`${URL}/admin/getAllCountry`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getCountryData;
    setCountry(Data1?.data?.data);
  };
  const getstateByCountryId = async (countryid) => {
    const getStateData = await axios.get(
      `${URL}/admin/getStateByCountryId/${countryid}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Data1 = await getStateData;
    setState(Data1?.data?.data);
  };

  const getSingleData = async () => {
    // setShowLoader("block");
    const getData = await axios.get(`${URL}/users/getSingleUser/${id}`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getData;
    const userData = Data1?.data?.data;
    setValues({
      name: userData.name || "",
      email: userData.email || "",
      countryid: userData.countryid?._id || "",
      stateid: userData.stateid?._id || "",
      mobile: userData.mobile || "",
      image: userData.image || "",
      presignedUrl1: userData.presignedUrl1 || "",
      birthdate: userData.birthdate || "",
      fulltimesocialworker: userData.fulltimesocialworker || false,
    });
    // setValues({
    //   ...values,
    //   name: datafinal.name,
    //   email: datafinal.email,
    //   countryid: datafinal.countryid._id,
    //   stateid: datafinal.stateid._id,
    //   mobile: datafinal.mobile,
    //   image: datafinal.image,
    //   birthdate: datafinal.birthdate,
    //   fulltimesocialworker: datafinal.fulltimesocialworker,
    // });
    setShowLoader("none");
    getstateByCountryId(Data1?.data?.data.countryid._id);
  };

  useEffect(() => {
    getSingleData();
    getCountry();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "mobile" && value.length > 10) {
      // toast.error("Please Select 10 digit number");
      return;
    }

    setValues({ ...values, [name]: value });
  };
  const handleChangeCountry = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    getstateByCountryId(value);
    setValues({ ...values, [name]: value });
  };
  const handleImageChange = async (e) => {
    setShowLoader("block");
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    var requestOptions = {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-API-Key": APP_X_API_KEY,
      },
    };
    try {
      const fetchdata = axios.post(
        `${URL}/admin/imageUpload_Use/imageUpload`,
        formData,
        requestOptions
      );
      const response = await fetchdata;
      if (response.status === 200) {
        //toast.success("Data Uploaded Successfully");
        setShowLoader("none");
        //seturl(response?.data?.url);
        setValues({
          ...values,
          image: response?.data?.url,
          presignedUrl1: response?.data?.presignedUrl,
        });
      } else {
        setShowLoader("none");
        toast.error("Fail To Load");
      }
    } catch (error) {
      setShowLoader("none");
      console.error("Error uploading image:", error);
      toast.error(
        "An error occurred while uploading the image. Please try again."
      );
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();

    const { name, email, countryid, stateid, birthdate, image, mobile } =
      values;

    if (
      !name ||
      !email ||
      !countryid ||
      !stateid ||
      !birthdate ||
      !image ||
      !mobile
    ) {
      toast.error("Please fill out all fields");
      return;
    }
    UpdateData(values);
  };
  const UpdateData = async () => {
    setShowLoader("block");
    const url = `${URL}/users/updateInfo/${id}`;
    const {
      name,
      email,
      countryid,
      stateid,
      birthdate,
      image,
      mobile,
      fulltimesocialworker,
    } = values;

    axios
      .put(
        url,
        {
          name: name,
          email: email,
          countryid: countryid,
          stateid: stateid,
          birthdate: birthdate,
          fulltimesocialworker: fulltimesocialworker,
          image: image,
          mobile: mobile,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("User Update Successfully");
        navigate(`/users/${userType}`);
        setShowLoader("none");
      })
      .catch((error) => {
        setShowLoader("none");
        alert("service error");
      });
  };
  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link to={`/users/${userType}`} className="btn btn-primary mb-2">
        {" "}
        Back{" "}
      </Link>
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">User Details </h4>
              <p className="card-description"></p>

              {/*  row start */}
              <form className="forms-sample" onSubmit={onSubmit}>
                <div class="row">
                  <div class="col-md-12 ">
                    <div className="d-flex justify-content-center align-items-center ">
                      <div className="text-center " style={{ width: "150px" }}>
                        {values?.presignedUrl1 ? (
                          <img
                            src={values.presignedUrl1}
                            alt="img"
                            className="w-100"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Name </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder={values?.name}
                        value={values?.name}
                        maxlength="20"
                        name="name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Country </label>

                      <select
                        className="form-control"
                        id="f1"
                        name="countryid"
                        onChange={handleChangeCountry}
                        value={values?.countryid}
                      >
                        <option value="">Select</option>
                        {country.map((ctry, index) => {
                          return (
                            <option
                              value={ctry._id}
                              key={index}
                              selected={
                                ctry._id === values?.countryid
                                  ? "selected"
                                  : null
                              }
                            >
                              {ctry.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">State </label>
                      <select
                        className="form-control"
                        id="f1"
                        name="stateid"
                        onChange={handleChange}
                        value={values?.stateid}
                      >
                        <option value="">Select</option>
                        {state.map((stat, index) => {
                          return (
                            <option
                              value={stat._id}
                              key={index}
                              selected={
                                stat._id === values?.stateid ? "selected" : null
                              }
                            >
                              {stat.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Email Id </label>
                      <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder={values?.email}
                        value={values?.email}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">DOB</label>
                      <input
                        type="date"
                        name="birthdate"
                        onChange={handleChange}
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="DOB"
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={(e) => e.target.showPicker()}
                        value={values?.birthdate.split("T")[0]}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Mobile</label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputUsername1"
                        value={values?.mobile}
                        name="mobile"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                {/* row end */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Image</label>
                      <div
                        className="loader-container "
                        style={{ display: showloader }}
                      >
                        <img src={loaderimg} alt="" className="loaderImage" />
                      </div>
                      <input
                        type="file"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="Image Upload"
                        name="file"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSingleUser;
