import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams, Link } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert"; // Import
// import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { Context } from '@ckeditor/ckeditor5-core';
// import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
// import { Essentials } from '@ckeditor/ckeditor5-essentials';
// import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
// import Editor from "../../components/Editor";
// import DefaultImg from "../../assets/image/defaultimages/default.png";
import loaderimg from "../../assets/image/loader.gif";
import Modal from "../../components/Modal";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const ViewDownloadLink = () => {
  const { id } = useParams();
  const initialState = {
    linkdetail: "",
    downloadId: id,
  };
  const [values, setValues] = useState(initialState);
  const [page, setPages] = useState([]);

  // const [isEditClick, setisEditClick] = useState(false);
  // const [editId, seteditId] = useState("");
  const [showloader, setShowLoader] = useState("none");

  // const [data2, setData] = useState("");

  const [modalactive, setModalActive] = useState("none"); //model
  const [details, setdetails] = useState(""); //model
  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************

  const setModalHide = () => {
    setModalActive("none");
    setdetails("");
  };

  const AddData = async (userData) => {
    await axios
      .post(
        `${URL}/admin/createDownloadLink`,
        {
          downloadId: values?.downloadId,
          linkdetail: values?.linkdetail,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Data Added Successfully");
        getAllData();
        // setValues({ linkdetail: "" });
        //  setData("");
      })
      .catch((error) => {
        alert("service error");
      });
  };
  const confirmDelete = (Linkid) => {
    confirmAlert({
      message: "Are you sure you want to delete this Data.",
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteData(Linkid),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const DeleteData = async (Linkid) => {
    await axios
      .delete(`${URL}/admin/deleteDownloadLinkByDownloadLinkId/${Linkid}`, {
        headers: { "X-API-Key": APP_X_API_KEY },
      })
      .then((result) => {
        toast.success("Data Delete Successfully ");
        getAllData();
      })
      .catch((error) => {
        alert("service error");
        // console.log(error);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { downloadId, linkdetail } = values;

    if (!downloadId || !linkdetail) {
      toast.error("Please Add Url Link");
      return;
    }

    AddData(values);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = name === "file" ? e.target.files[0] : e.target.value;
    setValues({ ...values, [name]: value });
  };

  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(
      `${URL}/admin/getallDownload_LinkByDownloadId/${id}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    setShowLoader("none");
    const Data1 = await getData;
    setPages(Data1?.data?.data);
    //  if(Data1?.data?.TotalData === 0)
    //  {
    //   toast.error("No Data Found...");
    //  }
  };

  useEffect(() => {
    getAllData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <Modal
        modalactive={modalactive}
        title="Feedback Details"
        description={details}
        setModalHide={setModalHide}
      />

      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link to="/viewalldownload" className="btn btn-primary mb-2">
        {" "}
        Back{" "}
      </Link>

      <div className="row">
        {/* first */}
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                {/* {isEditClick ? "EDIT DATA" : "ADD URL"} */}Add Link
              </h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">URL</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="linkdetail"
                    name="linkdetail"
                    value={values?.linkdetail}
                    onChange={handleChange}
                  />
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">DOWNLOAD</h4>
              <p className="card-description"></p>
              <form className="forms-sample">
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">Label Title </label>
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="linkdetail"
                    value={page?.downloaddetail?.title}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1"> Description </label>
                  <textarea
                    type="text"
                    readOnly
                    rows={5}
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="linkdetail"
                    value={page?.downloaddetail?.description}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Download Link's</h4>
              {/*  <p className="card-description">Total pages : {page?.length}</p>*/}
              <div className="table-responsive">
                <table className="table" id="datatable">
                  <thead>
                    <tr>
                      <th>Sr.no </th>
                      <th>Link</th>

                      <th className="txt-right">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {page?.data?.map((pages, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1} </td>
                          <td>{pages?.linkdetail}</td>

                          <td className="txt-right">
                            <Link>
                              <i
                                class="fa-solid fa-trash menu-icon"
                                style={{ marginLeft: "10px" }}
                                onClick={() => confirmDelete(pages?._id)}
                              ></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* first */}
      </div>
    </div>
  );
};

export default ViewDownloadLink;
