import React from "react";
import { useNavigate, useParams,Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
// import WhatsappSend from "../../utils/WhatsappSend";

const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const initialstate = {
  imageurl: "",
};

const ViewSingleTemplateFromCategory = () => {
  const { backcategoryid,id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState(initialstate);

  const [showloader, setShowLoader] = useState("none");

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  useEffect(() => {
    const getData = async () => {
      const getdata = await axios.get(`${URL}/admin/getSingleTemplate/${id}` ,{ headers: { 'X-API-Key': APP_X_API_KEY } });
      const Singledata = await getdata;
      setValues(Singledata?.data?.data);
    };

    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const UpdateData = async () => {
    setShowLoader("block");
    const url = `${URL}/admin/updateTemplate/${id}`;

    axios
      .patch(url, {
        imageurl: values?.imageurl,
      } ,{ headers: { 'X-API-Key': APP_X_API_KEY } })
      .then((result) => {
        
        toast.success("Data Update Successfully");
        navigate("/viewalltemplate");
        setShowLoader("none");
      })
      .catch((error) => {
        setShowLoader("none");
        alert("service error");
      
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { imageurl } = values;

    if (!imageurl) {
      toast.error("Please Select Image");
      return;
    }
    UpdateData(values);
  };

  // const handleChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setValues({ ...values, [name]: value });
  // };
  const handleImageChange = async (e) => {
    setShowLoader("block");
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    var requestOptions = {
      headers: {
        "Content-Type": "multipart/form-data",'X-API-Key': APP_X_API_KEY
      },
    
    };
    try {
      const fetchdata = axios.post(
        `${URL}/admin/imageUpload_Use/imageUpload`,
        formData,
        requestOptions
      );
      const response = await fetchdata;
      if (response.status === 200) {
        //toast.success("Data Uploaded Successfully");
        setShowLoader("none");
        //seturl(response?.data?.url);
        setValues({ ...values, imageurl: response?.data?.url });
      } else {
        setShowLoader("none");
        toast.error("Fail To Load");
      }}
      catch (error) {
        setShowLoader("none");
        console.error("Error uploading image:", error);
        toast.error("An error occurred while uploading the image. Please try again.");
      }
  };
  // const isoDate = new Date(value?.date);
  //   const formattedDate = isoDate.toISOString().split('T')[0];
  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link to={`/viewsinglecategory/${backcategoryid}`} className="btn btn-primary mb-2"> Back </Link>
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Edit Template</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">TEMPLATE</label>
                      <input
                        type="file"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="Image Upload"
                        name="file"
                        onChange={handleImageChange}
                      />
                      {/* <img src={values.imageurl} alt="img" /> */}
                    </div>
                    <button type="submit" className="btn btn-primary mr-2">
                  Update Template
                </button>
                  </div>
                </div>
               
                {/* start */}
                <div className="row">
                  <div className="col-md-7 mx-auto">
                    <div
                      className="row bg-info p-5 edit-temp-card"
                      style={{ backgroundImage: `url(${values?.imageurl})` }}
                    >
                      <div className="col-md-12 text-right"></div>
                      <div className="col-md-7 mx-auto">
                        <div
                          className="d-flex mx-auto mb-4 rounded-pill bg-light"
                          style={{ width: "150px", height: "150px" }}
                        >
                        
                        </div>
                        <div
                          className="w-100 rounded "
                          style={{ height: "200px" }}
                        >
                          <input
                            type="text"
                            name="text"
                            className="w-100 h-100"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ENd */}
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default ViewSingleTemplateFromCategory;
