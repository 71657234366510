import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ isActive, onClick }) => {
  const permission = localStorage.getItem("permission");

  const x = permission;
  const [number, setActiveLink] = useState(0); // Initialize active link state

  const handleLinkClick = (index) => {
    setActiveLink(index); // Update active link state when clicked
  };
  const activeinactive = (ACTIVEDATA) => {
    sessionStorage.setItem("ACTIVEINACTIVE", ACTIVEDATA);
  };

  const ClearDataForUserOnly = () => {
    
    sessionStorage.setItem("Session_searchFilter", '');
    sessionStorage.setItem("Session_startDateFilter", '');
    sessionStorage.setItem("Session_endDateFilter", '');
    sessionStorage.setItem(
      "Session_userTypeFilter","Normal"
    );

    // =============================
    sessionStorage.setItem("Session_countryFilter", "");
    sessionStorage.setItem("Session_stateFilter", "");
    sessionStorage.setItem("Session_PlanactiveInactiveFilter", "");
    sessionStorage.setItem("Session_registeredStatusFilter", "");
  }
  const activeinactivedetail = sessionStorage.getItem("ACTIVEINACTIVE");

  useEffect(() => {
    if (activeinactivedetail === "DASHBOARDACTIVE") {
      setActiveLink(0);
    }
    if (activeinactivedetail === "USERACTIVE") {
      setActiveLink(1);
    }
    if (activeinactivedetail === "TEMPLATESACTIVE") {
      setActiveLink(5);
    }
    if (activeinactivedetail === "SUBSCRIPTIONPLANACTIVE") {
      setActiveLink(15);
    }
    if (activeinactivedetail === "CATEGORIESACTIVE") {
      setActiveLink(2);
    }
    if (activeinactivedetail === "PAGESACTIVE") {
      setActiveLink(11);
    }
    if (activeinactivedetail === "DOWNLOADACTIVE") {
      setActiveLink(13);
    }
    if (activeinactivedetail === "NOTIFICATIONACTIVE") {
      setActiveLink(8);
    }
  }, [activeinactivedetail]);
  return (
    <nav
      class={`sidebar sidebar-scrll sidebar-offcanvas ${
        isActive === "active" ? "active" : ""
      }`}
      id="sidebar2"
    >
      <ul class="nav">
        <li
          class={`nav-item ${number === 0 ? "active" : null} ${
            number !== 0 ? null : "active"
          }`}
        >
          <Link
            class="nav-link"
            to="/dashboard"
            onClick={(e) => {
              onClick(0);
              handleLinkClick(0);
              activeinactive("DASHBOARDACTIVE");
              ClearDataForUserOnly();
            }}
          >
            <i class="icon-grid menu-icon"></i>
            <span class="menu-title">Dashboard</span>
          </Link>
        </li>

        <li
          class={`nav-item ${number === 1 ? "active" : null} ${
            number !== 1 ? null : "active"
          }`}
        >
          <Link
            class="nav-link"
            to="/users/Normal"
            onClick={(e) => {
              onClick(1);
              handleLinkClick(1);
              activeinactive("USERACTIVE");
              ClearDataForUserOnly();
            }}
          >
            <i class="fa fa-users menu-icon"></i>
            <span class="menu-title">Users</span>
          </Link>
        </li>

        {/* <li class={`nav-item ${number === 3 ? 'active' : null} ${number !== 3 ?  null:'active'}`}>
            <Link class="nav-link" to="/viewevent"  onClick={(e)=>{onClick(3);handleLinkClick(3)}}>
              <i class="fa-solid fa-video-camera menu-icon"></i>
              <span class="menu-title">Event</span>
            </Link>
          </li>
        */}

        {/* <li class={`nav-item ${number === 4 ? 'active' : null} ${number !== 4 ?   null:'active'}`}>
            <Link class="nav-link" to="/viewpledge"  onClick={(e)=>{onClick(4);handleLinkClick(4)}}>
              <i class="fa-solid fa-user-group menu-icon"></i>
              <span class="menu-title">Pledge</span>
            </Link>
          </li>
        */}

        <li
          class={`nav-item ${number === 5 ? "active" : null} ${
            number !== 5 ? null : "active"
          }`}
        >
          <Link
            class="nav-link"
            to="/viewalltemplate/1"
            onClick={(e) => {
              onClick(5);
              handleLinkClick(5);
              activeinactive("TEMPLATESACTIVE");
              ClearDataForUserOnly();
            }}
          >
            <i class="fa-solid fa fa-user menu-icon"></i>
            <span class="menu-title">Templates</span>
          </Link>
        </li>

        <li
          class={`nav-item ${number === 15 ? "active" : null} ${
            number !== 15 ? null : "active"
          }`}
          onClick={(e) => {
            onClick(15);
            handleLinkClick(15);
            activeinactive("SUBSCRIPTIONPLANACTIVE");
            ClearDataForUserOnly();
          }}
        >
          <Link class="nav-link" to="/subscriptionplan">
            <i class="fa-solid fa fa-money menu-icon"></i>
            <span class="menu-title">Subscription Plan </span>
          </Link>
        </li>

        <li
          class={`nav-item ${number === 2 ? "active" : null} ${
            number !== 2 ? null : "active"
          }`}
        >
          <Link
            class="nav-link"
            to="/viewcategory"
            onClick={(e) => {
              onClick(2);
              handleLinkClick(2);
              activeinactive("CATEGORIESACTIVE");
              ClearDataForUserOnly();
            }}
          >
            <i class="fa-solid fa-list menu-icon"></i>
            <span class="menu-title">Categories</span>
          </Link>
        </li>

        <li
          class={`nav-item ${number === 11 ? "active" : null} ${
            number !== 11 ? null : "active"
          }`}
        >
          <Link
            class="nav-link"
            to="/viewpages"
            onClick={(e) => {
              onClick(11);
              handleLinkClick(11);
              activeinactive("PAGESACTIVE");
              ClearDataForUserOnly();
            }}
          >
            <i class="fa-solid fa fa-file menu-icon"></i>
            <span class="menu-title">Pages</span>
          </Link>
        </li>

        <li
          class={`nav-item ${number === 13 ? "active" : null} ${
            number !== 13 ? null : "active"
          }`}
        >
          <Link
            class="nav-link"
            to="/viewalldownload"
            onClick={(e) => {
              onClick(13);
              handleLinkClick(13);
              activeinactive("DOWNLOADACTIVE");
              ClearDataForUserOnly();
            }}
          >
            <i class="fa-solid fa-download menu-icon"></i>
            <span class="menu-title">Downloads</span>
          </Link>
        </li>

        <li
          class={`nav-item ${number === 8 ? "active" : null} ${
            number !== 8 ? null : "active"
          }`}
        >
          <Link
            class="nav-link"
            to="/viewnotification"
            onClick={(e) => {
              onClick(8);
              handleLinkClick(8);
              activeinactive("NOTIFICATIONACTIVE");
              ClearDataForUserOnly();
            }}
          >
            <i class="fa-solid fa-bell menu-icon"></i>
            <span class="menu-title">Notification</span>
          </Link>
        </li>
        {/* {x.includes("6") ? (
          <li class={`nav-item ${number === 8 ? 'active' : null}`}>
            <Link class="nav-link" to="/viewnotification"  onClick={(e)=>{onClick(8)}}>
              <i class="fa-solid fa-bell menu-icon"></i>
              <span class="menu-title">Notification</span>
            </Link>
          </li>
        ) : (
          ""
        )} */}

        {/* 

upstream errro
       */}
      </ul>
    </nav>
  );
};

export default Sidebar;
