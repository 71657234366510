import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";

import "react-confirm-alert/src/react-confirm-alert.css";
import dateFormat from "dateformat";
// import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
// import { Button } from "bootstrap";
// import { confirmAlert } from "react-confirm-alert"; // Import
import { DownloadTableExcel } from "react-export-table-to-excel";

const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const ViewAllPledgeUser = () => {
  const { id } = useParams();
  const [Users, setUsers] = useState([]);
  const [showloader, setShowLoader] = useState("none");
  const [NoPledge, setNoPledge] = useState(0);
  const tableRef = useRef(null);

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************

  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/pledge/getSinglepledge/${id}`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getData;
    setUsers(Data1?.data?.data?.user_id);
    setNoPledge(Data1?.data?.data?.noofpledge);
    setShowLoader("none");
  };

  // const getAllFilteredData = async () => {

  // };

  useEffect(() => {
    getAllData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const confirmDelete = (id) => {
  //   confirmAlert({
  //     message: "Are you sure you want to delete this Data.",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => DeleteData(id),
  //       },
  //       {
  //         label: "Cancel",
  //       },
  //     ],
  //   });
  // };

  // const DeleteData = async (Linkid) => {
  //   await axios
  //     .put(`${URL}/users/deleteUserById/${Linkid}`)
  //     .then((result) => {
  //       toast.success("Data Delete Successful...");
  //       getAllData();

  //     })
  //     .catch((error) => {
  //       alert("service error");
  //       console.log(error);
  //     });
  // };

  return (
    <div className="content-wrapper user-page">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link to="/viewpledge" className="btn btn-primary mb-2">
        {" "}
        Back{" "}
      </Link>

      <div className="row">
        <div className="col-lg-12 grid-margin mt-3 stretch-card">
          <div className="card">
            <div className="card-body">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={tableRef.current}
              >
                <button className="btn btn-primary"> Export excel </button>
              </DownloadTableExcel>
              <br />
              <h4 className="mt-2">No of pledge - {NoPledge} </h4>
              {/* <Link to="/addUsers" className="btn btn-primary">
                Add Users
              </Link> */}
              {/* <p className="card-description">Add className</p> */}
              <div className="table-responsive" id="collapse1">
                <table
                  className="table table-striped tableFixHead"
                  ref={tableRef}
                >
                  <thead>
                    <tr>
                      <th>Sr.no</th>
                      {/* <th>IMAGE</th> */}
                      {/* <th>DAILY EXERCISES</th> */}
                      <th>Date </th>
                      <th>User Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Users?.map((val, index) => {
                      return (
                        <>
                          {/* {val?.UsersforSingleuser === "false" ? ( */}
                          <tr key={index}>
                            <td className="py-1"> {index + 1} )</td>
                            {/* <td class="py-1">
                          <img src={val?.imageurl} alt="img" />
                        </td> */}
                            {/* <td>
                              <Link
                                className="btn"
                                to={`/singleusers_dailyexc/${val._id}`}
                                style={{color: "#6a4dac"}}
                              >
                                <i class="fa-solid fa-eye menu-icon"></i>
                              </Link>
                            </td> */}
                            <td>
                              {dateFormat(`${val?.createdAt}`, "mmmm dS, yyyy")}
                            </td>
                            <td>
                              {val?.name
                                ? val?.name
                                : "Pending Complete Registration"}
                            </td>

                            <td>{val?.email}</td>
                            <td>{val?.mobile}</td>
                          </tr>
                          {/* ): null } */}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* // end */}
      </div>
    </div>
  );
};

export default ViewAllPledgeUser;
