import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import Pagination from "../../components/Pagination";

import dateFormat from "dateformat";

import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const ViewAllPledge = () => {
  const [pledge, setPledge] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [showloader, setShowLoader] = useState("none");
  // Function to filter customers based on input field value ==========================================
  const itemsPerPage = 10; // Set the number of items per page

  const [searchfilterValue, setSearchFilterValue] = useState("");
  const [filtertype, setFilterType] = useState("DEFAULT");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [initialFetchDone, setInitialFetchDone] = useState(false); // Track initial fetch

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // const filterCustomers = (value) => {
  //   if (!value) {
  //     return Users;
  //   }
  //   return Users?.filter((customer) =>
  //     customer?.name?.toLowerCase().includes(value?.toLowerCase())
  //   );
  // };

  // Function to handle input change
  const handleInputChangeSearch = (e) => {
    setSearchFilterValue(e.target.value);
  };

  const SubmitSearch = async (e) => {
    e.preventDefault();
    if (!searchfilterValue) {
      toast.error("Please Enter Search Data");
      return;
    }
    setFilterType("SEARCH");
    const getdata = await axios.get(
      `${URL}/pledge/MultiplefilterPledge?FILTERTYPE=${filtertype}&SEARCH=${searchfilterValue}&page=${currentPage}&limit=${itemsPerPage}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Singledata = await getdata;
    const Data1 = await Singledata;
    setPledge(Data1?.data?.data);
    setTotalPages(Data1?.data.totalPages);
  };

  const SubmitSearch2_by_button = async (e) => {
    setShowLoader("block");
    e.preventDefault();

    if (!searchfilterValue) {
      setShowLoader("none");
      toast.error("Please Enter Search Data");
      return;
    }
    setCurrentPage(1);
    setFilterType("SEARCH");
    const getdata = await axios.get(
      `${URL}/pledge/MultiplefilterPledge?FILTERTYPE=SEARCH&SEARCH=${searchfilterValue}&page=${1}&limit=${itemsPerPage}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Singledata = await getdata;
    const Data1 = await Singledata;
    setPledge(Data1?.data?.data);
    setTotalPages(Data1?.data.totalPages);
    setShowLoader("none");
  };

  // ==========================================================
  //==================== Submit Search End ======================================
  // ==========================================================

  //======================= Date filter start================================================
  // ==========================================================
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "startDate") {
      if (new Date(value) > new Date(endDate)) {
        toast.error("Start date cannot be later than end date.");
      } else {
        setstartDate(value);
      }
    }

    if (name === "endDate") {
      if (!startDate) {
        toast.error("Please Select a Start Date First");
        return;
      }
      if (new Date(value) < new Date(startDate)) {
        toast.error("End date cannot be earlier than start date.");
      } else {
        setendDate(value);
      }
    }
  };

  const onSubmitBothDate = async (e) => {
    // e.preventDefault();
    if (!startDate || !endDate) {
      toast.error("Please fill Start Date and End Date");
      return;
    }
    setFilterType("DATE");
    const getdata = await axios.get(
      `${URL}/pledge/MultiplefilterPledge?FILTERTYPE=DATE&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&limit=${itemsPerPage}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Singledata = await getdata;
    const Data1 = await Singledata;
    setPledge(Data1?.data?.data);
    setTotalPages(Data1?.data.totalPages);
    setShowLoader("none");
  };

  const onSubmitByButton = async (e) => {
    setShowLoader("block");
    e.preventDefault();
    setCurrentPage(1);
    if (!startDate || !endDate) {
      toast.error("Please fill Start Date and End Date");
      return;
    }
    setFilterType("DATE");
    const getdata = await axios.get(
      `${URL}/pledge/MultiplefilterPledge?FILTERTYPE=DATE&startDate=${startDate}&endDate=${endDate}&page=${1}&limit=${itemsPerPage}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Singledata = await getdata;
    const Data1 = await Singledata;
    setPledge(Data1?.data?.data);
    setTotalPages(Data1?.data.totalPages);
    setShowLoader("none");
  };

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const getAllData = async () => {
    setShowLoader("block");
    setFilterType("DEFAULT");
    const getData = await axios.get(
      `${URL}/pledge/MultiplefilterPledge?FILTERTYPE=${filtertype}&page=${currentPage}&limit=${itemsPerPage}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Data1 = await getData;
    setPledge(Data1?.data?.data);
    setTotalPages(Data1?.data.totalPages);
    setShowLoader("none");
  };

  useEffect(() => {
    // getAllData(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!initialFetchDone) {
      getAllData(); // eslint-disable-next-line
      setInitialFetchDone(true);
    } else {
      if (filtertype === "DEFAULT") {
        // eslint-disable-next-line
        getAllData(); // eslint-disable-next-line
      }
      if (filtertype === "SEARCH") {
        // eslint-disable-next-line
        SubmitSearch(); // eslint-disable-next-line
      }
      if (filtertype === "DATE") {
        // eslint-disable-next-line
        onSubmitBothDate(); // eslint-disable-next-line
      }
    }
  }, [currentPage]);

  const confirmDelete = (id) => {
    confirmAlert({
      message: "Are you sure you want to delete this Data.",
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteData(id),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const DeleteData = async (Linkid) => {
    await axios
      .put(`${URL}/pledge/deletePledgeById/${Linkid}`, {
        headers: { "X-API-Key": APP_X_API_KEY },
      })
      .then((result) => {
        toast.success("Pledge Delete Successfully");
        getAllData();
      })
      .catch((error) => {
        alert("service error");
        // console.log(error);
      });
  };
  const ClearData = () => {
    getAllData();
    setSearchFilterValue("");
    setstartDate("");
    setendDate("");
    setCurrentPage(1);
  };
  // Helper function to format time in AM/PM format
  const formatTime = (timeString) => {
    if (!timeString) {
      return ""; // Return empty string if timeString is undefined or null
    }

    // Parse the input time string in 24-hour format (e.g., "14:55")
    const [hours, minutes] = timeString.split(":");
    let formattedHours = parseInt(hours, 10);

    // Determine AM/PM and convert to 12-hour format
    const period = formattedHours >= 12 ? "PM" : "AM";
    formattedHours = formattedHours % 12 || 12; // Convert 0 to 12
    // Format the time as "hh:mm AM/PM"
    return `${formattedHours}:${minutes} ${period}`;
  };

  const renderContent = (text) => {
    const words = text.split(" ");

    if (words.length > 7) {
      // If text has more than 50 words, truncate it
      const truncatedText = words.slice(0, 7).join(" ") + "...";
      return truncatedText;
    }

    return text; // Return full text if within or equal to 50 words
  };
  // ====================================================
  const startIndex = (currentPage - 1) * itemsPerPage;
  // ====================================================
  return (
    <div className="content-wrapper user-page form-bar">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      {/* Filter start */}
      <div className="row">
        <div className="col-md-12">
          <div class="form-inline">
            <form
              onSubmit={SubmitSearch2_by_button}
              class="input-group mb-3 mr-sm-5"
            >
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search"
                maxlength="50"
                value={searchfilterValue}
                onChange={handleInputChangeSearch}
              />
              <div class="input-group-append">
                <button class="btn btn-sm btn-primary" type="submit">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </form>

            <form onSubmit={onSubmitByButton} class="form-inline">
              <div class="input-group mb-3 mr-sm-3">
                <label
                  for="startDate"
                  className="mr-3 d-flex align-items-center"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-sm mr-sm-2"
                  id="startDate"
                  placeholder="Enter StartDate"
                  name="startDate"
                  onKeyDown={(e) => e.preventDefault()}
                  onClick={(e) => e.target.showPicker()}
                  value={startDate}
                  onChange={handleChange}
                />
              </div>
              <div class="input-group mb-3 mr-sm-3">
                <label for="endDate" className="mr-3 d-flex align-items-center">
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-sm mr-sm-2"
                  id="endDate"
                  placeholder="Enter EndDate"
                  name="endDate"
                  onKeyDown={(e) => e.preventDefault()}
                  onClick={(e) => e.target.showPicker()}
                  value={endDate}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex">
                <button type="submit" class="btn btn-primary mb-3 mr-3">
                  Submit
                </button>
                <span className="btn btn-primary mb-3" onClick={ClearData}>
                  Clear
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* FIlter end */}
      {/* <div className="row">
        <div className="col-md-2">
          {" "}
          <input
            type="text"
            className="form-control-sm searchinput"
            placeholder="Search Event"
            value={filterValue}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-8">
          <div className="form-group">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-5 mb-2">
                  <div className="row">
                    <label
                      for=""
                      className="col-md-3 d-flex align-items-center"
                    >
                      Start Date
                    </label>
                    <div className="col-md-9">
                      <input
                        type="date"
                        className="form-control form-control-sm"
                        id="startDate"
                        placeholder="Enter startDate"
                        name="startDate"
                        value={startDate}
                        onChange={handleChange}
                      />{" "}
                    </div>
                  </div>
                </div>{" "}
                <div className="col-md-5 mb-2">
                  <div className="row">
                    <label
                      for=""
                      className="col-md-3 d-flex align-items-center"
                    >
                      End Date
                    </label>
                    <div className="col-md-9">
                      <input
                        type="date"
                        className="form-control form-control-sm"
                        id="endDate"
                        placeholder="Enter endDate"
                        name="endDate"
                        value={endDate}
                        onChange={handleChange}
                      />{" "}
                    </div>
                  </div>
                </div>{" "}
                <div className="col-md-2">
                  {" "}
                  <button className="btn btn-primary">Search</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-2">
          {" "}
          <p className="btn btn-primary" onClick={ClearData}>
            Clear
          </p>
        </div>
      </div> */}

      <div className="row">
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="card-title">View Pledge</h4>
                <Link to="/addpledge" className="btn btn-primary link_button">
                  Add Pledge
                </Link>
              </div>
              {/* <p className="card-description">Add className</p> */}
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      <th>Sr.no</th>
                      <th>Image</th>
                      <th>Name </th>
                      <th>No of pledges</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pledge?.map((val, index) => {
                      return (
                        <>
                          {/* {val?.notificationforSingleuser === "false" ? ( */}
                          <tr key={index}>
                            <td className="py-1"> {startIndex + index + 1}</td>
                            <td class="py-1">
                              {val?.presignedUrl1 ? (
                                <img src={val?.presignedUrl1} alt="img" />
                              ) : (
                                "No Image"
                              )}
                            </td>
                            <td>{renderContent(val?.title)}</td>

                            <td>
                              <Link
                                className="btn"
                                to={`/ViewAllPledgeUser/${val._id}`}
                              >
                                {val?.noofpledge}
                              </Link>
                            </td>
                            <td>
                              {dateFormat(`${val?.createdAt}`, "mmmm dS, yyyy")}
                            </td>
                            <td>
                              <Link
                                className="btn btn-primary "
                                to={`/viewsinglepledge/${val?._id}`}
                              >
                                <i class="fa-solid fa-eye menu-icon"></i>
                              </Link>
                              <Link
                                className="btn btn-primary ml-2"
                                to={`/editpledge/${val?._id}`}
                              >
                                <i class="fa-solid fa-edit menu-icon"></i>
                              </Link>
                              <Link className="btn btn-primary ml-2">
                                <i
                                  class="fa-solid fa-trash menu-icon "
                                  onClick={() => confirmDelete(val?._id)}
                                ></i>
                              </Link>
                            </td>
                          </tr>
                          {/* ): null } */}
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
                {pledge.length === 0 ? (
                  <p className="text-center mt-2 bold ">No Data Found</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};

export default ViewAllPledge;
