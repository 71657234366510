import React from "react";
// import Logo from "../../assets/image/logo.png";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import img1 from "../../assets/image/img1.png";
import img2 from "../../assets/image/img2.png";
import img3 from "../../assets/image/img3.png";
import img4 from "../../assets/image/img4.png";

import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const Dashboard = () => {
  // const permission = localStorage.getItem("permission");

  // const x = permission;
  // console.log(x);
  const [value, setValue] = useState({
    Notificationdata: "",
    pendingorder: "",
    user: "",
    activeuser: "",
    items: "",
    unpaidorder: "",
  });
  const [showloader, setShowLoader] = useState("none");

  const getPendingOrder = async () => {
    setShowLoader("block");
    const PendingData = await axios.get(`${URL}/admin/getDashboardDetail/`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });

    const PendingData1 = await PendingData;
    setValue(PendingData1?.data);
    setShowLoader("none");
  };

  useEffect(() => {
    getPendingOrder();
  }, []);

  const activeinactive = (ACTIVEDATA) => {
    sessionStorage.setItem("ACTIVEINACTIVE", ACTIVEDATA);
    sessionStorage.setItem(
      "Session_userTypeFilter","Active"
    );
  };
  const ClearDataForUserOnly = () => {
    
    sessionStorage.setItem("Session_searchFilter", '');
    sessionStorage.setItem("Session_startDateFilter", '');
    sessionStorage.setItem("Session_endDateFilter", '');
    sessionStorage.setItem(
      "Session_userTypeFilter","Normal"
    );
  }
  return (
    <>
      {/* main page start */}
      <div className="content-wrapper">
        <div className="loader-container " style={{ display: showloader }}>
          <img src={loaderimg} alt="" className="loaderImage" />
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin transparent">
            <div className="row">
              <div
                className="col-md-3 mb-4 stretch-card transparent"
                onClick={(e) => {
                  activeinactive("USERACTIVE");
                  ClearDataForUserOnly();
                }}
              >
                {" "}
                <div className="card card-tale">
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-3 m-auto mx-auto align-center">
                        <img
                          src={img1}
                          alt="loading"
                          className="dashboard_img"
                        />
                      </div>
                      <div className="col-9">
                        <Link to="/users/Normal" className="">
                          {" "}
                          <p className="fs-30 mb-2  grey-txt">{value?.user}</p>
                          <p className="black-txt">Total no of users</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-md-3 mb-4 stretch-card transparent"
                onClick={(e) => {
                  activeinactive("USERACTIVE");
                }}
              >
                <div className="card card-tale">
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-3 m-auto mx-auto align-center">
                        <img
                          src={img4}
                          alt="loading"
                          className="dashboard_img"
                        />
                      </div>
                      <div className="col-9">
                        <Link to="/users/Active" className="">
                          {" "}
                          <p className="fs-30 mb-2  grey-txt">
                            {value?.activeuser}
                          </p>
                          <p className=" black-txt">Total no of Active users</p>
                        </Link>
                      </div>
                    </div>
                    {/* <p>10.00% (30 days)</p> */}
                  </div>
                </div>
              </div>

              {/* <div className="col-md-3 mb-4 stretch-card transparent">
                {" "}
                <div className="card card-tale">
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-3 m-auto mx-auto align-center">
                        <img
                          src={img2}
                          alt="loading"
                          className="dashboard_img"
                        />
                      </div>
                      <div className="col-9">
                        <Link to="/viewevent" className="">
                          {" "}
                          <p className="fs-30 mb-2 grey-txt ">
                            {value?.events}
                          </p>
                          <p className="black-txt">Total no of Events</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-md-3 mb-4 stretch-card transparent">
                <div className="card card-tale">
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-3 m-auto mx-auto align-center">
                        <img
                          src={img3}
                          alt="loading"
                          className="dashboard_img"
                        />
                      </div>
                      <div className="col-9">
                        <Link to="/viewpledge" className="">
                          {" "}
                          <p className="fs-30 mb-2  grey-txt">
                            {value?.pleges}
                          </p>
                          <p className=" black-txt">Total no of pledge</p>
                        </Link>
                      </div>
                    </div>
                    {/* <p>10.00% (30 days)</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main page end */}
    </>
  );
};

export default Dashboard;
