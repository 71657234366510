import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import { useParams, Link } from "react-router-dom";

import "react-confirm-alert/src/react-confirm-alert.css";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
// import { Button } from "bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import Pagination from "../../components/Pagination";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const Users = () => {
  // Delete option start
    //==================================  Delete option SAtart====================================
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
    //==================================  Delete option End====================================
 const [xyzValueCall, setxyzValueCall] = useState();
  //  ==============================================================================
  // sessionStorage.setItem('Session_startDateFilter',);
  const Session_searchFilter = sessionStorage.getItem("Session_searchFilter");
  const Session_startDateFilter = sessionStorage.getItem(
    "Session_startDateFilter"
  );
  const Session_endDateFilter = sessionStorage.getItem("Session_endDateFilter");
  const Session_userTypeFilter = sessionStorage.getItem(
    "Session_userTypeFilter"
  );
  const Session_countryFilter = sessionStorage.getItem("Session_countryFilter");
  const Session_stateFilter = sessionStorage.getItem("Session_stateFilter");
  const Session_PlanactiveInactiveFilter = sessionStorage.getItem(
    "Session_PlanactiveInactiveFilter"
  );
  const Session_registeredStatusFilter = sessionStorage.getItem(
    "Session_registeredStatusFilter"
  );

  useEffect(() => {
    sessionStorage.setItem("Session_searchFilter", "");
    sessionStorage.setItem("Session_startDateFilter", "");
    sessionStorage.setItem("Session_endDateFilter", "");
    sessionStorage.setItem(
      "Session_userTypeFilter",
      userType ? (userType === "Active" ? "Active" : "Normal") : "Normal"
    );

    sessionStorage.setItem("Session_countryFilter", "");
    sessionStorage.setItem("Session_stateFilter", "");
    sessionStorage.setItem("Session_PlanactiveInactiveFilter", "");
    sessionStorage.setItem("Session_registeredStatusFilter", "");
  }, []);

  const { userType } = useParams();
  const [Users, setUsers] = useState([]);
  const [user_Redirect_Type, setuser_Redirect_Type] = useState(
    userType ? userType : "Normal"
  ); //Normal,Active
  const [showloader, setShowLoader] = useState("none");
  const [startDate, setstartDate] = useState("0000-00-00");
  const [endDate, setendDate] = useState("0000-00-00");

  // Function to filter customers based on input field value ==========================================
  const itemsPerPage = 10; // Set the number of items per page

  const [searchfilterValue, setSearchFilterValue] = useState("");
  const [filtertype, setFilterType] = useState("DEFAULT");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [initialFetchDone, setInitialFetchDone] = useState(false); // Track initial fetch

  const [country, setCountry] = useState([]);
  const [countryname, setCountryname] = useState(null);
  const [state, setState] = useState([]);
  const [statename, setStatename] = useState(null);
  const [PlanactiveInactiveStatus1, setPlanactiveInactiveStatus1] =
    useState("");
  const [registeredStatus1, setregisteredStatus1] = useState(null);
  // ===============================================================
  // ======================CITY AND COUNTRY SECTION=========================================
  // ===============================================================
  const handleChangeCountry = (e) => {
    // const name = e.target.name;
    const value = e.target.value;

    if(value === "xyz") {
      sessionStorage.setItem("Session_countryFilter", '');
      sessionStorage.setItem("Session_stateFilter", '');
      setxyzValueCall("dfsdfsd");
    }else{
    const selectedText = e.target.options[e.target.selectedIndex].text;
    setCountryname(selectedText);
    sessionStorage.setItem("Session_countryFilter", selectedText);
    getstateByCountryId(value);
    setxyzValueCall(value);
  }
    // AllFilterCall({
    //   countryprop: selectedText,
    //   stateprop: statename,
    //   PlanactiveInactiveStatus: PlanactiveInactiveStatus1,
    //   registeredStatus: registeredStatus1,
    // });
  };
  const getCountry = async () => {
    const getCountryData = await axios.get(`${URL}/admin/getAllCountry`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getCountryData;
    setCountry(Data1?.data?.data);
  };

  const getstateByCountryId = async (countryid) => {
    const getStateData = await axios.get(
      `${URL}/admin/getStateByCountryId/${countryid}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Data1 = await getStateData;
    setState(Data1?.data?.data);
  };
  const handleStateChange = (e) => {
   
     const value = e.target.value;
    //  alert(e.target.selectedIndex);
    if(value === "xyz") {
      sessionStorage.setItem("Session_stateFilter", '');
      setxyzValueCall("dfsdfddsd");
    }else{
    const selectedText = e.target.options[e.target.selectedIndex].text;
    setStatename(selectedText);
    sessionStorage.setItem("Session_stateFilter", selectedText);
    setxyzValueCall(`1dsffd${e.target.selectedIndex}`);
    }
    // onSubmitByButtonBothWork2({
    //   countryprop: countryname,
    //   stateprop: selectedText,
    //   PlanactiveInactiveStatus: PlanactiveInactiveStatus1,
    //   registeredStatus: registeredStatus1,
    // });
  };
  const handlePlanactiveInactiveStatusChange = (e) => {
    // const name = e.target.name;
    const value = e.target.value;
    sessionStorage.setItem("Session_PlanactiveInactiveFilter", value);
    setPlanactiveInactiveStatus1(value);
    setxyzValueCall(value);
    // onSubmitByButtonBothWork2({
    //   countryprop: countryname,
    //   stateprop: statename,
    //   PlanactiveInactiveStatus: value,
    //   registeredStatus: registeredStatus1,
    // });
  };
  // ===============================================================
  // ======================CITY AND COUNTRY SECTION End =========================================
  // ================================================================================================================================
  //=================================================================================================================================
  // ================      On Change Section End ======================
  // ==========================================================
  const handleregisteredStatus1Change = (e) => {
    // const name = e.target.name;
    const value = e.target.value;

    // alert("Active Inactive");
    //  alert(value);
    setregisteredStatus1(value);
    setxyzValueCall(value);
    sessionStorage.setItem("Session_registeredStatusFilter", value);
    if (value === 2) {
      sessionStorage.setItem("Session_PlanactiveInactiveFilter", "InActive");
    }
    // onSubmitByButtonBothWork2({
    //   countryprop: countryname,
    //   stateprop: statename,
    //   PlanactiveInactiveStatus: PlanactiveInactiveStatus1,
    //   registeredStatus: value,
    // });
  };
  // Search On  Change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // Search On  Change
  const handleInputChangeSearch = (e) => {
    setSearchFilterValue(e.target.value);
    sessionStorage.setItem("Session_searchFilter", e.target.value);
  };
  //===========================================================
  //  ================On Change Section End ======================
  // ==========================================================
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "startDate") {
      // if (new Date(value) > new Date(endDate)) {
      //   toast.error("Start date cannot be later than end date.");
      // } else {
      setstartDate(value);
      sessionStorage.setItem("Session_startDateFilter", value);

      // }
    }

    if (name === "endDate") {
      if (!startDate) {
        toast.error("Please Select a Start Date First");
        return;
      }
      if (new Date(value) < new Date(startDate)) {
        toast.error("End date cannot be earlier than start date.");
      } else {
        setendDate(value);
        sessionStorage.setItem("Session_endDateFilter", value);
      }
    }
  };
  // ================================================================
  // =========================== ALL FILTER WORK HERE ===============
  // ================================================================
  useEffect(() => {
    // alert(PlanactiveInactiveStatus1);
    const AllFilterCall = async () =>
      // countryprop,
      // stateprop,
      // PlanactiveInactiveStatus,
      // registeredStatus
      {
        setShowLoader("block");
        // Check if only one date is selected
        let getdata;
        let apiUrl = `${URL}/users/MultiplefilterUser?FILTERTYPE=COMBINE&page=${currentPage}&limit=${itemsPerPage}&USERTYPE=${
          Session_userTypeFilter
            ? Session_userTypeFilter
            : Session_userTypeFilter === null
            ? userType
            : "Normal"
        }`;
        //   Session_userTypeFilter
        //     ? Session_userTypeFilter === "Active"
        //       ? "Active"
        //       : Session_userTypeFilter === "InActive"
        //       ? "Inactive"
        //       : "Normal"
        //     : "Normal"
        // }`;
        if (
          (Session_startDateFilter !== "" && Session_endDateFilter === "") ||
          (Session_startDateFilter === "" && Session_endDateFilter !== "")
        ) {
          toast.error("Please fill both Start Date and End Date");
          setShowLoader("none");
          return;
        }
        if (Session_startDateFilter !== "" && Session_endDateFilter !== "") {
          if (
            new Date(Session_endDateFilter) < new Date(Session_startDateFilter)
          ) {
            toast.error("End Date cannot be less than Start Date");
            setShowLoader("none");
            return;
          }
        }
        setFilterType("COMBINE");
        // sessionStorage.setItem("Session_userTypeFilter", "COMBINE");

        // // Condition 1 =========================
        if (
          Session_startDateFilter !== "" &&
          Session_startDateFilter != null &&
          Session_endDateFilter !== "" &&
          Session_endDateFilter != null
        ) {
          apiUrl += `&startDate=${Session_startDateFilter}&endDate=${Session_endDateFilter}`;
        }
        if (
          Session_PlanactiveInactiveFilter !== null &&
          Session_PlanactiveInactiveFilter !== ""
        ) {
          apiUrl += `&PlanactiveInactive=${
            Session_PlanactiveInactiveFilter !== null
              ? Session_PlanactiveInactiveFilter
              : Session_PlanactiveInactiveFilter
              ? Session_PlanactiveInactiveFilter
              : Session_userTypeFilter
              ? Session_userTypeFilter === "Normal"
                ? ""
                : Session_userTypeFilter === "Active"
                ? "Active"
                : Session_userTypeFilter === "InActive"
                ? "InActive"
                : ""
              : ""
          }`;
        }
        if (Session_searchFilter !== "" && Session_searchFilter !== null) {
          apiUrl += `&SEARCH=${Session_searchFilter}`;
        }
        if (Session_countryFilter !== null && Session_countryFilter !== "") {
          apiUrl += `&country=${
            Session_countryFilter
              ? Session_countryFilter
              : Session_countryFilter
          }`;
        }
        if (Session_stateFilter !== null && Session_stateFilter !== "") {
          apiUrl += `&state=${
            Session_stateFilter ? Session_stateFilter : Session_stateFilter
          }`;
        }
        if (
          Session_registeredStatusFilter !== null &&
          Session_registeredStatusFilter !== ""
        ) {
          apiUrl += `&registeredstatus=${
            Session_registeredStatusFilter
              ? Session_registeredStatusFilter
              : Session_registeredStatusFilter
          }`;
        }

        getdata = await axios.get(apiUrl, {
          headers: { "X-API-Key": APP_X_API_KEY },
        });
        // }
        const Singledata = await getdata;
        const Data1 = await Singledata;
        setUsers(Data1?.data?.data);
        setTotalPages(Data1?.data.totalPages);
        setShowLoader("none");
        if (currentPage > Data1?.data.totalPages) {
          setCurrentPage(1);
        }
      };
    AllFilterCall();
  }, [currentPage, xyzValueCall]);

  const onSubmitByButtonBothWork2 = async () => {};

  // ====================================================================================
  const confirmDelete = (id) => {
    confirmAlert({
      message: "Are you sure you want to delete this Data.",
      buttons: [
        {
          label: "Yes",
          onClick: () => DeleteData(id),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const DeleteData = async (Linkid) => {
    await axios
      .put(`${URL}/users/deleteUserById/${Linkid}`)
      .then((result) => {
        toast.success("User Delete Successfully");
        // getAllData();
      })
      .catch((error) => {
        alert("service error");
        // console.log(error);
      });
  };

  const ClearData = () => {
    setSearchFilterValue("");
    setFilterType("DEFAULT");
    //  AllFilterCall({"Search_Dy":null});
    setSearchFilterValue(null);
    setstartDate("");
    setendDate("");
    setstartDate("0000-00-00");
    setendDate("0000-00-00");
    setCurrentPage(1);
    setStatename(null);
    setState([""]);
    setCountryname(null);
    setPlanactiveInactiveStatus1(null);
    setregisteredStatus1(null);
    setxyzValueCall(4);
    sessionStorage.setItem("Session_searchFilter", "");
    sessionStorage.setItem("Session_startDateFilter", "");
    sessionStorage.setItem("Session_endDateFilter", "");
    sessionStorage.setItem("Session_userTypeFilter", "Normal");
    // 02/07/2024===
    // sessionStorage.setItem(
    //   "Session_userTypeFilter",
    //   userType ? userType  : "Normal"
    // );
    sessionStorage.setItem("Session_countryFilter", "");
    sessionStorage.setItem("Session_stateFilter", "");
    sessionStorage.setItem("Session_PlanactiveInactiveFilter", "");
    sessionStorage.setItem("Session_registeredStatusFilter", "");
  };
  // ====================================================
  const startIndex = (currentPage - 1) * itemsPerPage;
  // ====================================================

  useEffect(() => {
    getCountry();
  }, []);
   //==================================  Delete option Start====================================
   const handleCheckboxChange = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedUsers([]);
    } else {
      const allUserIds = Users.map((user) => user._id);
      setSelectedUsers(allUserIds);
    }
    setSelectAll(!selectAll);
  };
  const handleBulkDelete = async () => {
    confirmAlert({
      message: "Are you sure you want to delete this Data.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleBulkDelete1(),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };
  const handleBulkDelete1 = async () => {
   
    // Call your bulk delete API here with selectedUsers
    console.log('Deleting users with IDs:', selectedUsers);
    try {
  const result=  await axios.put(`${URL}/users/deleteBulkUserById`,{bulkid:selectedUsers});
    if (result.status === 200) {
      toast.success("User Delete Successfully");
         setxyzValueCall(selectedUsers[0]);
        setSelectedUsers([]);
      // .then((result) => {
      //   toast.success("User Delete Successfully");
      //   // getAllData();
      //   setxyzValueCall(selectedUsers[0]);
      //   setSelectedUsers([]);
      // });
}
      else {
        throw new Error('Unexpected response status: ' + result.status);
      }
    } catch (error) {
      alert("Service error");
      console.error(error); // Log error to the console for debugging
    }
  };
  useEffect(() => {
    if (selectedUsers.length === Users.length && Users.length > 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedUsers, Users]);
  //==================================  Delete option End====================================
  
  return (
    <div className="content-wrapper user-page form-bar">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>

      {/* Filter start 2*/}
      <div className="row">
        <div className="col-md-12">
          <div className="form-inline">
            <form className="form-inline">
              <div className="input-group mb-3 mr-sm-5">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search"
                  maxLength="50"
                  value={Session_searchFilter ? Session_searchFilter : ""}
                  onChange={handleInputChangeSearch}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-sm btn-primary"
                    type="button"
                    onClick={() => {
                      setxyzValueCall(5);
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="input-group mb-3 mr-sm-3">
                <label
                  htmlFor="startDate"
                  className="mr-3 d-flex align-items-center"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-sm mr-sm-2"
                  id="startDate"
                  placeholder="Enter StartDate"
                  name="startDate"
                  onKeyDown={(e) => e.preventDefault()}
                  onClick={(e) => e.target.showPicker()}
                  value={startDate}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group mb-3 mr-sm-3">
                <label
                  htmlFor="endDate"
                  className="mr-3 d-flex align-items-center"
                >
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-sm mr-sm-2"
                  id="endDate"
                  placeholder="Enter EndDate"
                  name="endDate"
                  onKeyDown={(e) => e.preventDefault()}
                  onClick={(e) => e.target.showPicker()}
                  value={endDate}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-primary mb-3 mr-3"
                  onClick={() => {
                    setxyzValueCall(6);
                  }}
                  // onClick={onSubmitByButtonBothWork}
                >
                  Submit
                </button>
                <span className="btn btn-primary mb-3" onClick={ClearData}>
                  Clear
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="form-inline">
            <form className="form-inline">
              <div className="input-group mb-3 mr-sm-3">
                <label
                  htmlFor="startDate"
                  className="mr-3 d-flex align-items-center"
                >
                  Country
                </label>
                <select
                  className="form-control"
                  id="f1"
                  name="countryid"
                  onChange={handleChangeCountry}
                  // value={countryname}
                >
                  <option
                    selected={
                      Session_countryFilter === "" &&
                      Session_countryFilter === null
                        ? "selected"
                        : null
                    }
                    value="xyz"
                  >
                    Select
                  </option>
                  {country.map((ctry, index) => {
                    return (
                      <option
                        value={ctry._id}
                        selected={
                          Session_countryFilter === ctry?.name
                            ? "selected"
                            : null
                        }
                        key={index}
                      >
                        {ctry.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {Session_countryFilter != "" ? (
                <div className="input-group mb-3 mr-sm-3">
                  <label
                    htmlFor="startDate"
                    className="mr-3 d-flex align-items-center"
                  >
                    State
                  </label>
                  <select
                    className="form-control"
                    id="f1"
                    name="stateid"
                    onChange={handleStateChange}
                    // value={values?.stateid}
                  >
                    <option
                      selected={
                        Session_stateFilter === "" &&
                        Session_stateFilter === null
                          ? "selected"
                          : null
                      }
                       value="xyz"
                    >
                      Select
                    </option>
                    {state.map((stat, index) => {
                      return (
                        <option
                          value={stat._id}
                          selected={
                            Session_stateFilter === stat?.name
                              ? "selected"
                              : null
                          }
                          key={index}
                        >
                          {stat.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}

              <div className="input-group mb-3 mr-sm-3">
                <label
                  htmlFor="startDate"
                  className="mr-3 d-flex align-items-center"
                >
                  Registered Status
                </label>
                <select
                  className="form-control"
                  id="f1"
                  name="registeredStatus1"
                  onChange={handleregisteredStatus1Change}
                  value={Session_registeredStatusFilter}
                >
                  <option
                    value=""
                    selected={
                      Session_registeredStatusFilter === "" &&
                      Session_registeredStatusFilter === null
                        ? "selected"
                        : null
                    }
                  >
                    Select
                  </option>

                  <option
                    value="1"
                    key="1"
                    selected={
                      Session_registeredStatusFilter === "1" ? "selected" : null
                    }
                  >
                    Complete Registered
                  </option>
                  <option
                    value="2"
                    key="2"
                    selected={
                      Session_registeredStatusFilter === "2" ? "selected" : null
                    }
                  >
                    Partially Registered
                  </option>
                </select>
              </div>
              {Session_registeredStatusFilter == 1 ? (
                <div className="input-group mb-3 mr-sm-3">
                  <label
                    htmlFor="startDate"
                    className="mr-3 d-flex align-items-center"
                  >
                    Plan Status
                  </label>
                  <select
                    className="form-control"
                    id="f1"
                    // name="handlePlanactiveInactiveStatusChange"
                    onChange={handlePlanactiveInactiveStatusChange}
                    // value={PlanactiveInactiveStatus1 || ""}
                  >
                    <option
                      value=""
                      selected={
                        Session_PlanactiveInactiveFilter === "" &&
                        PlanactiveInactiveStatus1 === null
                          ? "selected"
                          : null
                      }
                    >
                      Select
                    </option>

                    <option
                      value="Active"
                      key="1"
                      selected={
                        Session_PlanactiveInactiveFilter === "1"
                          ? "selected"
                          : null
                      }
                    >
                      Active
                    </option>
                    <option
                      value="InActive"
                      key="2"
                      selected={
                        Session_PlanactiveInactiveFilter === "2"
                          ? "selected"
                          : null
                      }
                    >
                      InActive
                    </option>
                  </select>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-12">
          <div className="form-inline">
            <form
              onSubmit={SubmitSearch2_by_button}
              className="input-group mb-3 mr-sm-5"
            >
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search"
                maxLength="50"
                value={searchfilterValue}
                onChange={handleInputChangeSearch}
              />
              <div className="input-group-append">
                <button className="btn btn-sm btn-primary" type="submit">
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </form>

            <form onSubmit={onSubmitByButton} className="form-inline">
              <div className="input-group mb-3 mr-sm-3">
                <label
                  htmlFor="startDate"
                  className="mr-3 d-flex align-items-center"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-sm mr-sm-2"
                  id="startDate"
                  placeholder="Enter StartDate"
                  name="startDate"
                  onKeyDown={(e) => e.preventDefault()}
                  onClick={(e) => e.target.showPicker()}
                  value={startDate}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group mb-3 mr-sm-3">
                <label
                  htmlFor="endDate"
                  className="mr-3 d-flex align-items-center"
                >
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control form-control-sm mr-sm-2"
                  id="endDate"
                  placeholder="Enter EndDate"
                  name="endDate"
                  onKeyDown={(e) => e.preventDefault()}
                  onClick={(e) => e.target.showPicker()}
                  value={endDate}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex">
                <button type="submit" className="btn btn-primary mb-3 mr-3">
                  Submit
                </button>
                <span className="btn btn-primary mb-3" onClick={ClearData}>
                  Clear
                </span>
              </div>
            </form>
          </div>
        </div>
      </div> */}
      {/* FIlter end */}
      <div className="row">
        <div className="col-lg-12 grid-margin mt-3 stretch-card">
          <div className="card">
            <div className="card-body">
              {/* <Link to="/addUsers" className="btn btn-primary">
                Add Users
              </Link> */}
              {/* <p className="card-description">Add className</p> */}
              <button onClick={handleBulkDelete} disabled={selectedUsers.length === 0}  className="btn btn-primary mb-3 mr-3">
        Delete Selected
      </button>

              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                    <th>     <input
                   type="checkbox"
                   checked={selectAll}
                   onChange={handleSelectAllChange}
                 /></th>
                      <th>S.no</th>
                      
                      {/* <th>IMAGE</th> */}

                      <th>Date</th>
                      <th>User Name</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Country</th>
                      <th>State</th>
                      <th>Subscription</th>
                      <th>Registered</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  {showloader === "none" ? (
                    <tbody>
                      {Users?.map((val, index) => {
                        return (
                          <>
                            {/* {val?.UsersforSingleuser === "false" ? ( */}
                            
                            <tr key={index}>
                            <td >
                               
                               <input
                   type="checkbox"
                   checked={selectedUsers.includes(val._id)}
                   onChange={() => handleCheckboxChange(val._id)}
                 />
                               </td>
                              <td className="py-1">
                                {" "}
                                {startIndex + index + 1}{" "}
                              </td>
                             
                              {/* <td className="py-1">
                          <img src={val?.imageurl} alt="img" />
                        </td> */}
                              {/* <td>
                              <Link
                                className="btn"
                                to={`/singleusers_dailyexc/${val._id}`}
                                style={{color: "#6a4dac"}}
                              >
                                <i className="fa-solid fa-eye menu-icon"></i>
                              </Link>
                            </td> */}
                              <td>
                                {dateFormat(
                                  `${val?.createdAt}`,
                                  "mmmm dS, yyyy"
                                )}
                              </td>
                              <td>{val?.name ? val?.name : "Pending"}</td>
                              <td>{val?.mobile}</td>
                              <td>{val?.email ? val?.email : "-"}</td>
                              <td>{val?.countryid?.name}</td>
                              <td>{val?.stateid?.name}</td>
                              <td>
                                {/* {val?.planid?.title
                                ? val?.planid?.title
                                : "Pending"} */}
                                {val?.freetrialstatus === true
                                  ? "Free Active"
                                  : null}
                                {}
                                {val?.freetrialstatus === false
                                  ? val?.subscriptionexpirystatus === true
                                    ? "Plan Inactive"
                                    : val?.expiry_date
                                    ? new Date(val.expiry_date)
                                        .toISOString()
                                        .split("T")[0] <
                                      new Date().toISOString().split("T")[0]
                                      ? "Plan Inactive"
                                      : "Plan Active"
                                    : "Plan Inactive"
                                  : null}
                                {/* {val?.freetrialstatus === false ? val?.subscriptionexpirystatus === false ? val?.full_registered_date === null ? 'Plan Inactive ': 'Plan Active' : "Plan Expired" : null } */}
                                {/* ? val?.planid?.title
                                : "Pending"} */}
                              </td>
                              <td>{val?.email ? "Yes" : "No"}</td>

                              <td>
                                <Link
                                  className="btn btn-primary"
                                  to={`/singleusers/${val._id}/${user_Redirect_Type}`}
                                >
                                  <i className="fa-solid fa-eye menu-icon"></i>
                                </Link>{" "}
                                <Link
                                  className="btn btn-primary ml-2"
                                  to={`/editsingleusers/${val._id}/${user_Redirect_Type}`}
                                >
                                  <i className="fa-solid fa-edit menu-icon"></i>
                                </Link>
                                {/* <i
                                  className="fa-solid fa-trash menu-icon"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => confirmDelete(val._id)}
                                ></i> */}
                              </td>
                            </tr>
                            {/* ): null } */}
                          </>
                        );
                      })}
                    </tbody>
                  ) : (
                    <p className="text-center mt-2 bold ">
                      Please wait data Loading ...
                    </p>
                  )}
                </table>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
                {Users.length === 0 ? (
                  <p className="text-center mt-2 bold ">No Data Found</p>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* // end */}
      </div>
    </div>
  );
};

export default Users;
