import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
// import WhatsappSend from "../../utils/WhatsappSend";

const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const initialstate = {
  title: "",
  description: "",
  ios_plan_id: "",
  google_plan_id: "",
  android_price: "",
  ios_price: "",
  fordays: 1,
};

const AddSubscriptionPlan = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialstate);

  const [showloader, setShowLoader] = useState("none");

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************

  const CreateData = async () => {
    setShowLoader("block");
    const url = `${URL}/admin/addSubscriptionPlan`;

    axios
      .post(
        url,
        {
          title: values?.title,
          description: values?.description,
          ios_plan_id: values?.ios_plan_id,
          google_plan_id: values?.google_plan_id,
          android_price: values?.android_price,
          ios_price: values?.ios_price,
          fordays: values?.fordays,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Subscription plan Add Successfully");
        navigate("/subscriptionplan");
        setShowLoader("none");
      })
      .catch((error) => {
        alert("service error");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      title,
      description,
      ios_plan_id,
      google_plan_id,
      android_price,
      ios_price,fordays
    } = values;

    if (
      !title ||
      !description ||
      !ios_plan_id ||
      !google_plan_id ||
      !android_price ||
      !fordays ||
      !ios_price
    ) {
      toast.error("Please fill out all fields");
      return;
    }
    CreateData(values);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };
  // const handleImageChange = async (e) => {
  //   setShowLoader("block");
  //   const formData = new FormData();
  //   formData.append("file", e.target.files[0]);
  //   var requestOptions = {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   };
  //   const fetchdata = axios.post(
  //     `${URL}/admin/imageUpload_Use/imageUpload`,
  //     formData,
  //     requestOptions
  //   );
  //   const response = await fetchdata;
  //   if (response.status === 200) {
  //     //toast.success("Data Uploaded Successfully...");
  //     setShowLoader("none");
  //     //seturl(response?.data?.url);
  //     setValues({ ...values, imageurl: response?.data?.url });
  //   } else {
  //     setShowLoader("none");
  //     toast.error("Fail To Load...");
  //   }
  //   setShowLoader("none");
  // };

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link to="/subscriptionplan" className="btn btn-primary mb-2">
        {" "}
        Back{" "}
      </Link>

      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Subscription Plan</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">
                        Subscription name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="Enter Subscription name"
                        name="title"
                        value={values?.title}
                        maxlength="50"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter Description"
                        name="description"
                        value={values?.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Ios plan ID </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter Ios plan ID"
                        name="ios_plan_id"
                        maxlength="50"
                        value={values?.ios_plan_id}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Google plan ID</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter Google plan ID"
                        name="google_plan_id"
                        maxlength="50"
                        value={values?.google_plan_id}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Android Price </label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter Android Price"
                        name="android_price"
                        maxlength="50"
                         min="1"
                        value={values?.android_price}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">IOS Price </label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter iOS Price "
                        name="ios_price"
                        maxlength="50"
                         min="1"
                        value={values?.ios_price}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">For Days </label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter Days "
                        name="fordays"
                        min="1"
                        value={values?.fordays}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default AddSubscriptionPlan;
