import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";

// import { confirmAlert } from "react-confirm-alert"; // Import
// import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { Context } from '@ckeditor/ckeditor5-core';
// import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
// import { Essentials } from '@ckeditor/ckeditor5-essentials';
// import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import Editor from "../../components/Editor";
// import DefaultImg from "../../assets/image/defaultimages/default.png";
import loaderimg from "../../assets/image/loader.gif";
import Modal from "../../components/Modal";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const initialState = {
  title: "",
  description: "",
};

const ViewDownload = () => {
  const [values, setValues] = useState(initialState);
  const [page, setPages] = useState([]);
  const [description, setDescription] = useState("");
  const [isEditClick, setisEditClick] = useState(false);
  const [editId, seteditId] = useState("");
  const [showloader, setShowLoader] = useState("none");
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data2, setData] = useState("");

  const [modalactive, setModalActive] = useState("none"); //model
  const [details, setdetails] = useState(""); //model
  const itemsPerPage = 10; // Set the number of items per page

  // const [searchfilterValue, setSearchFilterValue] = useState("");
  const [filtertype, setFilterType] = useState("DEFAULT");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [initialFetchDone, setInitialFetchDone] = useState(false); // Track initial fetch

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  // const setModalShow = (details) => {
  //   setModalActive("block");
  //   setdetails(details);

  // };

  const setModalHide = () => {
    setModalActive("none");
    setdetails("");
  };

  useEffect(() => {
    setEditorLoaded(true);
  }, [isEditClick, editId]);

  // const handleShow = (data) => {
  //   setDescription(data);
  // };

  const AddData = async (userData) => {
    const formData = new FormData();
    // if (values.image !== "") {
    //   formData.append("file", values.image);
    // }

    formData.append("title", values?.title);
    formData.append("description", values?.description);

    await axios
      .post(
        `${URL}/admin/createDownload`,
        { title: values?.title, description: values?.description },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Data Added Successfully ");
        getAllData();
        setValues({ title: "", description: "" });
        setData("");
      })
      .catch((error) => {
        alert("service error");
      });
  };
  const UpdateData = async (userData) => {
    // const config = {
    //   headers: { "content-type": "multipart/form-data" },
    // };
    // const formData = new FormData();
    // if (values.image !== "") {
    //   formData.append("file", values.image);
    // }
    // formData.append("title", values.title);
    // formData.append("description", data2);
    await axios
      .patch(
        `${URL}/admin/updateDownload/${editId}`,
        { title: values?.title, description: values?.description },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Data Added Successfully");
        getAllData();
        setValues({ title: "", description: "" });
        setData("");
        setisEditClick(false);
        seteditId("");
      })
      .catch((error) => {
        alert("service error");
      });
  };

  const clear = () => {
    setDescription("");
    setisEditClick(false);
    seteditId("");
    setValues({ title: "", description: "" });
    setData("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { title, description } = values;

    if (!title || !description) {
      toast.error("Please fill out all fields");
      return;
    }
    if (isEditClick) {
      UpdateData(values);
    } else {
      AddData(values);
    }
  };
  // const handleFileChange = (e) => {
  //   const name = "file";
  //   const value = e.target.files[0];
  //   setValues({ ...values, [name]: value });
  //   const name1 = "image";
  //   const value1 = e.target.files[0];
  //   setValues({ ...values, [name1]: value1 });
  // };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = name === "file" ? e.target.files[0] : e.target.value;
    setValues({ ...values, [name]: value });
  };

  // const getAllData = async () => {
  //   setShowLoader("block");
  //   const getData = await axios.get(`${URL}/admin/getallDownload`);
  //   const Data1 = await getData;
  //   setPages(Data1?.data?.data);
  //   setShowLoader("none");

  // };
  const getAllData = async () => {
    setShowLoader("block");
    setFilterType("DEFAULT");
    const getData = await axios.get(
      `${URL}/admin/MultiplefilterDownload?FILTERTYPE=${filtertype}&page=${currentPage}&limit=${itemsPerPage}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const Data1 = await getData;
    setPages(Data1?.data?.data);
    setTotalPages(Data1?.data.totalPages);
    setShowLoader("none");
  };
  useEffect(() => {
    getAllData();
  }, []);
  useEffect(() => {
    if (filtertype === "DEFAULT") {
      // eslint-disable-next-line
      getAllData(); // eslint-disable-next-line
    }
  }, [currentPage]);

  const editpage = async (editid) => {
    setDescription("");
    const SingleData = await axios.get(
      `${URL}/admin/getDownloadById/${editid}`,
      { headers: { "X-API-Key": APP_X_API_KEY } }
    );
    const SingleData1 = await SingleData;
    setisEditClick(true);
    seteditId(editid);
    setValues({
      title: SingleData1?.data?.data?.title,
      description: SingleData1?.data?.data.description,
    });
    // setData(SingleData1?.data?.data?.description);
  };

  const renderContent = (text) => {
    const words = text.split(" ");

    if (words.length > 7) {
      // If text has more than 50 words, truncate it
      const truncatedText = words.slice(0, 7).join(" ") + "...";
      return truncatedText;
    }

    return text; // Return full text if within or equal to 50 words
  };
  // ====================================================
  const startIndex = (currentPage - 1) * itemsPerPage;
  // ====================================================

  return (
    <div className="content-wrapper">
      <Modal
        modalactive={modalactive}
        title="Feedback Details"
        description={details}
        setModalHide={setModalHide}
      />

      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        {/* first */}

        <div className="col-lg-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Download</h4>
              <p className="card-description">Total Label : {page?.length}</p>
              <div className="table-responsive" id="collapse1">
                <table className="table" id="datatable">
                  <thead>
                    <tr>
                      <th>Sr.no</th>
                      <th>Label Title</th>
                      {/* <th>IMAGE</th> */}
                      <th className="txt-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {page?.map((pages, index) => {
                      return (
                        <tr key={index}>
                          <td>{startIndex + index + 1}</td>
                          <td>{renderContent(pages?.title)}</td>
                          {/* <td class="py-1">
                            {pages?.image ? (
                              <img src={pages?.image} alt="images" />
                            ) : (
                              <img src={DefaultImg} alt="images"></img>
                            )}
                          </td> */}

                          <td className="txt-right">
                            {/* <i
                              class="fa-solid fa-eye menu-icon"
                              onClick={() => setModalShow(pages?.description)}
                            ></i> */}

                            <Link>
                              {" "}
                              <i
                                class="fa-solid fa-edit menu-icon"
                                style={{ marginLeft: "10px" }}
                                onClick={() => editpage(pages?._id)}
                              ></i>
                            </Link>
                            <Link to={`/viewadownloadLink/${pages?._id}`}>
                              {" "}
                              <i
                                class="fa-solid fa-add menu-icon"
                                style={{ marginLeft: "10px" }}
                              ></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>

        {/* first */}

        {/*ADD FORM SECOND FORM */}
        {/* <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                {isEditClick ? "EDIT DATA" : "ADD DATA"}
              </h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">TITLE</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="TITLE"
                    name="title"
                    value={values?.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">DESCRIPTION</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="DESCRIPTION"
                    name="description"
                    value={values?.description}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">IMAGE</label>
                  <input
                    type="file"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="page unit"
                    name="file"
                    onChange={handleFileChange}
                  />
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div> */}
        {/*ADD FORM SECOND FORM */}

        {description ? (
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">DESCRIPTION</h4>
                <button
                  onClick={() => clear()}
                  className="btn btn-primary mr-2"
                >
                  Clear
                </button>
                <p className="card-description"></p>
                {description}
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  {isEditClick ? "Edit Label" : "Add Label"}
                </h4>
                <p className="card-description"></p>
                <form className="forms-sample" onSubmit={onSubmit}>
                  <div className="form-group">
                    <label htmlFor="exampleInputUsername1">
                      Label Title (Black Label)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputUsername1"
                      placeholder="Label Title"
                      name="title"
                      maxLength={40}
                      value={values?.title}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputUsername1">
                      Label Description (Red Label)
                    </label>

                    {/* <Editor
                      name="description"
                      value={data2}
                      //  onChange={handleChange}
                      onChange={(data3) => {
                        setData(data3);
                        //  setValues(data);
                      }}
                      editorLoaded={editorLoaded}
                    /> */}
                    <textarea
                      type="text"
                      rows="8"
                      className="form-control"
                      id="exampleInputUsername1"
                      placeholder="DESCRIPTION"
                      name="description"
                      value={values?.description}
                      onChange={handleChange}
                    />
                  </div>

                  {/* <div className="form-group">
                    <label htmlFor="exampleInputEmail1">IMAGE</label>
                    <input
                      type="file"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="page unit"
                      name="file"
                      onChange={handleFileChange}
                    />
                  </div> */}

                  <button type="submit" className="btn btn-primary mr-2">
                    Submit
                  </button>
                  {isEditClick ? (
                    <button
                      onClick={() => clear()}
                      className="btn btn-primary mr-2"
                    >
                      Clear
                    </button>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewDownload;
