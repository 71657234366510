import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
// import WhatsappSend from "../../utils/WhatsappSend";
import Multiselect from "multiselect-react-dropdown";
const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const Setting_Subscription = () => {
  const navigate = useNavigate();
  const [usertype, setusertype] = useState("Social Worker");
  const [usertype2, setusertype2] = useState(0);
  const [subscriptionplanid, setsubscriptionplanid] = useState("");
  const [noofmonth, setnoofmonth] = useState(1);
  const [noofmonth2, setnoofmonth2] = useState(12);

  //   const [values, setValues] = useState({
  //     usertype: "Social Worker",
  //     subscriptionplanid: "",
  //     noofmonth: 1,
  // });

  const [subscription, setsubscription] = useState([]);

  const [showloader, setShowLoader] = useState("none");
  const [user, setUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const options = user.map((u) => ({
    id: u._id,
    name: `${u.name} ${u.socialfreeplanUsedStatus ? "**Already Give" : ""}`,
    // label: `${u.name} (${u.socialfreeplanUsedStatus ? '**Already Give)' : null})`,
    socialfreeplanUsedStatus: u.socialfreeplanUsedStatus,
  }));
  const handleSelect = (selectedList) => {
    setSelectedUser(selectedList);
  };

  const handleRemove = (selectedList) => {
    setSelectedUser(selectedList);
  };
  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************

  const CreateData = async () => {
    setShowLoader("block");
    const url = `${URL}/admin/UpdateSubcriptionSetting`;

    axios
      .patch(
        url,
        {
          usertype: usertype,
          subscriptionplanid: subscriptionplanid,
          noofmonth: noofmonth,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Setting Update Successfully");
        navigate("/subscriptionplan");
        setShowLoader("none");
      })
      .catch((error) => {
        setShowLoader("none");
        alert("service error");
      });
  };
  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getAllSubscriptionPlan`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getData;
    setsubscription(Data1?.data?.data);
    setShowLoader("none");
  };
  const getAllUserListData = async (value) => {
    setShowLoader("block");
    const getData = await axios.get(
      `${URL}/admin/getAllUsersByFilterType?fulltimesocialworker=${value}`,
      {
        headers: { "X-API-Key": APP_X_API_KEY },
      }
    );
    const Data1 = await getData;
    setUser(Data1?.data?.data);
    setShowLoader("none");
  };

  const getSettingvalue = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getSettingvalue`, {
      headers: { "X-API-Key": APP_X_API_KEY },
    });
    const Data1 = await getData.data;
    // setValues({...values,usertype:Data1?.data?.data[0].usertype,subscriptionplanid:Data1?.data?.data[0].subscriptionplanid,noofmonth:Data1?.data?.data[0].noofmonth});
    //  alert(Data1.data.usertype);
    //  console.log(Data1)
    setusertype(Data1?.data?.usertype);
    setsubscriptionplanid(Data1?.data?.subscriptionplanid?._id);
    setnoofmonth(Data1?.data?.noofmonth);
    setShowLoader("none");
  };

  useEffect(() => {
    getAllData();
    getAllUserListData(usertype2);
    getSettingvalue();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!usertype || !subscriptionplanid || !noofmonth) {
      toast.error("Please fill out all fields");
      return;
    }
    CreateData();
  };
  const onSubmitFreeSub = (e) => {
    e.preventDefault();
    //  alert("Under Working.......")
    if (selectedUser.length === 0 || !noofmonth2) {
      toast.error("Please Select User");
      return;
    }
    UpdateFreeSubscriptionData();
  };

  const UpdateFreeSubscriptionData = async () => {
    setShowLoader("block");
    const url = `${URL}/users/free_subscription_to_user`;

    axios
      .patch(
        url,
        { multi_selected_users: selectedUser, month: noofmonth2 },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Subscription Added Successfully");
        navigate("/subscriptionplan");
        setShowLoader("none");
      })
      .catch((error) => {
        setShowLoader("none");
        toast.error("Service error");
      });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "usertype") {
      setusertype(value);
    }
    if (name === "subscriptionplanid") {
      setsubscriptionplanid(value);
    }
    if (name === "noofmonth") {
      setnoofmonth(value);
    }
    if (name === "noofmonth2") {
      setnoofmonth2(value);
    }
    if (name === "usertype2") {
      // Social Worker  non social worker
      setusertype2(value);
      getAllUserListData(value);
    }

    // setValues({ ...values, [name]: value });
  };
  // const handleImageChange = async (e) => {
  //   setShowLoader("block");
  //   const formData = new FormData();
  //   formData.append("file", e.target.files[0]);
  //   var requestOptions = {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   };
  //   const fetchdata = axios.post(
  //     `${URL}/admin/imageUpload_Use/imageUpload`,
  //     formData,
  //     requestOptions
  //   );
  //   const response = await fetchdata;
  //   if (response.status === 200) {
  //     //toast.success("Data Uploaded Successfully...");
  //     setShowLoader("none");
  //     //seturl(response?.data?.url);
  //     setValues({ ...values, imageurl: response?.data?.url });
  //   } else {
  //     setShowLoader("none");
  //     toast.error("Fail To Load...");
  //   }
  //   setShowLoader("none");
  // };
  console.log(selectedUser);
  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link to="/subscriptionplan" className="btn btn-primary mb-2">
        {" "}
        Back{" "}
      </Link>

      <div className="row">
        {/* First FORM */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Free Subscription</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmitFreeSub}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">
                        Select user type{" "}
                      </label>
                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="usertype2"
                        value={usertype2}
                        onChange={handleChange}
                      >
                        <option>Select</option>
                        <option value="0">View All Users</option>
                        <option value="1">View Social Worker</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      {/* {user.length} */}
                      <label for="exampleInputEmail1">User </label>
                      {/* <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="userid"
                        onChange={handleChange}
                      >
                        <option>Select</option>
                        {user.map((userss, index) => {
                          return (
                            <option value={userss?._id}>
                              {userss.name}
                            </option>
                          );
                        })}
                        ;
                      </select> */}
                      <Multiselect
                        className="inputHead"
                        onSelect={handleSelect}
                        onRemove={handleRemove}
                        options={options}
                        displayValue="name"
                        showCheckbox
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">No of Months - </label>
                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="noofmonth2"
                        value={noofmonth2}
                        onChange={handleChange}
                      >
                        <option>Select</option>
                        {Array.from({ length: 12 }, (_, index) => (
                          <option key={index + 1} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* First  FORM */}

        {/* SECOND FORM */}
        {/* <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Subscription Setting</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">
                        Select user type{" "}
                      </label>
                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="usertype"
                        value={usertype}
                        onChange={handleChange}
                      >
                        <option>Select</option>
                        <option value="Social Worker">Social Worker</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Subscription Type</label>
                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="subscriptionplanid"
                        value={subscriptionplanid}
                        onChange={handleChange}
                      >
                        {subscription.map((cc, index) => {
                          return <option value={cc._id}>{cc.title}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">No of Moths - </label>
                      <select
                        className="form-control"
                        id="exampleSelectGender"
                        name="noofmonth"
                        value={noofmonth}
                        onChange={handleChange}
                      >
                        <option>Select</option>
                        {Array.from({ length: 12 }, (_, index) => (
                          <option key={index + 1} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div> */}
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default Setting_Subscription;
