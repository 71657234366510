import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
// import WhatsappSend from "../../utils/WhatsappSend";

const URL = process.env.REACT_APP_URL;
const APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const adminid = localStorage.getItem("adminid");
const initialstate = {
  title: "",
  address: "",
  date: "",
  from_time: "",
  to_time: "",
  dresscode: "",
  instruction: "",
  eventcreatedByAdmin: true,
  admin_id: adminid,
  user_id: "",
  rsvp: "",
};

const AddEvent = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialstate);
  const [showloader, setShowLoader] = useState("none");

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************

  const CreateData = async () => {
    setShowLoader("block");
    const url = `${URL}/Event/AddEvent`;

    axios
      .post(
        url,
        {
          title: values?.title,
          address: values?.address,
          date: values?.date,
          from_time: values?.from_time,
          to_time: values?.to_time,
          dresscode: values?.dresscode,
          instruction: values?.instruction,
          eventcreatedByAdmin: values?.eventcreatedByAdmin,
          admin_id: values?.admin_id,
          user_id: values?.user_id,
          rsvp: values?.rsvp,
        },
        { headers: { "X-API-Key": APP_X_API_KEY } }
      )
      .then((result) => {
        toast.success("Event added successfully");
        navigate("/viewevent");
        setShowLoader("none");
      })
      .catch((error) => {
        setShowLoader("none");
        alert("Failed to Submit event.");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      title,
      address,
      date,
      from_time,
      to_time,
      dresscode,
      instruction,
      rsvp,
    } = values;

    if (
      !title ||
      !address ||
      !date ||
      !from_time ||
      !to_time ||
      !dresscode ||
      !instruction ||
      !rsvp
    ) {
      toast.error("Please fill out all fields");
      return;
    }
    if (from_time > to_time) {
      toast.error(" From time should be less then To time ");
      return;
    } else {
      CreateData(values);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    // if(name === 'to_time')
    //   {
    //     if(!values.from_time)
    //       {
    //         toast.error("Please select From time First");
    //         return;
    //       }
    //       if(value < values.from_time)
    //         {
    //           toast.error("Please select time greater then from time");
    //           return;
    //         }
    //   }
    setValues({ ...values, [name]: value });
  };
  // const handleImageChange = async (e) => {
  //   setShowLoader("block");
  //   const formData = new FormData();
  //   formData.append("file", e.target.files[0]);
  //   var requestOptions = {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   };
  //   const fetchdata = axios.post(
  //     `${URL}/admin/imageUpload_Use/imageUpload`,
  //     formData,
  //     requestOptions
  //   );
  //   const response = await fetchdata;
  //   if (response?.status === 200) {
  //     //toast.success("Data Uploaded Successfully...");
  //     setShowLoader("none");
  //     //seturl(response?.data?.url);
  //     setValues({ ...values, imageurl: response?.data?.url });
  //   } else {
  //     setShowLoader("none");
  //     toast.error("Fail To Load...");
  //   }
  //   setShowLoader("none");
  // };

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <Link to="/viewevent" className="btn btn-primary mb-2">
        {" "}
        Back{" "}
      </Link>
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Event</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputUsername1">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputUsername1"
                        placeholder="Enter Title"
                        name="title"
                        maxlength="50"
                        value={values?.title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter address"
                        name="address"
                        maxlength="50"
                        value={values?.address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Date</label>
                      <input
                        type="Date"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter date"
                        name="date"
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={(e) => e.target.showPicker()}
                        value={values?.date}
                        onChange={handleChange}
                        min={new Date().toISOString().slice(0, 10)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">From Time</label>
                      <input
                        type="time"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter From Time"
                        name="from_time"
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={(e) => e.target.showPicker()}
                        value={values?.from_time}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">To Time</label>
                      <input
                        type="time"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter time"
                        name="to_time"
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={(e) => e.target.showPicker()}
                        value={values?.to_time}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Dress code</label>
                      <select
                        className="form-control"
                        id="f1"
                        name="dresscode"
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="FORMAL">Formal</option>
                        <option value="CASUAL">Casual</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Instruction</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter instruction"
                        name="instruction"
                        value={values?.instruction}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Rsvp</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter rsvp"
                        name="rsvp"
                        value={values?.rsvp}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default AddEvent;
